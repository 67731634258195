import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { PageContainer } from "@flash-tecnologia/hros-web-ui-v2";

import { Header } from "./Header";
import { Tabs } from "./Tabs";

import { trpc } from "@api/client";

import { dispatchToast } from "@utils";

import type { Evaluation } from "server/src/services/evaluation/types";

export const PageEvaluationDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { evaluationId = "" } = useParams();

  const [selectedEvaluation, setSelectedEvaluation] = useState<
    Evaluation | undefined
  >();

  const { data: evaluationsByCycle, isFetching } =
    trpc.performance.evaluation.getEvaluationsByEvaluationCycle.useQuery(
      { _id: evaluationId },
      {
        onError: () => {
          dispatchToast({
            type: "error",
            content:
              "Não foi possível buscar a lista de avaliações do ciclo. Tente novamente em breve.",
          });
        },
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        enabled: !!evaluationId,
      }
    );

  useEffect(() => {
    if (!evaluationsByCycle) return;

    const queryParamEvaluationId = searchParams.get("detailsEvaluationId");

    if (queryParamEvaluationId) {
      const foundEvaluation = (evaluationsByCycle?.evaluations || []).find(
        (e) => e._id === queryParamEvaluationId
      );

      if (!foundEvaluation) {
        searchParams.delete("detailsEvaluationId");

        setSearchParams(searchParams);
      }

      return setSelectedEvaluation(foundEvaluation);
    }

    const foundEvaluation = (evaluationsByCycle?.evaluations || []).find(
      (e) => e._id === evaluationId
    );

    return setSelectedEvaluation(foundEvaluation);
  }, [evaluationsByCycle]);

  const onSetSelectedEvaluation = (evaluation?: Evaluation) => {
    setSelectedEvaluation(evaluation);

    if (!evaluation) return;

    const isEqual = evaluationId === evaluation?._id;

    isEqual
      ? searchParams.delete("detailsEvaluationId")
      : searchParams.set("detailsEvaluationId", evaluation._id);

    setSearchParams(searchParams);
  };

  const onChangeTab = () => {
    const tab = searchParams.get("tab");

    if (tab === "results") return;

    searchParams.set("tab", "results");

    setSearchParams(searchParams);
  };

  return (
    <PageContainer>
      <Header
        isLoading={isFetching}
        evaluations={evaluationsByCycle?.evaluations || []}
        cycle={evaluationsByCycle?.cycle}
        selectedEvaluation={selectedEvaluation}
        onEvaluationSelected={onSetSelectedEvaluation}
        onChangeTab={onChangeTab}
      />
      <Tabs isLoading={isFetching} selectedEvaluation={selectedEvaluation} />
    </PageContainer>
  );
};
