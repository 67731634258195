import { StyledIcon, StyledText, track } from "@utils";

import { TableSearch } from "./TableSearch";

import { HeaderContainer, SelectButton, SelectContainer } from "./styled";

interface IHeaderProps {
  selectedTab: "evaluateds" | "evaluators";
  onSearch: (value: string) => void;
  onTabSelected: (value: "evaluateds" | "evaluators") => void;
}

export const Header = ({
  selectedTab,
  onSearch,
  onTabSelected,
}: IHeaderProps) => {
  return (
    <HeaderContainer>
      <SelectContainer>
        <SelectButton
          onClick={() => {
            track({
              name: "people_strategic_hr_performance_company_evaluations_evaluationdetails_overview_evaluated_clicked",
            });

            onTabSelected("evaluateds");
          }}
          selected={selectedTab === "evaluateds"}
        >
          <StyledIcon
            name="IconChartBar"
            size={14}
            setColor={
              selectedTab === "evaluateds" ? "secondary50" : "neutral40"
            }
          />
          <StyledText
            variant="body3"
            children="Avaliados"
            setColor={
              selectedTab === "evaluateds" ? "secondary50" : "neutral40"
            }
          />
        </SelectButton>

        <SelectButton
          onClick={() => {
            track({
              name: "people_strategic_hr_performance_company_evaluations_evaluationdetails_overview_evaluators_clicked",
            });

            onTabSelected("evaluators");
          }}
          selected={selectedTab === "evaluators"}
        >
          <StyledIcon
            name="IconNotes"
            size={14}
            setColor={
              selectedTab === "evaluators" ? "secondary50" : "neutral40"
            }
          />
          <StyledText
            variant="body3"
            children="Avaliadores"
            setColor={
              selectedTab === "evaluators" ? "secondary50" : "neutral40"
            }
          />
        </SelectButton>
      </SelectContainer>

      <TableSearch
        disabled={false}
        onChange={onSearch}
        selectedTab={selectedTab}
      />
    </HeaderContainer>
  );
};
