import { useState, useMemo } from "react";

import { LinkButton, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledIcon,
  StyledText,
  StyledTitle,
  StyledPLine,
  avatarMaker,
  track,
} from "@utils";

import { CalibrationDrawersFlux } from "@components/Drawers";

import {
  StyledSkeleton,
  ItemContainer,
  Header,
  EmployeesContainer,
  Footer,
  StyledTag,
  FlexContainer,
  BigNumbers,
  StyledAvatar,
  StatusDot,
  StyledDot,
  PlusAvatar,
} from "./styled";

import type { MatrixQuadrantGridType, Evaluation } from "../../../../types";

interface IMatrixItemProps {
  evaluation: Evaluation;
  quadrant: MatrixQuadrantGridType;
  loading?: boolean;
}

export const MatrixItem = ({
  evaluation,
  quadrant,
  loading,
}: IMatrixItemProps) => {
  const [calibrationDrawersOpen, setCalibrationDrawersOpen] = useState({
    isOpen: false,
    selectedDrawer: "",
    id: "",
  });

  const isCalibrationFinished = useMemo(
    () => evaluation?.evaluationStatus === "calibration_finished",
    [evaluation.evaluationStatus]
  );

  if (loading) return <StyledSkeleton children={<ItemContainer />} />;

  return (
    <ItemContainer>
      <Header>
        <StyledTag
          children={
            <StyledText
              variant="body3"
              weight={600}
              setColor="neutral10"
              children={
                <StyledPLine
                  numberOfLines={1}
                  children={quadrant?.position + " - " + quadrant?.name}
                />
              }
            />
          }
        />

        <Tooltip title={quadrant?.description}>
          <StyledIcon
            name="IconInfoCircle"
            size={16}
            setColor="neutral30"
            style={{ flexShrink: 0 }}
          />
        </Tooltip>
      </Header>

      <EmployeesContainer>
        {(quadrant?.employees || []).map((evaluated) => {
          return (
            <Tooltip
              key={evaluated._id}
              title={
                <>
                  <StyledText
                    fontWeight={700}
                    variant="body3"
                    children={evaluated?.name}
                  />

                  {evaluated?.calibrated && (
                    <StyledText
                      variant="body4"
                      children={
                        <>
                          <b>Quadrante calibrado:</b> {quadrant?.name}
                        </>
                      }
                    />
                  )}

                  {evaluated?.originalQuadrantName && (
                    <StyledText
                      variant="body4"
                      children={
                        <>
                          <b>Quadrante original:</b>{" "}
                          {evaluated?.originalQuadrantName}
                        </>
                      }
                    />
                  )}

                  {evaluated?.calibrated && (
                    <StyledText
                      variant="body4"
                      children={
                        <>
                          <b>Nota calibrada:</b> {evaluated?.calibrationAverage}
                        </>
                      }
                    />
                  )}

                  <StyledText
                    variant="body4"
                    children={
                      <>
                        <b>Nota original:</b> {evaluated?.average}
                      </>
                    }
                  />
                </>
              }
            >
              <StyledAvatar
                key={evaluated._id}
                onClick={() => {
                  setCalibrationDrawersOpen({
                    isOpen: true,
                    selectedDrawer: "calibration",
                    id: evaluated._id,
                  });

                  track({
                    name: "people_strategic_hr_performance_company_evaluations_evaluationdetails_results_matrix_quadrants_person_clicked",
                  });
                }}
              >
                <StyledTitle
                  variant="body3"
                  setColor="secondary50"
                  children={avatarMaker(evaluated?.name)}
                />

                <StatusDot hidden={!evaluated?.calibrated} />
              </StyledAvatar>
            </Tooltip>
          );
        })}

        {quadrant?.total > 20 && (
          <PlusAvatar onClick={() => console.log("to quadrant detail")}>
            <StyledTitle
              variant="caption"
              children={`+${quadrant?.total - 20}`}
              setColor="neutral50"
            />
          </PlusAvatar>
        )}
      </EmployeesContainer>

      <Footer>
        <BigNumbers>
          <FlexContainer align="center">
            <StyledTitle variant="headline8" setColor="neutral40">
              {quadrant?.total}
            </StyledTitle>

            <StyledText variant="body3" setColor="neutral40">
              ({quadrant?.percentage}%)
            </StyledText>
          </FlexContainer>

          {!!quadrant?.calibratedTotal && (
            <FlexContainer align="center">
              <StyledDot variant="secondary" />

              <StyledTitle variant="body4" setColor="neutral50">
                {quadrant?.calibratedTotal}{" "}
                <span style={{ fontSize: "12px" }}>calibrados</span>
              </StyledTitle>
            </FlexContainer>
          )}
        </BigNumbers>

        <LinkButton
          variant="neutral"
          onClick={() => {
            setCalibrationDrawersOpen({
              isOpen: true,
              selectedDrawer: "quadrants",
              id: quadrant._id,
            });

            track({
              name: "people_strategic_hr_performance_company_evaluations_evaluationdetails_results_matrix_quadrants_seemore_clicked",
            });
          }}
        >
          Ver mais
        </LinkButton>
      </Footer>

      <CalibrationDrawersFlux
        isOpen={calibrationDrawersOpen.isOpen}
        evaluationStatus={evaluation?.evaluationStatus || ""}
        selectedDrawer={calibrationDrawersOpen.selectedDrawer as any}
        id={calibrationDrawersOpen.id}
        evaluationId={evaluation._id}
        isCalibrationFinished={isCalibrationFinished}
        onClose={() =>
          setCalibrationDrawersOpen({
            isOpen: false,
            selectedDrawer: "",
            id: "",
          })
        }
      />
    </ItemContainer>
  );
};
