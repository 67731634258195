import { useCallback, useEffect, useMemo, useState } from "react";

import { cloneDeep } from "lodash-es";

import {
  Button,
  DataGridProps,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";

import { ProfileTable } from "@components/ProfileTable";
import { TagList } from "@components/TagList";

import { trpc } from "@api/client";

import { RequiredAsterisk, StyledText, track } from "@utils";

import { Options } from "./Options";

import { FlexBetween, FlexColumn, StyledDot } from "./styled";

import type {
  Evaluation,
  EvaluationDetailsResults,
  EvaluationGrades,
} from "server/src/services/evaluation/types";

type CalibrationDrawersProps = {
  isOpen: boolean;
  selectedDrawer?: "calibration";
  id?: string;
};

type UseEvaluationDetailsProps = {
  evaluation: Evaluation & {
    typesNames: EvaluationGrades["type"][];
  };
  data: EvaluationDetailsResults["results"];
  departments: { value: string; label: string }[];
};

export const useEvaluationDetails = ({
  evaluation,
  data,
  departments,
}: UseEvaluationDetailsProps) => {
  const utils = trpc.useContext();

  const [tableData, setTableData] = useState<
    EvaluationDetailsResults["results"]
  >([]);

  const [openCalibrationDrawers, setOpenCalibrationDrawers] =
    useState<CalibrationDrawersProps>({
      isOpen: false,
      selectedDrawer: undefined,
      id: "",
    });

  useEffect(() => {
    setTableData(data || []);
  }, [data]);

  const { typesNames, evaluationStatus } = evaluation;

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        Header: "Avaliado",
        accessor: "name",
        Cell: ({ row, value }) => {
          if (row?.depth || !value) return <></>;

          return (
            <FlexColumn style={{ minWidth: "220px" }}>
              <ProfileTable name={value} />
            </FlexColumn>
          );
        },
      },
      {
        Header: "Seções",
        accessor: "sectionsCount",
        Cell: ({ row }) => {
          const { sectionsCount } = row?.original;

          if (row.depth) {
            const { criterialNames, name } = row?.original;

            return (
              <FlexColumn style={{ minWidth: "470px" }}>
                <StyledText
                  variant="body3"
                  setColor="neutral40"
                  fontWeight={600}
                >
                  {name}
                </StyledText>
                <StyledText variant="body4" setColor="neutral60">
                  Critérios avaliados: <strong>{criterialNames}</strong>
                </StyledText>
              </FlexColumn>
            );
          }

          return (
            <StyledText fontWeight={600} variant="body3">
              {sectionsCount || "-"}
            </StyledText>
          );
        },
      },
      {
        Header: () => {
          return (
            <div style={{ width: "150px" }}>
              <StyledText variant="body3">
                <strong>Nota final</strong> (Conta apenas nota do líder)
              </StyledText>
            </div>
          );
        },
        accessor: "average",
        Cell: ({ row, value }) => {
          if (row.depth) {
            return (
              <StyledText fontWeight={600} variant="body3">
                {value || "-"}
              </StyledText>
            );
          }

          const {
            calibrationAverage,
            average,
            name,
            originalQuadrantName,
            quadrantName,
          } = row.original;

          const parsedCalibration = Number(calibrationAverage) || null;

          const tooltipTitle = parsedCalibration ? (
            <>
              <StyledText fontWeight={700} variant="body3" children={name} />

              {quadrantName && (
                <StyledText
                  variant="body4"
                  children={
                    <>
                      <b>Quadrante calibrado:</b> {quadrantName}
                    </>
                  }
                />
              )}

              {originalQuadrantName && (
                <StyledText
                  variant="body4"
                  children={
                    <>
                      <b>Quadrante original:</b> {originalQuadrantName}
                    </>
                  }
                />
              )}

              <StyledText
                variant="body4"
                children={
                  <>
                    <b>Nota calibrada:</b> {calibrationAverage}
                  </>
                }
              />

              <StyledText
                variant="body4"
                children={
                  <>
                    <b>Nota original:</b> {average}
                  </>
                }
              />
            </>
          ) : (
            ""
          );

          if (!parsedCalibration)
            return (
              <StyledText fontWeight={600} variant="body3">
                {value || "-"}
              </StyledText>
            );

          return (
            <Tooltip
              title={tooltipTitle}
              style={{
                marginLeft: "4px",
                cursor: parsedCalibration ? "pointer" : "auto",
              }}
            >
              <FlexColumn>
                <StyledText
                  fontWeight={600}
                  variant="body3"
                  setColor="neutral40"
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  <>
                    {parsedCalibration || "-"} <StyledDot variant="primary" />
                  </>
                </StyledText>
                <StyledText
                  fontWeight={600}
                  variant="body4"
                  setColor="neutral50"
                >
                  Original: {value}
                </StyledText>
              </FlexColumn>
            </Tooltip>
          );
        },
      },
      {
        Header: "Calibrar nota final",
        accessor: "calibrate",
        Cell: ({ row }) => {
          if (row.depth) return <></>;

          const {
            calibrationAverage,
            // calibrationJustification = "",
            average,
            // name,
            _id,
          } = row.original;

          const isByLeader = !!typesNames?.find((type) => type === "byLeader");
          const hasCalibrationAverage = calibrationAverage !== undefined;

          const disabled = !isByLeader || average === undefined;

          if (evaluationStatus === "calibration_finished")
            return (
              <Button
                size="small"
                variant="secondary"
                disabled={disabled}
                style={{ minWidth: "140px" }}
                onClick={() => {
                  setOpenCalibrationDrawers({
                    isOpen: true,
                    selectedDrawer: "calibration",
                    id: _id || "",
                  });
                }}
              >
                Ver detalhes
              </Button>
            );

          if (hasCalibrationAverage)
            return (
              <Button
                size="small"
                variant="secondary"
                style={{ minWidth: "120px" }}
                onClick={() => {
                  setOpenCalibrationDrawers({
                    isOpen: true,
                    selectedDrawer: "calibration",
                    id: _id || "",
                  });
                }}
              >
                Editar nota
              </Button>
            );

          return (
            <Button
              size="small"
              variant="primary"
              style={{ minWidth: "110px" }}
              onClick={() => {
                setOpenCalibrationDrawers({
                  isOpen: true,
                  selectedDrawer: "calibration",
                  id: _id || "",
                });

                track({
                  name: "people_strategic_hr_performance_company_evaluations_evaluationdetails_results_list_calibrate_clicked",
                });
              }}
              disabled={disabled}
            >
              Calibrar
            </Button>
          );
        },
      },
      {
        Header: () => {
          return (
            <StyledText variant="body3" fontWeight={700}>
              Líder avalia liderado <RequiredAsterisk />
            </StyledText>
          );
        },
        accessor: "byLeader",
        Cell: ({ value }) => {
          return (
            <StyledText fontWeight={600} variant="body3">
              {value || "-"}
            </StyledText>
          );
        },
      },
      {
        Header: "Liderado avalia líder",
        accessor: "byLed",
        Cell: ({ value }) => {
          return (
            <StyledText fontWeight={600} variant="body3">
              {value || "-"}
            </StyledText>
          );
        },
      },
      {
        Header: "Participante se autoavalia",
        accessor: "self",
        Cell: ({ value }) => {
          return (
            <StyledText fontWeight={600} variant="body3">
              {value || "-"}
            </StyledText>
          );
        },
      },
      {
        Header: "Quadrante",
        accessor: "quadrantName",
        Cell: ({ row, value }) => {
          if (row.depth) return <></>;

          const { calibrationAverage, originalQuadrantName } = row.original;

          const parsedCalibration = Number(calibrationAverage) || null;

          if (!parsedCalibration)
            return (
              <StyledText
                fontWeight={600}
                variant="body3"
                setColor="neutral40"
                style={{ minWidth: "220px" }}
              >
                {value || " - "}
              </StyledText>
            );

          return (
            <FlexColumn style={{ minWidth: "220px" }}>
              <StyledText
                fontWeight={600}
                variant="body3"
                setColor="neutral40"
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
                children={
                  value ? (
                    <>
                      {value} <StyledDot variant="primary" />
                    </>
                  ) : (
                    " - "
                  )
                }
              />
              {originalQuadrantName ? (
                <StyledText
                  fontWeight={600}
                  variant="body4"
                  setColor="neutral50"
                >
                  Original: {originalQuadrantName}
                </StyledText>
              ) : (
                <></>
              )}
            </FlexColumn>
          );
        },
      },
      {
        Header: "Líder",
        accessor: "reportsToName",
        Cell: ({ row, value }) => {
          if (row.depth || !value) return <></>;

          return (
            <FlexColumn style={{ minWidth: "220px" }}>
              <ProfileTable name={value} />
            </FlexColumn>
          );
        },
      },
      {
        Header: "Departamento",
        accessor: "departmentIds",
        Cell: ({ row, value }) => {
          if (row.depth || !value || !departments?.length) return <></>;

          const ids = value || [];

          const filteredDepartments = departments.filter((d) =>
            ids.includes(d.value)
          );

          if (!filteredDepartments.length) return <></>;

          const tags = filteredDepartments.map((f) => ({
            id: f.value,
            label: f.label,
            style: { maxWidth: filteredDepartments?.length > 1 ? "100px" : "" },
          }));

          return (
            <FlexColumn style={{ minWidth: "290px" }}>
              <TagList limit={2} tags={tags} />
            </FlexColumn>
          );
        },
      },
      {
        Header: "Ações",
        sticky: "right",
        disableSortBy: true,
        accessor: "action",
        Cell: ({ row }) => {
          if (row.depth) return <></>;

          const { _id } = row.original;

          return (
            <FlexBetween style={{ gap: "15px" }}>
              <Options _id={_id} row={row} />
            </FlexBetween>
          );
        },
      },
    ] as DataGridProps<string>["columns"];

    const hasSelfEvaluation = typesNames?.find((e) => e === "self");
    const hasByLedEvaluation = typesNames?.find((e) => e === "byLed");
    const hasByLeaderEvaluation = typesNames?.find((e) => e === "byLeader");

    let filteredColumns = defaultColumns;

    if (!hasSelfEvaluation)
      filteredColumns = filteredColumns.filter((f) => f.accessor !== "self");

    if (!hasByLedEvaluation)
      filteredColumns = filteredColumns.filter((f) => f.accessor !== "byLed");

    if (!hasByLeaderEvaluation)
      filteredColumns = filteredColumns.filter(
        (f) => f.accessor !== "byLeader"
      );

    if (
      !["in_calibration", "calibration_finished"].includes(
        evaluationStatus || ""
      )
    )
      filteredColumns = filteredColumns.filter(
        (f) => f.accessor !== "calibrate"
      );

    return filteredColumns;
  }, [typesNames, evaluationStatus, departments?.length]);

  const isCalibrationFinished = useMemo(
    () => evaluation?.evaluationStatus === "calibration_finished",
    [evaluation.evaluationStatus]
  );

  const updateCalibratedData = useCallback(
    (updatedGrade: EvaluationGrades) => {
      const { evaluatedId, quadrantId, calibrationAverage } = updatedGrade;

      const clonedTableData = cloneDeep(tableData || []);

      const foundIndex = clonedTableData.findIndex(
        (data) => data._id === evaluatedId
      );

      const matrixQuadrants = evaluation?.matrix?.quadrants || [];

      const quadrantName = quadrantId
        ? matrixQuadrants.find((m) => m._id === quadrantId)?.name
        : undefined;

      if (foundIndex >= 0) {
        clonedTableData[foundIndex] = {
          ...clonedTableData[foundIndex],
          calibrationAverage: calibrationAverage
            ? Number(calibrationAverage)
            : undefined,
          quadrantName,
        };
      }

      return clonedTableData;
    },
    [tableData, evaluation]
  );

  const onSubmit = useCallback(
    (updatedGrade: EvaluationGrades) => {
      const updatedData = updateCalibratedData(updatedGrade);

      utils.performance.evaluation.getEvaluationDetailsResults.setData(
        {
          evaluationId: evaluation?._id || "",
        },
        (oldData) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            results: updatedData,
          };
        }
      );
    },
    [tableData, evaluation._id]
  );

  const onCloseCalibration = () => {
    setOpenCalibrationDrawers({
      isOpen: false,
      selectedDrawer: undefined,
      id: "",
    });
  };

  return {
    columns,
    tableData,
    openCalibrationDrawers,
    isCalibrationFinished,
    onSubmit,
    onCloseCalibration,
  };
};
