import styled from "styled-components";

import { Dot } from "@flash-tecnologia/hros-web-ui-v2";

import { EmptyState } from "@assets/index";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SubtitleRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  padding-bottom: 16px;
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  gap: 16px;
  padding: 80px 0px;
`;

export const EmptyStateImg = styled.div`
  width: 160px;
  height: 156px;
  background-image: url(${EmptyState});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
`;

export const StyledDot = styled(Dot).attrs(() => ({
  variant: "pink",
  style: { height: "12px", width: "12px", borderRadius: "50%" },
}))`
  background-color: ${({ theme }) => theme.colors.secondary[50]};
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 24px;
`;
