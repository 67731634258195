import { SelectField, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { SearchField } from "@components/SearchField";

import { SearchContainer } from "./styled";

interface Evaluation {
  label: string;
  value: string;
}

interface ISearchHeader {
  evaluationNames: Evaluation[];
  cycleId: string;
  disabled: boolean;
  isLoading: boolean;
  selectedEvaluationId: string;
  onSearch: (e: string) => void;
  onEvaluationSelect: (e: string) => void;
}

export const SearchHeader = ({
  evaluationNames,
  disabled,
  isLoading,
  selectedEvaluationId,
  onEvaluationSelect,
  onSearch,
}: ISearchHeader) => {
  if (isLoading)
    return (
      <SearchContainer>
        <Skeleton width={"100%"} height={50} variant="rounded" />{" "}
        <Skeleton
          width={"100%"}
          height={50}
          style={{ maxWidth: "439px" }}
          variant="rounded"
        />
      </SearchContainer>
    );

  return (
    <SearchContainer>
      <SearchField
        placeholder={"Buscar por liderado"}
        onChange={(searchValue) => {
          onSearch(searchValue);
        }}
        disabled={!selectedEvaluationId}
      />

      <SelectField
        label={"Selecionar avaliação"}
        value={selectedEvaluationId}
        options={evaluationNames || []}
        onSelectChange={(_, option) => onEvaluationSelect(option.value)}
        fullWidth
        style={{ maxWidth: "439px" }}
        disabled={disabled}
      />
    </SearchContainer>
  );
};
