import { Box, Line, MatrixArea } from "./styled";
import { ArrowString } from "../";

interface IMatrix {
  variant?: "primary" | "secondary";
}

export const Matrix = ({ variant }: IMatrix) => {
  return (
    <>
      <ArrowString
        label="EIXO VERTICAL"
        position="vertical"
        variant={variant}
      />
      <MatrixArea>
        <Line>
          <Box>7</Box>
          <Box>8</Box>
          <Box>9</Box>
        </Line>
        <Line>
          <Box>4</Box>
          <Box>5</Box>
          <Box>6</Box>
        </Line>
        <Line>
          <Box>1</Box>
          <Box>2</Box>
          <Box>3</Box>
        </Line>
      </MatrixArea>
      <ArrowString label="EIXO HORIZONTAL" variant={variant} />
    </>
  );
};
