import { useMemo } from "react";
import { FormikProps } from "formik";

import { MatrixSection, QuadrantsSection, WarningBanner } from "./components";

import { FormSkeleton, FormContainer, StyledAccordion } from "./styled";

import type { CalibrationMatrixFormProps } from "../../../types";

import type { EvaluationSection } from "server/src/services/evaluation/types";

interface ICalibrationMatrixFormProps {
  formik: FormikProps<CalibrationMatrixFormProps>;
  hasByLeaderType?: boolean;
  hasTwoSections?: boolean;
  hasCalibrationStep?: boolean;
  sections?: EvaluationSection[];
  error?: boolean;
  isLoading?: boolean;
  disabledEdit?: boolean;
  disabledPartialEdit?: boolean;
}

export const CalibrationMatrixForm = ({
  formik,
  hasByLeaderType,
  hasTwoSections,
  hasCalibrationStep,
  isLoading,
  sections,
  error,
  disabledEdit,
  disabledPartialEdit,
}: ICalibrationMatrixFormProps) => {
  const matrixConfig = useMemo(() => {
    const values = formik.values;
    const touched = formik.touched;
    const errors = formik.errors;

    const hasHorizontalTouched =
      touched.horizontalAxisName && touched.horizontalSectionId;

    const hasVerticalTouched =
      touched.verticalAxisName && touched.verticalSectionId;

    const hasHorizontalError =
      hasHorizontalTouched &&
      Boolean(errors.horizontalAxisName || errors.horizontalSectionId);

    const hasVerticalError =
      hasVerticalTouched &&
      Boolean(errors.verticalAxisName || errors.verticalSectionId);

    const hasError = hasHorizontalError || hasVerticalError;

    const isFilled =
      values.horizontalAxisName &&
      values.horizontalSectionId &&
      values.verticalAxisName &&
      values.verticalSectionId;

    return {
      error: hasError,
      isFilled,
    };
  }, [formik.touched, formik.errors, formik.values]);

  const quadrantsConfig = useMemo(() => {
    const quadrants = formik.values.quadrants || [];

    const notFilled = quadrants.some((s) => !s.name || !s.description);

    return {
      error: formik.touched.quadrants && Boolean(formik.errors.quadrants),
      isFilled: !notFilled && quadrants.length >= 9,
    };
  }, [
    formik.touched.quadrants,
    formik.errors.quadrants,
    formik.values.quadrants,
  ]);

  if (isLoading) return <FormSkeleton />;

  if (!error && (!hasByLeaderType || !hasTwoSections || !hasCalibrationStep))
    return (
      <WarningBanner
        hasByLeaderType={hasByLeaderType}
        hasCalibrationStep={hasCalibrationStep}
      />
    );

  return (
    <FormContainer>
      <StyledAccordion
        defaultExpanded={true}
        variant="default"
        tagName={matrixConfig.isFilled ? "Preenchido" : "Obrigatório"}
        tagVariant={matrixConfig.isFilled ? "success" : "error"}
        title="Matriz de calibração"
        description="Selecione as seções do formulário que irão posicionar os avaliados de acordo com suas notas nos eixos vertical e horizontal na matriz."
        children={
          <MatrixSection
            formik={formik}
            sections={sections || []}
            disabledEdit={disabledEdit}
            disabledPartialEdit={disabledPartialEdit}
          />
        }
      />

      <StyledAccordion
        defaultExpanded={true}
        variant="default"
        tagName={quadrantsConfig.isFilled ? "Preenchido" : "Obrigatório"}
        tagVariant={quadrantsConfig.isFilled ? "success" : "error"}
        title="Quadrantes"
        description="Personalize os nomes dos quadrantes da matriz de calibração."
        children={
          <QuadrantsSection
            formik={formik}
            disabledEdit={disabledPartialEdit}
          />
        }
      />
    </FormContainer>
  );
};
