import styled from "styled-components";
import { PillButton } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledText } from "@utils";

export const StyledPillButton = styled(PillButton)`
  align-self: center;
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 32px;
`;

export const IconArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

export const GraphArea = styled.div`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 16px 0;
`;

export const GraphTitle = styled(StyledText)`
  margin-left: 32px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
`;

export const InfoStar = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

export const GraphContainer = styled.div`
  margin-bottom: 8px;
`;
