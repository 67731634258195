import styled from "styled-components";

import { EmptyState } from "@assets/index";

export const EmptyStateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const EmptyStateImg = styled.div`
  width: 160px;
  height: 156px;
  background-image: url(${EmptyState});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
`;
