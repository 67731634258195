import { useState, SetStateAction } from "react";

import { CheckboxFilter } from "@flash-tecnologia/hros-web-ui-v2";

import { track } from "@utils";

import { cloneDeep } from "lodash-es";

import { selectAllTracks, selectTracks } from "./constants";

import type { FilterType } from "../../types";

type OptionsProps = Parameters<typeof CheckboxFilter>[0]["options"];

interface IFilterItemProps extends FilterType {
  setFilterList: (value: SetStateAction<FilterType[]>) => void;
}

export const FilterItem = ({
  accessor,
  label,
  options,
  setFilterList,
}: IFilterItemProps) => {
  const [filterOptions, setFilterOptions] = useState<OptionsProps>(
    options.map((e) => ({
      label: e?.label,
      value: e?.value,
      checked: !!e?.checked,
      onChange: (value, checked) => handleOnChange(value, checked),
    }))
  );

  const handleSelectAll = (checked: boolean) => {
    track({ name: selectAllTracks[label] });

    setFilterOptions((current) => {
      const clonedOptions = cloneDeep(current);

      const mappedOptions = clonedOptions.map((option) => ({
        ...option,
        checked,
      }));

      return [...mappedOptions];
    });

    setFilterList((current) => {
      const clonedCurrent = cloneDeep(current);

      const itemIndex = current.findIndex((c) => c.accessor === accessor);

      if (itemIndex === -1) return current;

      const mappedOptions = clonedCurrent[itemIndex].options.map((option) => ({
        ...option,
        checked,
      }));

      clonedCurrent[itemIndex].options = mappedOptions;

      return [...clonedCurrent];
    });
  };

  const handleOnChange = (value: string, checked: boolean) => {
    track({ name: selectTracks[label] });

    setFilterOptions((current) => {
      const clonedOptions = cloneDeep(current);

      const itemIndex = clonedOptions.findIndex(
        (option) => option.value === value
      );

      if (itemIndex === -1) return current;

      clonedOptions[itemIndex].checked = checked;

      return [...clonedOptions];
    });

    setFilterList((current) => {
      const clonedCurrent = cloneDeep(current);

      const itemIndex = current.findIndex((c) => c.accessor === accessor);

      if (itemIndex === -1) return current;

      const optionIndex = clonedCurrent[itemIndex].options.findIndex(
        (option) => option.value === value
      );

      if (optionIndex === -1) return current;

      clonedCurrent[itemIndex].options[optionIndex].checked = checked;

      return [...clonedCurrent];
    });
  };

  if (!filterOptions?.length) return <></>;

  return (
    <CheckboxFilter
      title={label}
      options={filterOptions}
      onAllSelection={handleSelectAll}
      allSelectLabel="Selecionar todos"
    />
  );
};
