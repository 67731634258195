import { useMemo } from "react";
import { cloneDeep } from "lodash-es";

import { ParticipantCard } from "./ParticipantCard";
import { LoadingSkeleton } from "./LoadingSkeleton";

import { StyledTitle, track } from "@utils";

import { EmptyStateContainer, EmptyStateImg, MainContainer } from "./styled";

import type { Evaluated, Filter } from "../../types";

interface IParticipantsProps {
  items: Evaluated[];
  search: string;
  filters: Filter;
  isLoading?: boolean;
  onParticipantClick: (evaluatedId: string) => void;
}

export const Participants = ({
  items,
  search,
  filters,
  isLoading,
  onParticipantClick,
}: IParticipantsProps) => {
  const filteredItems = useMemo(() => {
    if (!items.length) return [];

    let dataToFilter: Evaluated[] = cloneDeep(items);

    const clonedFilters = cloneDeep(filters || {});

    if (search) {
      dataToFilter = dataToFilter.filter((item) => {
        const searchItem = item.name;

        return searchItem?.toLowerCase().includes(search.toLowerCase());
      });
    }

    Object.keys(clonedFilters || {}).forEach((key) => {
      clonedFilters?.[key] && !clonedFilters[key].length
        ? delete clonedFilters[key]
        : undefined;
    });

    if (clonedFilters["evaluateds"]?.length) {
      clonedFilters["_id"] = clonedFilters["evaluateds"];

      delete clonedFilters["evaluateds"];
    }

    if (Object.keys(clonedFilters || {})?.length) {
      dataToFilter = dataToFilter?.filter((item) => {
        for (const key in clonedFilters) {
          const itemValue = item[key] || undefined;

          if (itemValue === undefined) return false;

          if (Array.isArray(itemValue)) {
            if (!itemValue.some((el) => clonedFilters[key].includes(el))) {
              return false;
            }
          } else {
            if (!clonedFilters[key].includes(item[key])) return false;
          }
        }
        return true;
      });
    }

    return dataToFilter;
  }, [items, search, filters]);

  if (isLoading) return <LoadingSkeleton />;

  if (!filteredItems?.length)
    return (
      <EmptyStateContainer>
        <EmptyStateImg />
        <StyledTitle
          variant="body3"
          children={"Nenhum participante encontrado no quadrante."}
          setColor="neutral50"
          weight={600}
          style={{ maxWidth: "280px" }}
        />
      </EmptyStateContainer>
    );

  const cards = filteredItems.map((item) => (
    <ParticipantCard
      key={item._id}
      participant={item}
      onClick={(evaluated) => {
        onParticipantClick(evaluated._id);

        track({
          name: "people_strategic_hr_performance_company_evaluations_evaluationdetails_results_matrix_quadrantdetails_person_clicked",
        });
      }}
    />
  ));

  return <MainContainer children={cards} />;
};
