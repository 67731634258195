import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Header } from "./components/Header";
import { Evaluateds, Evaluators } from "./components/Tabs";

interface IOverviewProps {
  evaluationId: string;
}

export const Overview = ({ evaluationId }: IOverviewProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tab, setTab] = useState<"evaluateds" | "evaluators">(
    (searchParams.get("overviewTab") as any) || "evaluateds"
  );

  const [search, setSearch] = useState<string | undefined>("");

  const onTabSelected = (tab: "evaluateds" | "evaluators") => {
    setTab(tab);

    searchParams.set("overviewTab", tab);

    setSearchParams(searchParams);
  };

  useEffect(() => {
    setSearch(undefined);
  }, [tab]);

  return (
    <>
      <Header
        selectedTab={tab}
        onSearch={setSearch}
        onTabSelected={onTabSelected}
      />

      {tab === "evaluateds" && (
        <Evaluateds evaluationId={evaluationId} search={search} />
      )}

      {tab === "evaluators" && (
        <Evaluators evaluationId={evaluationId} search={search} />
      )}
    </>
  );
};
