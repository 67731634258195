import { Popover } from "@mui/material";
import React, { ReactNode, useEffect, useMemo, useState } from "react";

import {
  Checkbox,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import * as SC from "../styled";

interface Option {
  label: string;
  value: string;
  checked?: boolean;
  setChecked?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface TagCheckboxFilterPopoverProps {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl(ref: HTMLButtonElement | null): void;
  children?: ReactNode;
  options?: Option[];
  onClick(value: Array<string>): void;
  placeholder?: string;
  showClearButton?: boolean;
  showSelectAllButton?: boolean;
  initialValues: string[];
}

export const TagCheckboxFilterPopover = ({
  anchorEl,
  setAnchorEl,
  children,
  options = [],
  onClick,
  placeholder,
  showClearButton,
  showSelectAllButton,
  initialValues,
}: TagCheckboxFilterPopoverProps) => {
  const optionsState = options.map((option) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [checked, setChecked] = useState(
      initialValues.includes(option.value)
    );

    return {
      ...option,
      checked: option.checked || checked,
      setChecked: option.setChecked || setChecked,
    };
  });

  const [search, setSearch] = useState<string>("");
  const [checkedAll, setCheckedAll] = useState<boolean>(false);

  const handleClick = (newState: boolean, option: Option) => {
    const index = optionsState?.findIndex((item) => item == option);

    optionsState[index].setChecked(newState);
  };

  const handleSelectAll = () => {
    const filteredOptions = optionsState.filter((option) =>
      option.label.toLowerCase().includes(search.toLowerCase())
    );

    const anyUnchecked = filteredOptions.some((option) => !option.checked);

    filteredOptions.forEach((option) => option.setChecked(anyUnchecked));

    setCheckedAll(!anyUnchecked);
  };

  const handleClear = () => {
    optionsState.forEach((option) => option.setChecked(false));
    setCheckedAll(false);
  };

  const filteredOptions = useMemo(() => {
    return optionsState.filter((option) =>
      option.label.toLowerCase().includes(search.toLowerCase())
    );
  }, [optionsState, search]);

  const mappedCheckedOptions = optionsState.map(({ checked }) => checked);

  useEffect(() => {
    const optionsChecked = optionsState.reduce<string[]>(
      (labelOptions, { checked, value }) => {
        if (checked) {
          labelOptions.push(value);
        }

        return labelOptions;
      },
      []
    );

    onClick(optionsChecked);

    const anyUnchecked = optionsState.some((option) => !option.checked);

    setCheckedAll(!anyUnchecked);

    const allChecked = optionsState
      .filter((option) =>
        option.label.toLowerCase().includes(search.toLowerCase())
      )
      .every((option) => option.checked);

    if (allChecked) {
      setCheckedAll(true);
    }
  }, [...mappedCheckedOptions, search]);

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      PaperProps={{
        style: {
          marginTop: "8px",
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {children ? (
        children
      ) : (
        <div className="tag-checkbox-filter-menu">
          <SC.SearchInputContainer>
            <SC.SearchInputBorder>
              <SC.SearchIcon name="IconSearch" />
              <SC.SearchInput
                placeholder={placeholder || "Buscar"}
                variant={"standard"}
                InputProps={{
                  disableUnderline: true,
                }}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
            </SC.SearchInputBorder>
          </SC.SearchInputContainer>
          <SC.SelectAllContainer
            showSelectAllButton={
              showSelectAllButton && !!filteredOptions.length
            }
          >
            <SC.SelectAllItem>
              <Checkbox
                checked={checkedAll}
                onChange={() => handleSelectAll()}
              />
              <Typography variant="body3">Selecionar todos</Typography>
            </SC.SelectAllItem>
          </SC.SelectAllContainer>
          <SC.MenuContainer showClearButton={showClearButton}>
            {filteredOptions.map((option, index) => (
              <SC.MenuItem
                key={index}
                onClick={() => handleClick(!option.checked, option)}
              >
                <Checkbox checked={option.checked} />
                <Typography variant="body3">{option.label}</Typography>
              </SC.MenuItem>
            ))}
          </SC.MenuContainer>
          {showClearButton && (
            <SC.ClearButtonContainer>
              <LinkButton variant="default" onClick={handleClear}>
                Limpar seleção
              </LinkButton>
            </SC.ClearButtonContainer>
          )}
        </div>
      )}
    </Popover>
  );
};
