import styled from "styled-components";

import { EmptyState } from "@assets/index";

export const EmptyStateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20;
  text-align: center;
  padding: 52px 0px;
`;

export const EmptyStateImg = styled.div`
  width: 160px;
  height: 156px;
  background-image: url(${EmptyState});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
