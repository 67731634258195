import { ButtonBase, TextField } from "@mui/material";
import styled from "styled-components";

import {
  Icons,
  TagFilterProps,
  ThemesType,
} from "@flash-tecnologia/hros-web-ui-v2";

interface TagFilterStyledProps extends Pick<TagFilterProps, "variant"> {
  active?: boolean;
}

export const Button = styled(ButtonBase)<TagFilterStyledProps>((props) => ({
  background: getVariantColor(props.theme.colors, props.variant, "background"),
  borderRadius: props.theme.borders.radius.xl,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "flex-start",
  color: getVariantColor(props.theme.colors, props.variant, "color"),
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "18px",
  textAlign: "center",
  padding: props.theme.spacings.xs4 + " " + props.theme.spacings.xs2,
  cursor: "pointer",

  [`&:hover`]: {
    backgroundColor: props.theme.colors.neutral[90],
  },

  [`> svg`]: {
    backgroundColor: props.theme.colors.neutral[90],
  },

  [`.typography--variant-body3`]: {
    fontWeight: 600,
  },

  ...((props.variant == "tertiary" || props.variant == "secondary") && {
    border: `${props.theme.borders.width.xs2} solid ${getVariantColor(
      props.theme.colors,
      props?.variant,
      "borderColor"
    )}`,
  }),

  ...(props.active && {
    backgroundColor: props.theme.colors.tertiary[90],
    color: props.theme.colors.secondary[50],
  }),
}));

export const BadgeNumber = styled.div<TagFilterStyledProps>((props) => ({
  width: "24px",
  height: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: props.theme.borders.radius.circular,
  marginLeft: props.theme.spacings.xs4,
  backgroundColor: getVariantColor(
    props.theme.colors,
    props.variant,
    "badgeBackgroundColor"
  ),
  color: getVariantColor(props.theme.colors, props.variant, "badgeColor"),

  [".typography--variant-caption"]: {
    fontWeight: 700,
  },
  ...(props.active && {
    backgroundColor: props.theme.colors.primary,
    color: props.theme.colors.neutral[100],
  }),
}));

export const ClearButtonContainer = styled.div`
  padding: ${(props) =>
    `${props.theme.spacings.xs} ${props.theme.spacings.xs2}`};
  border-top: 1px solid ${(props) => props.theme.colors.neutral["90"]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MenuItem = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacings.xs3};
  padding: ${(props) => props.theme.spacings.xs2};

  &:hover {
    background-color: ${(props) => props.theme.colors.neutral[95]};
    border-radius: ${(props) => props.theme.borders.radius.xl};
  }
`;

export const MenuContainer = styled.ul<{ showClearButton?: boolean }>`
  list-style-type: none;
  max-height: 300px;
  width: 320px;
  padding: 0px ${(props) => props.theme.spacings.xs3};
  margin: ${(props) => props.theme.spacings.xs5} 0px;
  overflow: ${({ showClearButton }) => (showClearButton ? "auto" : "visible")};
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SearchInput = styled(TextField)`
  color: ${(props) => props.theme.colors.neutral[30]};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: 600;
  padding-left: 40px !important;
  border: none !important;
`;

export const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: ${(props) => props.theme.spacings.xs3};
  width: 100%;
  &:before,
  &:after {
    content: none;
  }
`;

export const SearchInputBorder = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: ${(props) => props.theme.spacings.xs3};
  height: 60px;
  width: 100%;
  border: ${(props) => props.theme.borders.width.xs2} solid
    ${(props) => props.theme.colors.neutral[70]};
  border-radius: 150px;
  &:before,
  &:after {
    content: none;
  }
`;

export const SearchIcon = styled(Icons)`
  position: relative;
  left: 20px;
  fill: transparent;
  z-index: 1;
  circle,
  line {
    stroke: ${(props) => props.theme.colors.neutral[70]};
  }
`;

export const SelectAllContainer = styled.div<{ showSelectAllButton?: boolean }>`
  display: ${({ showSelectAllButton }) =>
    showSelectAllButton ? "flex" : "none"};
  align-items: center;
  background-color: transparent;
  gap: ${(props) => props.theme.spacings.xs};
  padding: 0px ${(props) => props.theme.spacings.xs3};
  width: 100%;
  border-top: ${(props) => props.theme.borders.width.xs2} solid
    ${(props) => props.theme.colors.neutral[90]};
  border-bottom: ${(props) => props.theme.borders.width.xs2} solid
    ${(props) => props.theme.colors.neutral[90]};
`;

export const SelectAllItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  background-color: transparent;
  gap: ${(props) => props.theme.spacings.xs3};
  padding: ${(props) => props.theme.spacings.xs2};
`;

type ColorObject = {
  background: string;
  color: string;
  badgeColor: string;
  badgeBackgroundColor: string;
  borderColor: string;
  stroke: string;
};

const getVariantColor = (
  colors: ThemesType["colors"],
  variant: "primary" | "secondary" | "tertiary" | "disabled" | "error",
  key: keyof ColorObject
) => {
  const variantColors: { [k in typeof variant]: Partial<ColorObject> } = {
    primary: {
      background: colors?.tertiary?.[90],
      color: colors?.secondary?.[50],
      badgeColor: colors?.neutral?.[100],
      badgeBackgroundColor: colors?.primary,
    },
    secondary: {
      borderColor: colors?.neutral?.[90],
      color: colors?.neutral?.[40],
      badgeColor: colors?.neutral?.[40],
      stroke: colors?.neutral?.[40],
    },
    tertiary: {
      borderColor: colors?.neutral?.[90],
      color: colors?.neutral?.[40],
      badgeColor: colors?.neutral?.[40],
      stroke: colors?.neutral?.[40],
    },
    disabled: {
      background: colors?.neutral?.[90],
      color: colors?.neutral?.[80],
      badgeColor: colors?.neutral?.[80],
      stroke: colors?.neutral?.[80],
    },
    error: {
      background: colors?.neutral?.[90],
      color: colors?.neutral?.[80],
      badgeColor: colors?.neutral?.[80],
    },
  };

  return variantColors[variant][key];
};
