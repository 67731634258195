import { Checkbox } from "@flash-tecnologia/hros-web-ui-v2";

import { BulkActionsProps } from "../types";

import { Container, ItemContainer } from "./styled";

export const BulkActions = <T extends object>({
  table,
  totalCount,
  bulkActionsRender,
}: BulkActionsProps<T>) => {
  const totalSelected = table.selected.allSelected
    ? totalCount - table.selected.selected.length
    : table.selected.selected.length;

  return (
    <Container role="row">
      <ItemContainer>
        <Checkbox
          onChange={() => table.setAllSelected(!table.selected.allSelected)}
          indeterminate={table.selected.selected.length > 0}
          checked={table.selected.allSelected}
          aria-label="column row checkbox"
        />
      </ItemContainer>

      {bulkActionsRender?.({ table, totalSelected, totalCount }) || <></>}
    </Container>
  );
};
