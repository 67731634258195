import { Table, tableControllers } from "@flash-tecnologia/hros-web-ui-v2";

import { BulkActions } from "./BulkActions";

import { usePagination } from "./hooks";

import { pageSizeOptions } from "@utils";

import type { DataGridProps } from "./types";

export const DataGrid = <T extends object>({
  data,
  columns,
  options,
  empty,
  variant,
  loading,
  pagination: controlledPagination,
  initialPagination,
  onPaginationChanged,
  bulkActionsRender,
}: DataGridProps<T>) => {
  const { paginatedData, pagination, handlePaginationChanged } = usePagination({
    initialPagination,
    controlledPagination,
    data,
    onPaginationChanged,
  });

  const { page, pageSize, totalCount } = pagination;

  const table = tableControllers.useTableColumns({
    total: totalCount,
    options,
    columns,
    data: paginatedData,
    pagination: {
      pageNumber: page,
      pageSize: pageSize,
    },
    onPaginationChange: ({ pageNumber, pageSize }) =>
      handlePaginationChanged(pageNumber, pageSize),
  });

  const isAnyRowSelected =
    table.selected.allSelected || !!table.selected.selected.length;

  return (
    <Table.Root style={{ width: "100%" }} variant={variant}>
      <Table.Grid.Root loading={loading} empty={empty}>
        {isAnyRowSelected ? (
          <BulkActions
            table={table}
            totalCount={totalCount}
            bulkActionsRender={bulkActionsRender}
          />
        ) : (
          <Table.Grid.Header
            getHeaderGroups={table.getHeaderGroups}
            toggleAllRowsExpanded={table.toggleAllRowsExpanded}
          />
        )}

        {table.rows.map((row) => (
          <Table.Grid.Row key={row.id} row={row} />
        ))}
      </Table.Grid.Root>
      <Table.Pagination
        count={totalCount}
        onPaginationChange={({ pageNumber, pageSize }) =>
          handlePaginationChanged(pageNumber, pageSize)
        }
        pagination={{
          pageNumber: page,
          pageSize: pageSize,
        }}
        showItemRange
        pageSizeOptions={pageSizeOptions}
      />
    </Table.Root>
  );
};
