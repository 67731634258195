import { Outlet, Navigate } from "react-router-dom";

export const StagingRoutesGuard = ({
  children,
}: {
  children?: JSX.Element;
}) => {
  if (process.env.STAGE === "production") {
    return <Navigate to={"/home"} />;
  }

  return children ? children : <Outlet />;
};
