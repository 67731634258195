import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";

import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { routes } from "@routes";

import { PageTemplate } from "@components/PageTemplate";

import { ContentHeader } from "./components/ContentHeader";
import { TypesToAnswer } from "./components/TypesToAnswer";

import { trpc } from "@api/client";

import { dispatchToast } from "@utils";

export const EvaluationIndex = () => {
  const { participantId = "", cycleId = "" } = useParams();
  const utils = trpc.useContext();
  const theme = useTheme();
  const navigate = useNavigate();

  const routesBread = [
    {
      label: "Ciclo",
      route: routes.PageMyCycles(cycleId),
    },
    {
      label: "Responder avaliação",
    },
  ];

  const {
    data: evaluationIndexData,
    isFetching: isGettingEvaluation,
    refetch: refetchEmployeeEvaluation,
  } = trpc.performance.evaluation.getEmployeeEvaluationIndex.useQuery(
    {
      participantId: participantId || "",
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 0,
      enabled: !!participantId,
      onError: (e: any) => {
        const evaluationNotExists =
          e?.data?.error === "EVALUATION_NOT_EXISTS_ERROR";

        const message = evaluationNotExists
          ? "Erro, avaliação selecionada não foi encontrada."
          : "Erro ao buscar os dados. Tente novamente em breve";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    }
  );

  const { mutate: restartEmployeeMutate, isLoading: isRestarting } =
    trpc.performance.evaluation.restartEmployeeEvaluation.useMutation({
      onError: (e: any) => {
        const evaluationNotExists =
          e?.data?.error === "EVALUATION_NOT_EXISTS_ERROR";

        const message = evaluationNotExists
          ? "Erro, avaliação selecionada não foi encontrada."
          : "Erro ao editar suas respostas. Tente novamente em breve";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const {
    status,
    typeStatus,
    typesToAnswer,
    cycleName,
    cycleSteps,
    name,
    description,
  } = evaluationIndexData || {};

  return (
    <PageTemplate
      containerStyle={{
        backgroundColor: theme.colors.neutral95,
      }}
      contentStyle={{
        maxWidth: "870px",
        gap: theme.spacings.m,
        padding: `${theme.spacings.m} ${theme.spacings.xs}`,
      }}
      routes={routesBread}
      footer={{
        cancelProps: {
          title: "Sair",
          callback: () => {
            navigate(routes.PageMyCycles(cycleId));
          },
        },
        confirmProps: {
          loading: isRestarting,
          disabled:
            status !== "available" || isRestarting || isGettingEvaluation,
          title: (
            <>
              {typeStatus === "done" ? "Editar" : "Responder"}
              <Icons name="IconArrowRight" fill="transparent" />
            </>
          ),
          callback: () => {
            const isEdit = typeStatus === "done";

            const redirectUrl = routes.PageViewEvaluation(
              cycleId,
              participantId,
              "evaluation",
              1
            );

            if (isEdit) {
              restartEmployeeMutate(
                { participantId },
                {
                  onSuccess: () => {
                    utils.performance.evaluation.getEmployeeEvaluationByCycleId.invalidate();
                    refetchEmployeeEvaluation();

                    navigate(redirectUrl);
                  },
                }
              );
              return;
            }

            navigate(redirectUrl);
          },
        },
      }}
    >
      <ContentHeader
        name={name || ""}
        cycleName={cycleName || ""}
        cycleSteps={cycleSteps || []}
        description={description || ""}
        isLoading={isGettingEvaluation}
      />

      <TypesToAnswer
        types={typesToAnswer || []}
        status={status || "expired"}
        isLoading={isGettingEvaluation}
      />
    </PageTemplate>
  );
};
