import { Outlet, Navigate } from "react-router-dom";

import { useAuth } from "@flash-hros/auth-helper";

export const OpenRoutesGuard = ({ children }: { children?: JSX.Element }) => {
  const contractStatus = useAuth.getState().contractStatus || null;

  if (contractStatus?.url) {
    return <Navigate to={contractStatus?.url} state={{ from: location }} />;
  }

  return children ? children : <Outlet />;
};
