import * as yup from "yup";

export const validationSchema = yup.object({
  horizontalAxisName: yup.string().required("Este campo deve ser preenchido"),
  horizontalSectionId: yup.string().required("Este campo deve ser preenchido"),
  verticalAxisName: yup.string().required("Este campo deve ser preenchido"),
  verticalSectionId: yup.string().required("Este campo deve ser preenchido"),
  quadrants: yup
    .array(
      yup.object({
        _id: yup.string().required("Este campo deve ser preenchido"),
        position: yup.number().required("Este campo deve ser preenchido"),
        name: yup.string().required("Este campo deve ser preenchido"),
        description: yup.string().required("Este campo deve ser preenchido"),
      })
    )
    .min(9)
    .max(9),
});

export const DEFAULT_QUADRANTS = [
  {
    _id: "gVGLj6TtnO83UGAhXf9kA",
    position: 1,
    name: "Insuficiente",
    description:
      "Feedback ou revisão imediata/curto prazo. Avaliar outras possibilidades na companhia ou desligamento.",
  },
  {
    _id: "40J1gnHK0sKmCEgfebjVJ",
    position: 2,
    name: "Eficaz",
    description:
      "Atende às expectativas, cumpre o esperado. Pode ocasionalmente exceder às expectativas, mas somente quando demandado.",
  },
  {
    _id: "QGCeBLvwXWrvG-X9nUI9k",
    position: 3,
    name: "Comprometido",
    description:
      "Entrega de resultados acima da média e pode desenvolver na posição com possível movimentação lateral.",
  },
  {
    _id: "5YkXHP-Sz_fCSBcTUlr-Z",
    position: 4,
    name: "Questionável",
    description:
      "Rever atribuições, dar feedback estruturado, monitorar e ter plano de ação. Possivelmente considerar movimentação.",
  },
  {
    _id: "oynI18PDAHRsU-5WPzhtm",
    position: 5,
    name: "Mantenedor",
    description:
      "Alcança as expectativas na posição. Pode se adaptar à novas situações, porém precisa lapidar para assumir posição acima.",
  },
  {
    _id: "j4zOA1zXB6TLlViozw3jc",
    position: 6,
    name: "Forte entrega",
    description:
      "Tem espaço para mais atribuições e pode evoluir para posição maior. Apresenta boas entregas e resultados, profissional valioso.",
  },
  {
    _id: "bKXebMXYtOQY-f_PPgC8X",
    position: 7,
    name: "Enigma",
    description:
      "Acabou de entrar na posição. Está em desenvolvimento e pode melhorar resultados. Avaliar se é adequado para o papel.",
  },
  {
    _id: "Xjw25GK43SEYtTzYFP01j",
    position: 8,
    name: "Forte cultura",
    description:
      "Profissional referência. Potencial para assumir desafio e nível acima, podendo evoluir nas entregas e resultados.",
  },
  {
    _id: "I7qQV00PYwXddTv-5ZIDU",
    position: 9,
    name: "Destaque",
    description:
      "É destaque e está pronto para desafio maior, assumindo até 2 níveis acima da sua posição. Líderes estratégicos ou futuros líderes.",
  },
];
