import { FormikProps } from "formik";

import { TextField } from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";

import { Matrix } from "../Matrix";

import { HelperArea, InputArea } from "./styled";

import type { CalibrationMatrixFormProps } from "../../../../../types";
import { StyledText } from "@utils";

interface IQuadrantsSection {
  formik: FormikProps<CalibrationMatrixFormProps>;
  disabledEdit?: boolean;
}

export const QuadrantsSection = ({
  formik,
  disabledEdit,
}: IQuadrantsSection) => {
  const quadrants = formik.values.quadrants || [];

  return (
    <Grid container spacing={3}>
      <Grid item md={12} lg={8}>
        {quadrants.map((q, index) => {
          return (
            <InputArea key={q._id}>
              <div style={{ width: "100%" }}>
                <TextField
                  label={`Nome do quadrante ${q.position} *`}
                  value={q.name}
                  disabled={disabledEdit}
                  inputProps={{ maxLength: 30 }}
                  onChange={(e) => {
                    const findQuadrantIndex = quadrants.findIndex(
                      (s) => q.position === s.position
                    );

                    const currentQuadrant = {
                      ...q,
                      name: e.target.value,
                    };

                    const arrayQuadrants = Array.from(quadrants);

                    arrayQuadrants.splice(findQuadrantIndex, 1);

                    arrayQuadrants.splice(
                      findQuadrantIndex,
                      0,
                      currentQuadrant
                    );

                    formik.handleChange({
                      target: {
                        id: "quadrants",
                        value: arrayQuadrants,
                      },
                    });
                  }}
                  error={
                    formik.touched.quadrants?.[index] &&
                    Boolean((formik.errors.quadrants?.[index] as any)?.name)
                  }
                  helperText={
                    (formik.touched.quadrants?.[index] &&
                      (formik.errors.quadrants?.[index] as any)?.name) ||
                    undefined
                  }
                  fullWidth
                />
                <StyledText
                  variant="caption"
                  children="Máximo 30 caracteres"
                  style={{ marginLeft: "18px", fontWeight: 600 }}
                  setColor="neutral50"
                />
              </div>
              <div style={{ width: "100%" }}>
                <TextField
                  label={`Contexto/recomendação do quadrante ${q.position}`}
                  value={q.description}
                  multiline={true}
                  rows={3}
                  disabled={disabledEdit}
                  inputProps={{ maxLength: 150 }}
                  onChange={(e) => {
                    const findQuadrantIndex = quadrants.findIndex(
                      (s) => q.position === s.position
                    );

                    const currentQuadrant = {
                      ...q,
                      description: e.target.value,
                    };

                    const arrayQuadrants = Array.from(quadrants);

                    arrayQuadrants.splice(findQuadrantIndex, 1);

                    arrayQuadrants.splice(
                      findQuadrantIndex,
                      0,
                      currentQuadrant
                    );

                    formik.handleChange({
                      target: {
                        id: "quadrants",
                        value: arrayQuadrants,
                      },
                    });
                  }}
                  helperText={
                    (formik.touched.quadrants?.[index] &&
                      (formik.errors.quadrants?.[index] as any)?.description) ||
                    undefined
                  }
                  error={
                    formik.touched.quadrants?.[index] &&
                    Boolean(
                      (formik.errors.quadrants?.[index] as any)?.description
                    )
                  }
                  fullWidth
                />
                <HelperArea>
                  <StyledText
                    variant="caption"
                    setColor="neutral50"
                    weight={600}
                    children={"Máximo 150 caracteres"}
                  />
                  <StyledText
                    variant="caption"
                    setColor="neutral50"
                    weight={600}
                    children={`${q.description.length ?? 0}/150`}
                  />
                </HelperArea>
              </div>
            </InputArea>
          );
        })}
      </Grid>
      <Grid item md={12} lg={4}>
        <Matrix variant="secondary" />
      </Grid>
    </Grid>
  );
};
