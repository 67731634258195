import { useEffect, useMemo, useState } from "react";

import { DataGridProps, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";

import { ProfileTable } from "@components/ProfileTable";

import { RequiredAsterisk, StyledText } from "@utils";

import { Options } from "./Options";

import { FlexBetween, FlexColumn, StyledDot } from "./styled";

import type {
  EvaluationDetailsResults,
  EvaluationGrades,
} from "server/src/services/evaluation/types";

type UseEvaluationDetailsProps = {
  types: EvaluationGrades["type"][];
  data: EvaluationDetailsResults["results"];
  selectedEvaluationId?: string;
};

export const useEvaluationDetails = ({
  types,
  data,
  selectedEvaluationId,
}: UseEvaluationDetailsProps) => {
  const [tableData, setTableData] = useState<
    EvaluationDetailsResults["results"]
  >([]);

  useEffect(() => {
    setTableData(data || []);
  }, [data]);

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        Header: "Liderado",
        accessor: "name",
        Cell: ({ row, value }) => {
          if (row?.depth || !value) return <></>;
          const role = row.original.roleName;
          return (
            <FlexColumn style={{ minWidth: "220px" }}>
              <ProfileTable name={value} role={role} />
            </FlexColumn>
          );
        },
      },
      {
        Header: "Seções",
        accessor: "sectionsCount",
        Cell: ({ row }) => {
          const { sectionsCount } = row?.original;

          if (row.depth) {
            const { criterialNames, name } = row?.original;

            return (
              <FlexColumn style={{ minWidth: "470px" }}>
                <StyledText
                  variant="body3"
                  setColor="neutral40"
                  fontWeight={600}
                >
                  {name}
                </StyledText>
                <StyledText variant="body4" setColor="neutral60">
                  Critérios avaliados: <strong>{criterialNames}</strong>
                </StyledText>
              </FlexColumn>
            );
          }

          return (
            <StyledText fontWeight={600} variant="body3">
              {sectionsCount || "-"}
            </StyledText>
          );
        },
      },
      {
        Header: () => {
          return (
            <div style={{ width: "150px" }}>
              <StyledText variant="body3">
                <strong>Nota final</strong> (Conta apenas nota do líder)
              </StyledText>
            </div>
          );
        },
        accessor: "average",
        Cell: ({ row, value }) => {
          if (row.depth) {
            return (
              <StyledText fontWeight={600} variant="body3">
                {value || "-"}
              </StyledText>
            );
          }

          const {
            calibrationAverage,
            average,
            name,
            originalQuadrantName,
            quadrantName,
          } = row.original;

          const parsedCalibration = Number(calibrationAverage) || null;

          const tooltipTitle = parsedCalibration ? (
            <>
              <StyledText fontWeight={700} variant="body3" children={name} />

              {quadrantName && (
                <StyledText
                  variant="body4"
                  children={
                    <>
                      <b>Quadrante calibrado:</b> {quadrantName}
                    </>
                  }
                />
              )}

              {originalQuadrantName && (
                <StyledText
                  variant="body4"
                  children={
                    <>
                      <b>Quadrante original:</b> {originalQuadrantName}
                    </>
                  }
                />
              )}

              <StyledText
                variant="body4"
                children={
                  <>
                    <b>Nota calibrada:</b> {calibrationAverage}
                  </>
                }
              />

              <StyledText
                variant="body4"
                children={
                  <>
                    <b>Nota original:</b> {average}
                  </>
                }
              />
            </>
          ) : (
            ""
          );

          if (!parsedCalibration)
            return (
              <StyledText fontWeight={600} variant="body3">
                {value || "-"}
              </StyledText>
            );

          return (
            <Tooltip
              title={tooltipTitle}
              style={{
                marginLeft: "4px",
                cursor: parsedCalibration ? "pointer" : "auto",
              }}
            >
              <FlexColumn>
                <StyledText
                  fontWeight={600}
                  variant="body3"
                  setColor="neutral40"
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  <>
                    {parsedCalibration || "-"} <StyledDot variant="primary" />
                  </>
                </StyledText>
                <StyledText
                  fontWeight={600}
                  variant="body4"
                  setColor="neutral50"
                >
                  Original: {value}
                </StyledText>
              </FlexColumn>
            </Tooltip>
          );
        },
      },
      {
        Header: () => {
          return (
            <StyledText variant="body3" fontWeight={700}>
              Líder avalia liderado <RequiredAsterisk />
            </StyledText>
          );
        },
        accessor: "byLeader",
        Cell: ({ value }) => {
          return (
            <StyledText fontWeight={600} variant="body3">
              {value || "-"}
            </StyledText>
          );
        },
      },
      {
        Header: "Liderado se autoavalia",
        accessor: "self",
        Cell: ({ value }) => {
          return (
            <StyledText fontWeight={600} variant="body3">
              {value || "-"}
            </StyledText>
          );
        },
      },

      {
        Header: "Ações",
        sticky: "right",
        disableSortBy: true,
        accessor: "action",
        Cell: ({ row }) => {
          if (row.depth) return <></>;

          const { _id } = row.original;

          return (
            <FlexBetween style={{ gap: "15px" }}>
              <Options
                _id={_id}
                row={row}
                evaluationId={selectedEvaluationId}
              />
            </FlexBetween>
          );
        },
      },
    ] as DataGridProps<string>["columns"];

    const hasSelfEvaluation = types?.find((e) => e === "self");

    const hasByLeaderEvaluation = types?.find((e) => e === "byLeader");

    let filteredColumns = defaultColumns;

    if (!hasSelfEvaluation)
      filteredColumns = filteredColumns.filter((f) => f.accessor !== "self");

    if (!hasByLeaderEvaluation)
      filteredColumns = filteredColumns.filter(
        (f) => f.accessor !== "byLeader" && f.accessor !== "average"
      );

    return filteredColumns;
  }, [types]);

  return {
    columns,
    tableData,
  };
};
