import { StyledText } from "@utils";
import styled from "styled-components";

export const TooltipBackground = styled.div`
  cursor: pointer;
  border-radius: 8px;
  padding: 12px 16px;
  background: ${({ theme }) => theme.colors.neutral[10]};
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.1);
`;

export const LegendArea = styled.div`
  display: flex;
  align-items: center;
  margin-left: 32px;
  margin-top: 20px;
`;

export const LegendDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-right: 8px;
`;

export const LegendText = styled(StyledText)`
  display: flex;
  align-items: center;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral[40]};
  margin-right: 24px;
`;
