import { TipCard } from "@components/Cards";

import { TitleRow } from "./styled";

import { StyledIcon, StyledTitle, track } from "@utils";

interface FlashTipProps {
  onButtonClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const FlashTip = ({ onButtonClick }: FlashTipProps) => {
  return (
    <TipCard
      icon="IconSparkles"
      title={"Dica da Flash!"}
      style={{ marginBottom: "32px" }}
      subTitle={
        <>
          <div>
            Separe os <b>tipos de critérios</b> em <b>seções</b> diferentes para
            distribuí-las na <b>matriz de calibração</b>.
          </div>
          <div>
            <b>Exemplo:</b> Criar uma seção representando <b>desempenho</b> ou{" "}
            <b>resultados</b>, enquanto outra seção representa <b>valores</b>,{" "}
            <b>cultura</b>, etc...
          </div>
        </>
      }
      button={{
        title: (
          <TitleRow>
            <StyledIcon
              name="IconInfoCircle"
              setColor="secondary50"
              size={14}
            />
            <StyledTitle
              variant="body4"
              setColor="secondary50"
              children={"Saiba mais sobre matriz de calibração"}
              style={{ fontWeight: 700 }}
            />
          </TitleRow>
        ),
        onClick: (e) => {
          track({
            name: "people_strategic_hr_performance_company_evaluations_createevaluation_evaluationform_matrixdrawer_opened",
          });

          onButtonClick?.(e);
        },
      }}
    />
  );
};
