import styled from "styled-components";

const ReversedText = styled.div`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
`;

const ReversedIcon = styled.div`
  transform: rotate(270deg);
  cursor: pointer;
`;

const AxisNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  min-width: 38px;
  max-width: 38px;
  user-select: none;
`;

const AxisLabelsContainer = styled.div`
  display: flex;
  min-width: 60px;
  max-width: 60px;
  user-select: none;
`;

const Container = styled(ReversedText)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 258px;
`;

export {
  ReversedText,
  ReversedIcon,
  AxisNameContainer,
  AxisLabelsContainer,
  Container,
};
