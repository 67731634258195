import { useEffect } from "react";
import {
  LinkButton,
  Skeleton,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";

import { StyledIcon, StyledText, StyledTitle } from "@utils";

import type { EvaluationGrades } from "server/src/services/evaluation/types";

import {
  Card,
  CardContainer,
  ContentContainer,
  LeftColumn,
  MainContainer,
  StyledAvatar,
  TextRow,
  ToolTipContainer,
} from "./styled";

type Insights = {
  self?: number | undefined;
  byLeader?: number | undefined;
  total: number;
  overallAverage?: string;
};

interface IBigNumbersProps {
  onFilter: (
    status: "selfUnanswered" | "byLeaderUnanswered" | undefined
  ) => void;
  insights: Insights;
  isLoading: boolean;
  types: EvaluationGrades["type"][];
  evaluationId: string;
  insightsFilter: "selfUnanswered" | "byLeaderUnanswered" | undefined;
}

export const BigNumbers = ({
  onFilter,
  insights,
  isLoading,
  types,
  evaluationId,
  insightsFilter,
}: IBigNumbersProps) => {
  const hasSelf = types.includes("self");
  const hasByLeader = types.includes("byLeader");

  useEffect(() => {
    onFilter(undefined);
  }, [evaluationId]);

  if (isLoading)
    return (
      <MainContainer>
        <Skeleton variant="rounded" width={"100%"} height={166} />
        <Skeleton variant="rounded" width={"100%"} height={166} />
        <Skeleton variant="rounded" width={"100%"} height={166} />
      </MainContainer>
    );

  return (
    <MainContainer>
      <Card>
        <CardContainer>
          <LeftColumn>
            <StyledAvatar setColor="secondary">
              <StyledIcon
                name="IconReportAnalytics"
                setColor="secondary50"
                size={24}
              />
            </StyledAvatar>

            <div>
              <StyledText
                variant="body3"
                children="Média de nota final dos liderados"
                setColor="neutral40"
                weight={600}
              />
              <StyledTitle
                variant="body1"
                setColor="neutral40"
                children={insights?.overallAverage || " - "}
              />
            </div>
          </LeftColumn>
          <ToolTipContainer>
            <Tooltip
              title={
                <div
                  style={{ maxWidth: "200px", padding: 16 }}
                  children={"A nota final conta apenas com a nota do líder"}
                />
              }
            >
              <StyledIcon name="IconInfoCircle" size={16} />
            </Tooltip>
          </ToolTipContainer>
        </CardContainer>
      </Card>
      <Card
        style={{ opacity: insightsFilter === "byLeaderUnanswered" ? 0.5 : 1 }}
      >
        <StyledAvatar setColor="error">
          <StyledIcon name="IconUser" setColor="error40" size={24} />
        </StyledAvatar>
        <ContentContainer>
          <div>
            <StyledText
              variant="body3"
              children="Liderados que não se autoavaliaram"
              setColor="neutral40"
              weight={600}
            />
            {hasSelf ? (
              <TextRow>
                <StyledTitle
                  variant="body1"
                  setColor="neutral30"
                  children={insights?.self}
                />
                <StyledTitle
                  variant="body2"
                  setColor="neutral40"
                  children={`/${insights?.total} liderados`}
                />
              </TextRow>
            ) : (
              " - "
            )}
          </div>

          <LinkButton
            variant="default"
            size="large"
            style={{ alignSelf: "flex-start" }}
            children={
              insightsFilter === "selfUnanswered" ? "Remover filtro" : "Filtrar"
            }
            onClick={() => {
              const status =
                insightsFilter === "selfUnanswered"
                  ? undefined
                  : "selfUnanswered";

              onFilter(status);
            }}
            disabled={!hasSelf || insights?.self === 0}
          />
        </ContentContainer>
      </Card>
      <Card style={{ opacity: insightsFilter === "selfUnanswered" ? 0.5 : 1 }}>
        <StyledAvatar setColor="error">
          <StyledIcon name="IconUsers" setColor="error40" size={24} />
        </StyledAvatar>
        <ContentContainer>
          <div>
            <StyledText
              variant="body3"
              children="Liderados não avaliados pelo líder"
              setColor="neutral40"
              weight={600}
            />
            {hasByLeader ? (
              <TextRow>
                <StyledTitle
                  variant="body1"
                  setColor="neutral30"
                  children={insights?.byLeader}
                  weight={700}
                />
                <StyledTitle
                  variant="body2"
                  setColor="neutral40"
                  children={`/${insights?.total} liderados`}
                  weight={600}
                />
              </TextRow>
            ) : (
              " - "
            )}
          </div>
          <LinkButton
            variant="default"
            size="large"
            style={{ alignSelf: "flex-start" }}
            children={
              insightsFilter === "byLeaderUnanswered"
                ? "Remover filtro"
                : "Filtrar"
            }
            onClick={() => {
              const status =
                insightsFilter === "byLeaderUnanswered"
                  ? undefined
                  : "byLeaderUnanswered";

              onFilter(status);
            }}
            disabled={!hasByLeader || insights?.byLeader === 0}
          />
        </ContentContainer>
      </Card>
    </MainContainer>
  );
};
