import { Grades, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { TextEditor } from "@components/TextEditor";

import { StyledTitle, removeHtmlTags } from "@utils";

import {
  Divider,
  QuestionCounter,
  QuestionDescription,
  QuestionHeader,
  QuestionTitle,
  SectionContainer,
} from "./styled";

import type {
  Evaluation,
  SectionsAnsweredType,
} from "server/src/services/evaluation/types";

interface IQuestionItemProps {
  _id: string;
  number: number;
  name: string;
  description: string;
  value?: string | number;
  commentary: string;
  scales: Evaluation["scale"];
  index: number;
}

interface IEvaluationTabProps {
  sections: SectionsAnsweredType[];
  scales: Evaluation["scale"];
  isLoading: boolean;
}

const QuestionItem = ({
  _id,
  number,
  name,
  description,
  value,
  commentary,
  scales = [],
}: IQuestionItemProps) => {
  return (
    <div>
      <QuestionHeader>
        <QuestionCounter variant="headline8">{number + 1}.</QuestionCounter>
        <QuestionTitle variant="headline8">{name}</QuestionTitle>
      </QuestionHeader>

      <QuestionDescription variant="body3">
        {removeHtmlTags(description)}
      </QuestionDescription>

      <div style={{ marginLeft: "25px" }}>
        <Grades
          value={value?.toString() || ""}
          showTooltip={true}
          contentArray={scales.map((scale) => ({
            label: scale?.value?.toString() || "",
            title: scale?.title || "",
            subtitle: scale?.subtitle || "",
            value: scale?.value?.toString() || "",
            disabled: scale?.order !== value,
          }))}
        />
      </div>

      {commentary && (
        <TextEditor
          id={_id}
          styles={{
            container: { width: "100%", marginTop: "16px" },
            editor: { height: "120px" },
          }}
          readOnly={true}
          value={commentary}
          placeholder="Comentário (Opcional)"
        />
      )}
      <Divider />
    </div>
  );
};

export const Answers = ({
  sections = [],
  scales,
  isLoading,
}: IEvaluationTabProps) => {
  if (isLoading)
    return <Skeleton width={"100%"} height={300} variant={"rounded"} />;

  return (
    <div>
      {sections.map((section) => (
        <SectionContainer key={section._id}>
          <StyledTitle
            variant="headline8"
            setColor="secondary50"
            children={section.name}
          />

          {section.criterials?.map((criterial, index) => (
            <QuestionItem
              key={criterial._id}
              _id={criterial._id}
              number={index}
              name={criterial.name}
              description={criterial.description}
              value={criterial?.value}
              commentary={criterial?.commentary || ""}
              scales={scales}
              index={index}
            />
          ))}
        </SectionContainer>
      ))}
    </div>
  );
};
