import { useMemo } from "react";

import {
  DataGrid,
  PillButton,
  Icons,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledTitle,
  StyledText,
  pageSizeOptions,
  renderHtmlText,
} from "@utils";

import {
  ButtonArea,
  CellArea,
  DescriptionContainer,
  EmptyStateContainer,
  EmptyStateImg,
  IconContainer,
} from "./styled";

import type { TableGridCommonProps } from "@components/Table/types";

import type { EvaluationTypes } from "server/src/services/evaluation/types";

export const TableGrid = ({
  data,
  loading,
  types,
  evaluationNotExists,
}: TableGridCommonProps & {
  types?: EvaluationTypes[];
  evaluationNotExists?: boolean;
}) => {
  const columns = useMemo(() => {
    let renderColumns = [
      {
        Header: "Seções e critérios",
        disableSortBy: true,
        accessor: "name",
        Cell: ({ row: { original } }) => {
          const { name, description = "" } = original;
          return (
            <CellArea>
              <StyledText
                children={name}
                variant="body3"
                setColor="neutral40"
              />
              {description && (
                <DescriptionContainer>
                  <Tooltip
                    title={renderHtmlText(description)}
                    style={{ cursor: "pointer" }}
                    children={
                      <Icons
                        name="IconInfoCircle"
                        fill="transparent"
                        size={16}
                      />
                    }
                  />
                  <StyledTitle
                    children={renderHtmlText(description)}
                    variant="body4"
                    setColor="neutral40"
                  />
                </DescriptionContainer>
              )}
            </CellArea>
          );
        },
      },
      {
        Header: "Nota final (Conta apenas nota do líder)",
        disableSortBy: true,
        accessor: "average",
        Cell: ({ row: { original } }) => {
          return (
            <StyledText
              children={original.average || ""}
              variant="body3"
              setColor="neutral40"
              style={{ width: "113px", textAlign: "center" }}
            />
          );
        },
      },
      {
        Header: `Líder avalia liderado`,
        disableSortBy: true,
        accessor: "byLeader",
        Cell: ({ row: { original } }) => {
          return (
            <StyledText
              children={original.byLeader || ""}
              variant="body3"
              setColor="neutral40"
              style={{ width: "113px", textAlign: "center" }}
            />
          );
        },
      },
      {
        Header: "Liderado avalia líder",
        disableSortBy: true,
        accessor: "byLed",
        Cell: ({ row: { original } }) => {
          return (
            <StyledText
              children={original.byLed || ""}
              variant="body3"
              setColor="neutral40"
              style={{ width: "113px", textAlign: "center" }}
            />
          );
        },
      },
      {
        Header: "Participante se autoavalia",
        disableSortBy: true,
        accessor: "self",
        Cell: ({ row }) => {
          return (
            <StyledText
              children={row.original.self || ""}
              variant="body3"
              setColor="neutral40"
              style={{ width: "113px", textAlign: "center" }}
            />
          );
        },
      },
      {
        Header: "Ações",
        accessor: "action",
        sticky: "right",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          if (row?.depth > 0) return null;
          return (
            <ButtonArea>
              <IconContainer>
                {row?.canExpand ? (
                  <span {...row.getToggleRowExpandedProps()}>
                    {row?.isExpanded ? (
                      <PillButton
                        variant="default"
                        size="small"
                        type="secondary"
                        icon="IconChevronUp"
                      />
                    ) : (
                      <PillButton
                        variant="default"
                        size="small"
                        type="secondary"
                        icon="IconChevronDown"
                      />
                    )}
                  </span>
                ) : (
                  <PillButton
                    variant="default"
                    size="small"
                    type="secondary"
                    icon="IconChevronDown"
                    disabled={true}
                  />
                )}
              </IconContainer>
            </ButtonArea>
          );
        },
      },
    ];
    if (!types?.find((type) => type === "byLeader"))
      renderColumns = renderColumns.filter(
        (columns) =>
          columns.accessor !== "byLeader" && columns.accessor !== "average"
      );

    if (!types?.find((type) => type === "byLed"))
      renderColumns = renderColumns.filter(
        (columns) => columns.accessor !== "byLed"
      );

    if (!types?.find((type) => type === "self"))
      renderColumns = renderColumns.filter(
        (columns) => columns.accessor !== "self"
      );

    return renderColumns;
  }, [data]);

  if (evaluationNotExists)
    return (
      <EmptyStateContainer>
        <EmptyStateImg />
        <StyledTitle
          variant="body2"
          style={{ textAlign: "center", maxWidth: 400 }}
          children={"A avaliação ainda não foi respondida"}
          setColor="neutral30"
        />
        <StyledText
          variant="body3"
          style={{ textAlign: "center", maxWidth: 400 }}
          children={
            "Assim que as respostas forem enviadas, ela ficará disponível no filtro de seleção para consulta."
          }
          setColor="neutral40"
        />
      </EmptyStateContainer>
    );

  return (
    <>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        loading={loading}
        expandSubRows
        emptyState={{
          message: "Nenhuma avaliação selecionada",
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </>
  );
};
