import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const GradeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
`;

export const RightColumn = styled.div`
  display: flex;
  width: 100%;
`;

export const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Line = styled.div`
  width: 100%;
  margin: 16px 0px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral[90]}`};
`;
