import { useMemo } from "react";
import { concat } from "lodash-es";
import { Table } from "@components/Table";
import Banner from "@components/Banner";

import { TableGrid } from "./components";

import { trpc } from "@api/client";

import { dispatchToast, RequiredAsterisk, StyledText } from "@utils";

import type { Evaluation } from "server/src/services/evaluation/types";

interface IResultsProps {
  evaluation: Evaluation;
}

export const List = ({ evaluation }: IResultsProps) => {
  const { data, isFetching } =
    trpc.performance.evaluation.getEvaluationDetailsResults.useQuery(
      {
        evaluationId: evaluation?._id || "",
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: !!evaluation?._id,
        onError: () => {
          dispatchToast({
            type: "error",
            content: "Erro ao buscar os resultados, tente novamente em breve!",
          });
        },
      }
    );

  const { data: extraFilters, isLoading: loadingFilters } =
    trpc.performance.evaluation.getEvaluationDetailsMatrixFilters.useQuery(
      {
        evaluationId: evaluation?._id || "",
      },
      {
        enabled: !!evaluation?._id,
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
          dispatchToast({
            type: "error",
            content: "Erro ao buscar os filtros, tente novamente em breve!",
          });
        },
      }
    );

  const isByLeader = !!evaluation?.types?.find(
    (item) => item.type === "byLeader"
  );

  const filters = useMemo(() => {
    const mainFilters = data?.filters || [];

    if (!extraFilters?.length) return mainFilters;

    const filtered = extraFilters.filter((c) =>
      ["departmentIds", "roleId"].includes(c.accessor)
    );

    return concat(mainFilters, filtered);
  }, [data?.filters, extraFilters]);

  const departments = useMemo(() => {
    if (!filters.length) return [];

    return filters.find((f) => f.accessor === "departmentIds")?.options || [];
  }, [filters]);

  if (!evaluation) return <></>;

  return (
    <div style={{ marginBottom: "24px", marginTop: "24px" }}>
      <Table
        data={data?.results || []}
        filters={filters || []}
        loading={isFetching || loadingFilters}
        searchable={false}
        searchPlaceholder={"Buscar por avaliações"}
        tableGridHeader={({ isFiltered, rawData, data, loading }) => {
          if ((isFiltered && !data?.length) || !rawData?.length || loading)
            return <></>;

          const calibrationDisabled =
            !isByLeader && evaluation?.evaluationStatus === "in_calibration";

          return (
            <>
              {calibrationDisabled && (
                <Banner
                  type="error"
                  icon="IconX"
                  title="Não é possível calibrar as notas dessa avaliação."
                  subTitle={`Você não adicionou a modalidade "Líder avalia liderado" na avaliação. Portanto, não é possível calibrar as notas dos participantes, pois apenas a nota do líder é considerada no cálculo da nota final e pode ser calibrada.`}
                  hasHideBanner={false}
                  style={{ marginBottom: "16px" }}
                />
              )}

              <StyledText
                variant="body4"
                setColor="neutral50"
                style={{ marginTop: "24px", marginBottom: "16px" }}
              >
                <RequiredAsterisk /> Apenas a <strong>nota do líder</strong>{" "}
                (líder avalia liderado) será considerada no cálculo da{" "}
                <strong>nota final</strong> e poderá ser calibrada.
              </StyledText>
            </>
          );
        }}
        tableGridRender={(props) => (
          <TableGrid
            {...props}
            evaluation={{
              ...evaluation,
              typesNames: data?.evaluationTypes || [],
            }}
            departments={departments || []}
          />
        )}
      />
    </div>
  );
};
