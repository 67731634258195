import {
  DataGrid,
  Skeleton,
  EmptyState,
} from "@flash-tecnologia/hros-web-ui-v2";

import { pageSizeOptions } from "@utils";

import { CalibrationDrawersFlux } from "@components/Drawers";

import { useEvaluationDetails } from "./hooks";

import { DataGridContainer } from "./styled";

import type { TableGridCommonProps } from "@components/Table/types";

import {
  Evaluation,
  EvaluationGrades,
} from "server/src/services/evaluation/types";

interface ITableGridProps extends TableGridCommonProps {
  evaluation: Evaluation & {
    typesNames: EvaluationGrades["type"][];
  };
  departments?: { label: string; value: string }[];
}

export const TableGrid = ({
  data,
  departments = [],
  rawData,
  loading,
  evaluation,
}: ITableGridProps) => {
  const {
    columns,
    tableData,
    openCalibrationDrawers,
    isCalibrationFinished,
    onSubmit,
    onCloseCalibration,
  } = useEvaluationDetails({
    evaluation,
    data,
    departments,
  });

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={"Nenhum resultado foi encontrado!"}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <DataGridContainer>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={(tableData as any[]) || []}
        expandSubRows
        initialState={{ pageSize: Number(10) }}
        emptyState={{
          isFiltered: false,
          message: "Nenhuma resultado foi encontrado!",
        }}
        pageSizeOptions={pageSizeOptions}
      />

      <CalibrationDrawersFlux
        isOpen={openCalibrationDrawers.isOpen}
        evaluationStatus={evaluation?.evaluationStatus || ""}
        selectedDrawer={openCalibrationDrawers.selectedDrawer as any}
        id={openCalibrationDrawers.id}
        evaluationId={evaluation._id}
        isCalibrationFinished={isCalibrationFinished}
        onClose={onCloseCalibration}
        onSubmitCalibration={onSubmit}
      />
    </DataGridContainer>
  );
};
