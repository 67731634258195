import { useNavigate, useParams } from "react-router-dom";
import { cloneDeep } from "lodash-es";
import { Grid } from "@mui/material";

import { StyledIcon, StyledText, StyledTitle } from "@utils";

import { routes } from "@routes";

import {
  Container,
  AxisNameContainer,
  AxisLabelsContainer,
  ReversedIcon,
  ReversedText,
} from "./styled";

import type { AxisScales } from "../../../types";

interface IVerticalAxisProps {
  scales: AxisScales[];
  verticalName: string;
}

export const VerticalAxis = ({ scales, verticalName }: IVerticalAxisProps) => {
  const navigate = useNavigate();

  const { evaluationId = "" } = useParams();

  const cloneScales = cloneDeep(scales || []);

  return (
    <>
      <AxisNameContainer>
        <ReversedIcon
          onClick={() =>
            navigate(routes.PageEditEvaluation("matrix", evaluationId), {
              state: { origin: "calibration-matrix" },
            })
          }
        >
          <StyledIcon name="IconPencil" setColor="neutral40" size={24} />
        </ReversedIcon>

        <ReversedText>
          <StyledTitle
            variant="headline7"
            children={verticalName}
            setColor="neutral20"
          />
        </ReversedText>
      </AxisNameContainer>

      <AxisLabelsContainer>
        <Grid container rowGap={2}>
          {cloneScales?.reverse().map((scale) => {
            return (
              <Grid key={scale.label} item width={"100%"} xs={12}>
                <Container>
                  <StyledText
                    variant="body2"
                    setColor="neutral40"
                    weight={600}
                    children={
                      <>
                        <b>{scale?.label}:</b> {scale?.init} - {scale?.end}
                      </>
                    }
                  />
                </Container>
              </Grid>
            );
          })}
        </Grid>
      </AxisLabelsContainer>
    </>
  );
};
