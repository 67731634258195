import { useState, useEffect, useMemo, useCallback } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import { Grid } from "@mui/material";

import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { PageTemplate } from "@components/PageTemplate";

import {
  EvaluationWithSaveModal,
  EvaluationWithoutSaveModal,
} from "@components/Modals";

import { CalibrationMatrixForm } from "./components";

import { routes } from "@routes";

import { trpc } from "@api/client";

import {
  StyledTitle,
  StyledText,
  track,
  getObjDiffs,
  dispatchToast,
} from "@utils";

import { Header, LeftContainer } from "../styled";

import type { BasicStepProps, CalibrationMatrixFormProps } from "../types";

import { validationSchema, DEFAULT_QUADRANTS } from "./constants";

export const CalibrationMatrixStep = ({
  isEdit,
  disabledEdit,
  isLoading,
  error,
  steps,
  breadcrumbs,
  evaluation,
  isUpdating,
  evaluationId,
  onSubmitCustom,
  onNavigateByStep,
}: BasicStepProps) => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const { origin = "" } = (state || {}) as any;

  const disabled = error || disabledEdit.partial || disabledEdit.all;
  const partialDisabled = error || disabledEdit.matrix;

  const [exitAndSaveModal, setExitAndSaveModal] = useState(false);
  const [withoutSaveModal, setWithoutSaveModal] = useState(false);

  const utils = trpc.useContext();

  const formik = useFormik<CalibrationMatrixFormProps>({
    initialValues: {
      horizontalAxisName: "",
      horizontalSectionId: "",
      verticalAxisName: "",
      verticalSectionId: "",
      quadrants: DEFAULT_QUADRANTS,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const hasChanges = formHasChanges(values);

      const nextStep =
        origin === "calibration-matrix" ? "calibrationMatrix" : "review";

      if (!hasChanges) return onNavigateByStep(nextStep);

      if (values.horizontalAxisName === values.verticalAxisName) {
        dispatchToast({
          content: "Os eixos da matriz devem ter nomes diferentes.",
          type: "error",
        });

        formik.setErrors({
          horizontalAxisName: "Os eixos devem ter nomes diferentes",
          verticalAxisName: "Os eixos devem ter nomes diferentes",
        });

        return;
      }

      const matrix = {
        horizontalAxis: {
          name: values.horizontalAxisName,
          sectionId: values.horizontalSectionId,
        },
        verticalAxis: {
          name: values.verticalAxisName,
          sectionId: values.verticalSectionId,
        },
        quadrants: values.quadrants,
      };

      return onSubmitCustom({ matrix }, nextStep, "matrix");
    },
  });

  const hasCalibrationStep = useMemo(
    () => !!evaluation?.cycle.steps.find((step) => step.type === "calibration"),
    [evaluation?.cycle]
  );

  const hasByLeaderType = useMemo(
    () => !!evaluation?.types?.find((type) => type.type === "byLeader"),
    [evaluation?.types]
  );

  const hasTwoSections = useMemo(
    () => (evaluation?.sections || []).length === 2,
    [evaluation?.sections]
  );

  const formHasChanges = useCallback(
    (formikValues: CalibrationMatrixFormProps) => {
      const valuesToTest = {
        horizontalAxisName: formikValues.horizontalAxisName,
        horizontalSectionId: formikValues.horizontalSectionId,
        verticalAxisName: formikValues.verticalAxisName,
        verticalSectionId: formikValues.verticalSectionId,
        quadrants: formikValues.quadrants,
      };

      const baseValues = {
        horizontalAxisName: evaluation?.matrix?.horizontalAxis?.name || "",
        horizontalSectionId:
          evaluation?.matrix?.horizontalAxis?.sectionId || "",
        verticalAxisName: evaluation?.matrix?.verticalAxis?.name || "",
        verticalSectionId: evaluation?.matrix?.verticalAxis?.sectionId || "",
        quadrants: evaluation?.matrix?.quadrants || [],
      };

      return getObjDiffs(valuesToTest, baseValues);
    },
    [evaluation]
  );

  useEffect(() => {
    if (!evaluation) return;

    formik.setValues({
      horizontalAxisName: evaluation?.matrix?.horizontalAxis?.name || "",
      horizontalSectionId: evaluation?.matrix?.horizontalAxis?.sectionId || "",
      verticalAxisName: evaluation?.matrix?.verticalAxis?.name || "",
      verticalSectionId: evaluation?.matrix?.verticalAxis?.sectionId || "",
      quadrants: (evaluation?.matrix?.quadrants || []).length
        ? evaluation?.matrix?.quadrants || []
        : formik.initialValues.quadrants,
    });
  }, [evaluation]);

  return (
    <PageTemplate
      stepper={{
        steps: steps,
        activeStep: 5,
      }}
      routes={breadcrumbs || []}
      footer={{
        cancelProps: {
          title: "Sair sem salvar",
          callback: () => setWithoutSaveModal(true),
        },
        ...(evaluation?.evaluationStatus === "draft" && {
          draftProps: {
            disabled: isLoading || isUpdating || error,
            title: "Sair e salvar rascunho",
            callback: () => setExitAndSaveModal(true),
          },
        }),
        goBackProps: {
          title: (
            <>
              <Icons name="IconArrowLeft" fill="transparent" />
              Voltar
            </>
          ),
          callback: () => onNavigateByStep("form"),
        },
        confirmProps: {
          title: (
            <>
              Continuar
              <Icons name="IconArrowRight" fill="transparent" />
            </>
          ),
          disabled: error || isLoading,
          loading: isUpdating,
          callback: async () => {
            track({
              name: "people_strategic_hr_performance_company_evaluations_createevaluation_calibrationmatrix_continue_clicked",
            });

            const nextStep =
              origin === "calibration-matrix" ? "calibrationMatrix" : "review";

            const isFormEnabled =
              hasCalibrationStep &&
              hasByLeaderType &&
              hasTwoSections &&
              !partialDisabled;

            if (isFormEnabled) return formik.handleSubmit();

            onNavigateByStep(nextStep);
          },
        },
      }}
    >
      <Header>
        <StyledTitle
          setColor="neutral20"
          variant="headline6"
          children={`${isEdit ? "Editar" : "Criar"} Avaliação`}
        />
      </Header>
      <Grid container paddingBottom={"40px"} spacing={2}>
        <Grid item sm={12} md={12} lg={3} style={{ width: "100%" }}>
          <LeftContainer>
            <StyledTitle
              setColor="secondary50"
              variant="headline7"
              children={"Matriz de calibração"}
            />

            <StyledText
              setColor="neutral50"
              variant="body3"
              children={
                "Configure os eixos e quadrantes da matriz de calibração. Caso o seu ciclo contenha ”Calibração”, é obrigatório o preenchimento da matriz."
              }
            />
          </LeftContainer>
        </Grid>
        <Grid item sm={12} md={12} lg={9} style={{ width: "100%" }}>
          <CalibrationMatrixForm
            formik={formik}
            isLoading={isLoading}
            sections={evaluation?.sections || []}
            error={error}
            hasByLeaderType={hasByLeaderType}
            hasCalibrationStep={hasCalibrationStep}
            hasTwoSections={hasTwoSections}
            disabledEdit={disabled}
            disabledPartialEdit={partialDisabled}
          />
        </Grid>
      </Grid>

      <EvaluationWithoutSaveModal
        open={withoutSaveModal}
        onClose={() => setWithoutSaveModal(false)}
        onConfirm={() => {
          utils.performance.evaluation.getAllEvaluationsPaginated.invalidate();

          if (origin === "calibration-matrix" && evaluationId) {
            navigate({
              pathname: routes.PageManagerEvaluationDetails(evaluationId),
              search: createSearchParams({
                tab: "results",
                resultsTab: "matrix",
              }).toString(),
            });

            return;
          }

          navigate(routes.PageManageEvaluations);
        }}
      />

      <EvaluationWithSaveModal
        open={exitAndSaveModal}
        isLoading={isUpdating}
        onConfirm={async () => {
          const isFormEnabled =
            hasCalibrationStep &&
            hasByLeaderType &&
            hasTwoSections &&
            !partialDisabled;

          if (!isFormEnabled) return onNavigateByStep("manageEvaluations");

          const errors = await formik.validateForm();

          const values = formik.values;

          if (Object.keys(errors).length) {
            onNavigateByStep("manageEvaluations");
            return;
          }

          const hasChanges = formHasChanges(values);

          if (!hasChanges) return onNavigateByStep("manageEvaluations");

          const matrix = {
            horizontalAxis: {
              name: values.horizontalAxisName,
              sectionId: values.horizontalSectionId,
            },
            verticalAxis: {
              name: values.verticalAxisName,
              sectionId: values.verticalSectionId,
            },
            quadrants: values.quadrants,
          };

          onSubmitCustom({ matrix }, "manageEvaluations", "matrix");
        }}
        onClose={() => setExitAndSaveModal(false)}
      />
    </PageTemplate>
  );
};
