import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

export const BodySkeleton = () => {
  return (
    <>
      <div>
        <Skeleton width={50} style={{ marginBottom: 8 }} variant="rounded" />
        <Skeleton width={100} variant="rounded" />
      </div>

      <div>
        <Skeleton width={150} style={{ marginBottom: 8 }} variant="rounded" />

        <Skeleton
          width={"100%"}
          height={"50px"}
          variant="rounded"
          style={{ marginBottom: 8 }}
        />

        <Skeleton
          width={"100%"}
          height={"130px"}
          variant="rounded"
          style={{ marginBottom: 8 }}
        />

        <Skeleton width={"100%"} variant="rounded" height={"70px"} />
      </div>
    </>
  );
};
