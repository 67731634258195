import styled from "styled-components";

import { Drawer } from "@mui/material";
import { KnowMore } from "@assets/index";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 100%;
    max-width: 680px;
  }
`;

const Header = styled.div`
  padding: 40px 40px 24px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  position: relative;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  overflow: scroll;
  padding: 40px;
  gap: 40px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Card = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 24px;
  gap: 23px;

  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const LeftColumn = styled.div`
  width: 134px;
`;

const KnowMoreImg = styled.div`
  width: 134px;
  height: 134px;
  background-image: url(${KnowMore});
  background-size: cover;
  background-position: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style-position: inside;
`;

export {
  MainContainer,
  StyledDrawer,
  Header,
  Body,
  FlexColumn,
  Card,
  KnowMoreImg,
  RightColumn,
  LeftColumn,
  ContentContainer,
};
