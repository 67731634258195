import {
  ShapeIcon,
  ShapeIconOptions,
  ShapeIconProps,
  LinkButtonProps,
  PillButton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText, StyledTitle, StyledIcon } from "@utils";

import { DefaultFooterV2 } from "@components/Modal";

import type { CommonModalProps } from "@components/Modals/types";

import {
  StyledModal,
  ContentContainer,
  StyledIconTitle,
  IconContainer,
  StyledHeader,
  Survey,
} from "./styled";

export interface ConfirmationModalV2Props extends CommonModalProps {
  title: React.ReactNode | string;
  subTitle?: React.ReactNode | string;
  titleStyle?: React.CSSProperties;
  subTitleStyle?: React.CSSProperties;
  cancel?: {
    title: string;
    variant?: LinkButtonProps["variant"];
  };
  confirm?: {
    title: string;
    icon?: ShapeIconProps["name"];
    iconColor?: string;
    variant?: "primary" | "secondary";
    disabled?: boolean;
  };
  icon?: ShapeIconProps["name"];
  iconTitle?: React.ReactNode | string;
  variantType?: ShapeIconOptions;
  secondaryVariant?: ShapeIconOptions;
  surveyId?: string;
  closeButton?: boolean;
  children?: React.ReactNode;
}

export const ConfirmationModalV2 = ({
  open,
  isLoading,
  icon,
  iconTitle,
  title,
  subTitle,
  variantType = "default",
  titleStyle,
  subTitleStyle,
  cancel,
  confirm,
  secondaryVariant,
  children,
  surveyId,
  closeButton,
  onClose,
  onConfirm,
}: ConfirmationModalV2Props) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      header={
        closeButton ? (
          <StyledHeader>
            <PillButton
              variant="default"
              size="extra-small"
              icon="IconX"
              onClick={onClose}
            />
          </StyledHeader>
        ) : undefined
      }
      footer={
        <DefaultFooterV2
          variantType={variantType}
          secondaryVariant={secondaryVariant}
          isLoading={isLoading}
          cancelButton={cancel}
          confirmButton={
            confirm
              ? {
                  variant: confirm?.variant || "primary",
                  content: (
                    <>
                      {confirm.title}
                      {confirm.icon && (
                        <StyledIcon
                          setColor={confirm.iconColor || "error40"}
                          name={confirm.icon}
                          fill="none"
                          size={24}
                        />
                      )}
                    </>
                  ),
                  disabled: confirm.disabled,
                }
              : undefined
          }
          onClose={onClose}
          onConfirm={onConfirm}
        />
      }
    >
      <ContentContainer>
        {icon && (
          <IconContainer>
            <ShapeIcon
              variant={variantType}
              name={icon}
              size={48}
              stroke={variantType}
            />

            {iconTitle && (
              <StyledIconTitle
                variant="body4"
                color={variantType}
                children={iconTitle}
                weight={700}
              />
            )}
          </IconContainer>
        )}

        <StyledTitle
          variant="headline8"
          children={title}
          setColor="neutral10"
          style={{
            marginBottom: "8px",
            ...(titleStyle || {}),
          }}
        />

        {subTitle && (
          <Survey>
            <StyledText
              variant="body4"
              children={subTitle}
              setColor="neutral40"
              style={{
                marginBottom: "8px",
                ...(subTitleStyle || {}),
              }}
            />

            {surveyId && <div id={surveyId} />}
          </Survey>
        )}

        {children}
      </ContentContainer>
    </StyledModal>
  );
};
