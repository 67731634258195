import { useState, useEffect } from "react";

import { useQuadrant } from "./hooks";

import { SearchField } from "@components/SearchField";
import { TableFilters } from "@components/TableFilters";

import { Header, Participants } from "./components";

import type { Filter } from "./types";

import { Body, MainContainer, StyledDrawer } from "./styled";

interface IQuadrantDrawerProps {
  isOpen: boolean;
  quadrantId?: string;
  evaluationId?: string;
  onParticipantSelected: (evaluatedId: string) => void;
  onClose: () => void;
}

export const QuadrantDrawer = ({
  isOpen,
  quadrantId = "",
  evaluationId = "",
  onParticipantSelected,
  onClose,
}: IQuadrantDrawerProps) => {
  const [search, setSearch] = useState("");

  const { quadrant, filters, isLoading, filteredValues } = useQuadrant({
    isOpen,
    quadrantId,
    evaluationId,
  });

  const [internalFiltered, setInternalFiltered] = useState<Filter>(undefined);

  useEffect(() => {
    if (isOpen) {
      setInternalFiltered(filteredValues);
      return;
    }

    if (search) setSearch("");

    const hasFilters = Object.keys(filteredValues || {}).some(
      (key) => filteredValues?.[key]?.length
    );

    if (hasFilters) setInternalFiltered(undefined);
  }, [isOpen]);

  return (
    <StyledDrawer anchor="right" open={isOpen} onClose={onClose}>
      <MainContainer>
        <Header onClose={onClose} quadrant={quadrant} loading={isLoading} />

        <Body>
          <SearchField
            placeholder="Buscar participante"
            disabled={isLoading || !quadrant?.evaluateds?.length}
            onChange={setSearch}
          />

          {filters?.[0] && (
            <TableFilters
              filters={filters || []}
              onFilter={setInternalFiltered}
              style={{ marginBottom: 0 }}
            />
          )}

          <Participants
            items={quadrant?.evaluateds || []}
            search={search}
            filters={internalFiltered}
            isLoading={isLoading}
            onParticipantClick={(id) => onParticipantSelected(id)}
          />
        </Body>
      </MainContainer>
    </StyledDrawer>
  );
};
