import { useMemo, useCallback } from "react";

import { useSearchParams } from "react-router-dom";

import { cloneDeep } from "lodash-es";

import { stringify, parse } from "qs";

import type { Evaluation, FiltersResponse } from "./types";

export const useEmptyState = (evaluation: Evaluation) => {
  const hasCalibration = !!evaluation?.calibrationStartDate;

  const hasByLeader = !!evaluation?.types?.find(
    (item) => item.type === "byLeader"
  );

  const calibrationHasStarted = [
    "in_calibration",
    "calibration_finished",
  ].includes(evaluation.evaluationStatus);

  const isSectionsAvailable = evaluation?.sections?.length === 2;

  const isCreatedBefore =
    !["draft"].includes(evaluation?.evaluationStatus) && !hasCalibration;

  const showEditButton =
    isSectionsAvailable && evaluation?.evaluationStatus == "scheduled";

  const showEmptyState =
    !hasCalibration || !hasByLeader || !isSectionsAvailable || isCreatedBefore;

  return {
    hasCalibration,
    hasByLeader,
    calibrationHasStarted,
    isSectionsAvailable,
    isCreatedBefore,
    showEditButton,
    showEmptyState,
  };
};

export const useFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filteredValues = useMemo(() => {
    const filtersMatrix = searchParams.get("filtersMatrix") || {};

    const parsedFilters = parse(filtersMatrix) || {};

    const departmentIds: string[] = Array.isArray(parsedFilters?.departmentIds)
      ? parsedFilters.departmentIds || []
      : [];

    const evaluateds: string[] = Array.isArray(parsedFilters?.evaluateds)
      ? parsedFilters.evaluateds || []
      : [];

    const reportsTo: string[] = Array.isArray(parsedFilters?.reportsTo)
      ? parsedFilters.reportsTo || []
      : [];

    const roleId: string[] = Array.isArray(parsedFilters?.roleId)
      ? parsedFilters.roleId || []
      : [];

    return {
      departmentIds,
      evaluateds,
      reportsTo,
      roleId,
    };
  }, [searchParams]);

  const setFilteredValues = (filters: { [key: string]: any[] }) => {
    const hasFilters = !!Object.keys(filters).length;

    if (!hasFilters) searchParams.delete("filtersMatrix");

    if (hasFilters) {
      const queryParamsFilters = stringify(filters, { encode: false });

      searchParams.set("filtersMatrix", queryParamsFilters);
    }

    setSearchParams(searchParams);
  };

  const hasAnyFilteredValue = useCallback(() => {
    return Object.keys(filteredValues).some(
      (key) => !!filteredValues[key]?.length
    );
  }, [filteredValues]);

  const mapFiltersWithValues = (filters: FiltersResponse[]) => {
    const hasFiltered = hasAnyFilteredValue();

    if (!hasFiltered) return filters || [];

    const clonedFilters = cloneDeep(filters);

    const filtersWithValues = clonedFilters.map((f) => {
      const hasValue = !!filteredValues[f.accessor]?.length;

      return {
        ...f,
        initialValues: hasValue ? filteredValues[f.accessor] : undefined,
      };
    });

    return filtersWithValues;
  };

  return {
    filteredValues,
    setFilteredValues,
    mapFiltersWithValues,
  };
};
