import { PillButton, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { Grid } from "@mui/material";

import {
  StyledIcon,
  StyledSpan,
  StyledTitle,
  StyledText,
  avatarMaker,
} from "@utils";

import {
  FlexColumn,
  FlexContainer,
  ActionsContainer,
  HeaderButtons,
  IconContainer,
  MainContainer,
  StyledAvatar,
} from "./styled";

import type { EmployeeDataByEvaluation } from "server/src/services/employee/types";

interface HeaderProps {
  employeeData?: EmployeeDataByEvaluation;
  loading?: boolean;
  onClose: () => void;
}

export const Header = ({ employeeData, loading, onClose }: HeaderProps) => {
  return (
    <MainContainer>
      <div>
        <ActionsContainer>
          <IconContainer onClick={onClose}>
            <StyledIcon name="IconArrowLeft" setColor="neutral40" />
          </IconContainer>
          <HeaderButtons>
            <PillButton
              variant="default"
              size="small"
              icon="IconX"
              type="secondary"
              onClick={() => onClose()}
            />
          </HeaderButtons>
        </ActionsContainer>

        {loading && (
          <FlexContainer gap={"16px"}>
            <Skeleton variant="circular" children={<StyledAvatar />} />

            <div>
              <Skeleton variant="text" height="28px" width="150px" />
              <Skeleton variant="text" height="20px" width="100px" />
            </div>
          </FlexContainer>
        )}

        {!loading && (
          <FlexContainer gap={"16px"}>
            <StyledAvatar
              children={avatarMaker(employeeData?.evaluatedName || "-")}
            />

            <div>
              <StyledTitle
                variant="headline7"
                setColor="neutral20"
                children={employeeData?.evaluatedName || " - "}
              />

              {employeeData?.reportsToName && (
                <StyledText variant="body4" setColor="neutral40" weight={600}>
                  Reporta para{" "}
                  <StyledSpan
                    setColor="primary"
                    fontWeight={600}
                    children={employeeData?.reportsToName}
                  />
                </StyledText>
              )}
            </div>
          </FlexContainer>
        )}
      </div>

      <Grid container spacing={2}>
        <Grid item sm={12} md={5} width={"100%"}>
          <FlexColumn>
            <StyledText
              variant="body4"
              weight={600}
              setColor="neutral30"
              children="Avaliação"
            />

            {loading && <Skeleton variant="text" height="22px" width="100%" />}

            {!loading && (
              <StyledText
                variant="body3"
                weight={600}
                setColor="neutral30"
                children={employeeData?.evaluationName || " - "}
              />
            )}
          </FlexColumn>
        </Grid>

        <Grid item sm={12} md={3} width={"100%"}>
          <FlexColumn>
            <StyledText
              variant="body4"
              weight={600}
              setColor="neutral30"
              children="Nota final original"
            />

            {loading && <Skeleton variant="text" height="22px" width="100%" />}

            {!loading && (
              <StyledText
                variant="body3"
                weight={600}
                setColor="neutral30"
                children={employeeData?.originalAverage || " - "}
              />
            )}
          </FlexColumn>
        </Grid>

        <Grid item sm={12} md={4} width={"100%"}>
          <FlexColumn>
            <StyledText
              variant="body4"
              weight={600}
              setColor="neutral30"
              children="Quadrante original"
            />

            {loading && <Skeleton variant="text" height="22px" width="100%" />}

            {!loading && (
              <StyledText
                variant="body3"
                weight={600}
                setColor="neutral30"
                children={employeeData?.originalQuadrantName || " - "}
              />
            )}
          </FlexColumn>
        </Grid>
      </Grid>
    </MainContainer>
  );
};
