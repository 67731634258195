import { ConfirmationModal } from "@components/Modals";

import type { CommonModalProps } from "@components/Modals/types";

interface DuplicateSectionModalProps extends CommonModalProps {}

export const DuplicateSectionModal = ({
  open,
  onClose,
  onConfirm,
  isLoading,
}: DuplicateSectionModalProps) => {
  return (
    <ConfirmationModal
      open={open}
      showWarning
      icon="IconAlertCircle"
      title="Tem certeza que deseja adicionar uma nova seção?"
      subTitle={
        <>
          Ao adicionar <b>mais de duas seções</b> na avaliação,{" "}
          <b>não será possível</b> utilizar a ferramenta de{" "}
          <b>matriz de calibração</b> para ver os resultados dos participantes.
        </>
      }
      confirm={{
        title: "Adicionar nova seção",
      }}
      onClose={onClose}
      onConfirm={onConfirm}
      isLoading={isLoading}
    />
  );
};
