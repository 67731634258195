import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { PillButton, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";

import { routes } from "@routes";
import { ActionsContainer } from "./styled";

interface OptionsProps {
  _id: string;
  row: any;
  evaluationId?: string;
}

export const Options = ({ _id, row, evaluationId }: OptionsProps) => {
  const navigate = useNavigate();

  const { cycleId = "" } = useParams();

  return (
    <ActionsContainer>
      <Tooltip title={"Ver detalhes da pessoa"}>
        <div>
          <PillButton
            size="small"
            variant="default"
            type="secondary"
            icon="IconUser"
            onClick={() =>
              navigate({
                pathname: routes.PagePersonDetails(
                  "my-cycles",
                  _id,
                  evaluationId
                ),
                search: createSearchParams({ cycleId: cycleId }).toString(),
              })
            }
          />
        </div>
      </Tooltip>
      {row.canExpand ? (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <PillButton
              size="small"
              variant="default"
              type="secondary"
              icon="IconChevronUp"
            />
          ) : (
            <PillButton
              size="small"
              variant="default"
              type="secondary"
              icon="IconChevronDown"
            />
          )}
        </span>
      ) : (
        <PillButton
          size="small"
          variant="default"
          type="secondary"
          icon="IconChevronDown"
          disabled={true}
        />
      )}
    </ActionsContainer>
  );
};
