import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { cloneDeep } from "lodash-es";

import { StyledText, StyledTitle, dispatchToast } from "@utils";
import { Table } from "@components/Table";
import { trpc } from "@api/client";

import { SearchHeader } from "./components/SearchHeader";
import { BigNumbers } from "./components/BigNumbers";
import { TableGrid } from "./components/TableGrid";

import {
  EmptyStateContainer,
  EmptyStateImg,
  PageContainer,
  StyledDot,
  SubtitleRow,
} from "./styled";

interface IMyTeamProps {
  cycleId: string;
}

export const MyTeam = ({ cycleId }: IMyTeamProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useState("");

  const [insightsFilter, setInsightsFilter] = useState<
    "selfUnanswered" | "byLeaderUnanswered" | undefined
  >();

  const selectedEvaluationId = useMemo(
    () => searchParams.get("evaluationId") || "",
    [searchParams]
  );

  const { data: evaluationNames, isLoading: loadingEvaluationList } =
    trpc.performance.evaluation.getCycleEvaluationNamesByLeader.useQuery(
      { cycleId: cycleId },
      {
        enabled: !!cycleId,
        refetchOnMount: false,
        onError: () => {
          dispatchToast({
            content:
              "Erro ao carregar as avaliações do ciclo. Tente novamente em breve.",
            type: "error",
          });
        },
      }
    );

  const { data: evaluatedsDetails, isFetching } =
    trpc.performance.evaluation.getEvaluationDetailsResultsByLeader.useQuery(
      {
        evaluationId: selectedEvaluationId,
      },
      {
        enabled: !!selectedEvaluationId,
        refetchOnMount: false,
        onError: () => {
          dispatchToast({
            type: "error",
            content: "Erro ao buscar os resultados, tente novamente em breve!",
          });
        },
      }
    );

  useEffect(() => {
    if (!evaluationNames?.length) return;

    const hasEvaluationId = evaluationNames.find(
      (e) => e.value === selectedEvaluationId
    );

    if (hasEvaluationId) return;

    searchParams.set("evaluationId", evaluationNames[0].value);

    setSearchParams(searchParams);
  }, [evaluationNames]);

  const result = evaluatedsDetails?.results || [];

  const filteredData = useMemo(() => {
    const notFiltered =
      !result?.length && !search && insightsFilter === undefined;

    if (notFiltered) return result;

    let dataToFilter = cloneDeep(result || []);

    if (search) {
      const lowerCaseSearch = search.toLowerCase();

      dataToFilter = dataToFilter.filter((item) =>
        item.name.toLowerCase().includes(lowerCaseSearch)
      );
    }

    if (insightsFilter !== undefined) {
      dataToFilter = dataToFilter.filter((item) => {
        if (insightsFilter === "selfUnanswered") {
          return !("self" in item);
        }

        if (insightsFilter === "byLeaderUnanswered") {
          return !("byLeader" in item);
        }

        return false;
      });
    }

    return dataToFilter;
  }, [result, search, insightsFilter]);

  const renderContent = () => {
    if (evaluationNames?.length === 0) {
      return (
        <EmptyStateContainer>
          <EmptyStateImg />
          <StyledText
            variant="body3"
            style={{ textAlign: "center", maxWidth: 600 }}
            children={
              "Esse ciclo não contém avaliações atribuídas aos seus liderados"
            }
          />
        </EmptyStateContainer>
      );
    }

    if (!selectedEvaluationId) return <></>;

    return (
      <Table
        data={filteredData}
        filters={evaluatedsDetails?.filters || []}
        loading={isFetching}
        searchable={false}
        tableGridHeader={() => {
          return (
            <>
              {evaluatedsDetails?.insights && (
                <BigNumbers
                  onFilter={setInsightsFilter}
                  insights={evaluatedsDetails?.insights}
                  isLoading={isFetching}
                  types={evaluatedsDetails?.evaluationTypes || []}
                  evaluationId={selectedEvaluationId}
                  insightsFilter={insightsFilter}
                />
              )}

              {!isFetching && (
                <SubtitleRow>
                  <StyledTitle
                    children={`Legenda:`}
                    variant="body3"
                    setColor="neutral50"
                  />
                  <StyledDot variant={"pink"} />
                  <StyledTitle
                    children={"Avaliados calibrados"}
                    variant="body3"
                    setColor="neutral50"
                  />
                </SubtitleRow>
              )}
            </>
          );
        }}
        tableGridRender={(props) => (
          <TableGrid
            {...props}
            selectedEvaluationId={selectedEvaluationId}
            types={evaluatedsDetails?.evaluationTypes || []}
          />
        )}
      />
    );
  };

  return (
    <PageContainer>
      <SearchHeader
        key={cycleId}
        cycleId={cycleId}
        selectedEvaluationId={selectedEvaluationId}
        evaluationNames={evaluationNames || []}
        disabled={!evaluationNames?.length}
        isLoading={loadingEvaluationList}
        onEvaluationSelect={(evalId) => {
          searchParams.set("evaluationId", evalId);
          setSearchParams(searchParams);
        }}
        onSearch={setSearch}
      />
      {renderContent()}
    </PageContainer>
  );
};
