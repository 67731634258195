import { useMemo, useState } from "react";

import { trpc } from "@api/client";

import { dispatchToast } from "@utils";

import { TableFilters } from "@components/TableFilters";
import { SearchField } from "@components/SearchField";

import { CalibrationMatrix } from "./CalibrationMatrix";

import { EmptyState } from "./EmptyState";

import { useEmptyState, useFilters } from "./hooks";

import { MainContainer } from "./styled";

import type { Evaluation } from "./types";

interface IMatrixTabProps {
  evaluation: Evaluation;
}

export const Matrix = ({ evaluation }: IMatrixTabProps) => {
  const [search, setSearch] = useState("");

  const { showEmptyState, ...rest } = useEmptyState(evaluation);

  const { filteredValues, setFilteredValues, mapFiltersWithValues } =
    useFilters();

  const { data, isLoading: loadingMatrix } =
    trpc.performance.evaluation.getEvaluationDetailsMatrix.useQuery(
      {
        evaluationId: evaluation?._id || "",
        params: {
          search,
          filters: {
            departments: filteredValues.departmentIds,
            evaluateds: filteredValues.evaluateds,
            reportsTo: filteredValues.reportsTo,
            roles: filteredValues.roleId,
          },
        },
      },
      {
        enabled: !!evaluation?._id && !showEmptyState,
        onError: () => {
          dispatchToast({
            type: "error",
            content:
              "Erro ao buscar dados da matriz, tente novamente em breve!",
          });
        },
      }
    );

  const { data: filters, isLoading: loadingFilters } =
    trpc.performance.evaluation.getEvaluationDetailsMatrixFilters.useQuery(
      {
        evaluationId: evaluation?._id || "",
      },
      {
        enabled: !!evaluation?._id && !showEmptyState,
        onError: () => {
          dispatchToast({
            type: "error",
            content:
              "Erro ao buscar os filtros da matriz, tente novamente em breve!",
          });
        },
      }
    );

  const filtersWithValues = useMemo(
    () => mapFiltersWithValues(filters || []),
    [filters]
  );

  if (!evaluation) return <></>;

  return (
    <MainContainer>
      {!loadingFilters && filtersWithValues?.length && (
        <TableFilters
          style={{ marginBottom: "24px" }}
          filters={filtersWithValues}
          disabled={showEmptyState || !data?.quadrants.length}
          onFilter={setFilteredValues}
        />
      )}

      {showEmptyState && <EmptyState {...rest} />}

      {!showEmptyState && (
        <>
          <SearchField
            placeholder="Buscar por pessoa"
            disabled={!data?.quadrants.length || loadingMatrix}
            onChange={(searched) => setSearch(searched)}
          />

          <CalibrationMatrix
            data={data}
            evaluation={evaluation}
            loading={loadingMatrix}
          />
        </>
      )}
    </MainContainer>
  );
};
