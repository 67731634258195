import { EmptyState, Skeleton, dayjs } from "@flash-tecnologia/hros-web-ui-v2";

import TimelineItem from "@mui/lab/TimelineItem";

import {
  StyledIcon,
  StyledText,
  StyledTitle,
  dispatchToast,
  removeHtmlTags,
} from "@utils";

import { trpc } from "@api/client";

import {
  MainContainer,
  Bullet,
  Connector,
  Content,
  EmptyStateContainer,
  Item,
  LeftContent,
  RightColumn,
  RightContent,
  Separator,
} from "./styled";

interface IHistoryTimelineProps {
  employeeId?: string;
  evaluationId?: string;
}

export const HistoryTimeline = ({
  employeeId = "",
  evaluationId = "",
}: IHistoryTimelineProps) => {
  const { data: calibrationHistory = [], isFetching } =
    trpc.performance.evaluation.getCalibrationHistory.useQuery(
      {
        evaluationId,
        evaluatedId: employeeId,
      },
      {
        onError: () => {
          dispatchToast({
            content: "Erro ao buscar histórico. Tente novamente em breve.",
            type: "error",
          });
        },
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        enabled: !!evaluationId && !!employeeId,
      }
    );

  if (isFetching)
    return <Skeleton variant="rounded" width={"100%"} height={300} />;

  if (!calibrationHistory?.length)
    return (
      <EmptyStateContainer>
        <EmptyState
          buttonProps={{ size: "small", hidden: true }}
          buttonText=""
          description="Nenhum histórico encontrado"
        ></EmptyState>
      </EmptyStateContainer>
    );

  return (
    <MainContainer>
      {calibrationHistory.map((item, index) => {
        const lastItem = index + 1 === calibrationHistory.length;

        const firstItem = index === 0;

        return (
          <Item position="alternate" key={item._id}>
            <TimelineItem>
              <LeftContent style={{ flex: 0 }}>
                <StyledText
                  variant="caption"
                  setColor="neutral70"
                  fontWeight={600}
                  children={
                    dayjs(item?.createdAt).isValid()
                      ? dayjs(item?.createdAt).format("DD/MM/YYYY [às] HH:mm")
                      : " - "
                  }
                />
              </LeftContent>

              <Separator>
                {!firstItem && <Connector firstItem={true} />}

                <Bullet firstItem={firstItem} />

                {!lastItem && <Connector />}
              </Separator>

              <RightContent>
                <RightColumn>
                  <StyledTitle variant="headline9" setColor="neutral30">
                    Nota ajustada
                  </StyledTitle>

                  <Content>
                    <StyledText
                      variant="body4"
                      fontWeight={600}
                      setColor="neutral30"
                      style={{
                        textDecoration: "line-through",
                        wordBreak: "break-all",
                      }}
                      children={`${item?.previous} ${
                        item?.previousQuadrantName
                          ? `(${item?.previousQuadrantName})`
                          : ""
                      }`}
                    />

                    <StyledIcon
                      name="IconArrowRight"
                      setColor="neutral70"
                      style={{ flexShrink: 0 }}
                      size={16}
                    />

                    <StyledText
                      variant="body4"
                      fontWeight={600}
                      setColor="neutral30"
                      style={{ wordBreak: "break-all" }}
                      children={`${item?.updatedTo} ${
                        item?.updatedToQuadrantName
                          ? `(${item?.updatedToQuadrantName})`
                          : ""
                      }`}
                    />
                  </Content>

                  {item.reason && (
                    <StyledText
                      variant="body4"
                      setColor="neutral40"
                      style={{ marginBottom: lastItem ? "2px" : "16px" }}
                    >
                      <b>Justificativa:</b>
                      <div
                        // dangerouslySetInnerHTML={{
                        //   __html: item?.reason || "",
                        // }}
                        children={removeHtmlTags(item?.reason) || ""}
                        style={{
                          lineHeight: "normal",
                          listStylePosition: "inside",
                        }}
                      ></div>
                    </StyledText>
                  )}
                </RightColumn>
              </RightContent>
            </TimelineItem>
          </Item>
        );
      })}
    </MainContainer>
  );
};
