import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  width: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AxisLabelsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 6px;
  align-items: center;
  user-select: none;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  width: 100%;
`;

const IconContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;

export {
  MainContainer,
  FlexContainer,
  AxisLabelsContainer,
  Container,
  IconContainer,
};
