import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";

import { StyledIcon, StyledText, StyledTitle } from "@utils";

import { routes } from "@routes";

import {
  Container,
  AxisLabelsContainer,
  FlexContainer,
  IconContainer,
  MainContainer,
} from "./styled";

import type { AxisScales } from "../../../types";

interface IHorizontalAxisProps {
  scales: AxisScales[];
  horizontalName: string;
}

export const HorizontalAxis = ({
  scales,
  horizontalName,
}: IHorizontalAxisProps) => {
  const navigate = useNavigate();

  const { evaluationId = "" } = useParams();

  return (
    <MainContainer>
      <div style={{ minWidth: "98px", maxWidth: "98px" }} />

      <FlexContainer>
        <Grid container spacing={2}>
          {scales.map((scale) => (
            <Grid
              key={scale.label}
              item
              width={"100%"}
              xs={4}
              flexDirection={"row"}
            >
              <Container>
                <StyledText
                  variant="body2"
                  setColor="neutral20"
                  weight={600}
                  children={
                    <>
                      <b>{scale?.label}:</b> {scale?.init} - {scale?.end}
                    </>
                  }
                />
              </Container>
            </Grid>
          ))}
        </Grid>

        {horizontalName && (
          <AxisLabelsContainer>
            <StyledTitle
              variant="headline7"
              children={horizontalName}
              setColor="neutral20"
            />

            <IconContainer>
              <StyledIcon
                name="IconPencil"
                setColor="neutral40"
                size={24}
                onClick={() =>
                  navigate(routes.PageEditEvaluation("matrix", evaluationId), {
                    state: { origin: "calibration-matrix" },
                  })
                }
              />
            </IconContainer>
          </AxisLabelsContainer>
        )}
      </FlexContainer>
    </MainContainer>
  );
};
