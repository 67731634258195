import { flatMap } from "lodash-es";

import * as yup from "yup";

import { SectionType } from "./types";

export const validationSchema = yup.object({
  calibrationJustification: yup.string().optional(),
  sections: yup
    .array(
      yup.object({
        _id: yup.string().required(),
        average: yup
          .number()
          .required("Digite uma nota válida entre 1.0 e 5.0")
          .min(1, "Digite uma nota válida entre 1.0 e 5.0")
          .max(5, "Digite uma nota válida entre 1.0 e 5.0")
          .typeError("Digite uma nota válida entre 1.0 e 5.0"),
        calibrationAverage: yup
          .number()
          .optional()
          .min(1, "Digite uma nota válida entre 1.0 e 5.0")
          .max(5, "Digite uma nota válida entre 1.0 e 5.0")
          .typeError("Digite uma nota válida entre 1.0 e 5.0"),
        criterials: yup.array(
          yup.object({
            _id: yup.string().required(),
            value: yup
              .number()
              .required("Digite uma nota válida entre 1.0 e 5.0")
              .min(1, "Digite uma nota válida entre 1.0 e 5.0")
              .max(5, "Digite uma nota válida entre 1.0 e 5.0")
              .typeError("Digite uma nota válida entre 1.0 e 5.0"),
            calibrationValue: yup
              .number()
              .optional()
              .min(1, "Digite uma nota válida entre 1.0 e 5.0")
              .max(5, "Digite uma nota válida entre 1.0 e 5.0")
              .typeError("Digite uma nota válida entre 1.0 e 5.0"),
          })
        ),
      })
    )
    .min(1),
});

export const compareSections = (
  currentSections: SectionType[],
  calibrationSections: SectionType[]
) => {
  if (!calibrationSections || !currentSections) return false;

  const criterialsGrades = flatMap(currentSections || [], (item) =>
    (item.criterials || []).map((c) => ({ sectionId: item._id, ...c }))
  );

  const criterialsCalibrations = flatMap(calibrationSections, (item) =>
    (item.criterials || []).map((c) => ({ sectionId: item._id, ...c }))
  );

  const hasChanges = criterialsGrades.some((criterial) => {
    const calibratedCriterial = criterialsCalibrations.find(
      (calibrationCriterial) =>
        criterial.sectionId === calibrationCriterial.sectionId &&
        criterial._id === calibrationCriterial._id
    );

    if (!calibratedCriterial) return false;

    return calibratedCriterial.calibrationValue !== criterial.calibrationValue;
  });

  return hasChanges;
};
