import { Avatar } from "@mui/material";
import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 40px;
  background-color: ${({ theme }) => theme.colors.secondary[99]};
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledAvatar = styled(Avatar)`
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.primary};
  width: 57px;
  height: 57px;
  font-size: 30px;
`;

export const FlexContainer = styled.div<{ gap: string }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap};
`;

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  gap: 16px;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const IconContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;
