import * as yup from "yup";

import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";

export type CreateEditCycleFormProps = {
  name: string;
  description: string;
  evaluationCheckbox: boolean;
  evaluationStartDate: string;
  evaluationEndDate: string;
  calibrationCheckbox: boolean;
  calibrationStartDate: string;
  calibrationEndDate: string;
  feedbackCheckbox: boolean;
  feedbackStartDate: string;
  feedbackEndDate: string;
  idpCheckbox: boolean;
  idpStartDate: string;
  idpEndDate: string;
};

export const validationSchema = yup.object({
  name: yup
    .string()
    .required("Este campo deve ser preenchido")
    .max(50, "O nome deve ter até 50 caracteres"),
  description: yup.string().optional(),
  evaluationCheckbox: yup.boolean(),
  evaluationStartDate: yup
    .date()
    .test("hasContent", "Este campo deve ser preenchido", function (value) {
      const { evaluationCheckbox: checkbox } = this.parent;
      if (checkbox) return Boolean(value);
      return true;
    })
    .test(
      "isBeforeCalibration",
      "A data inicial não pode ser posterior a calibração",
      function (value) {
        const { calibrationCheckbox, calibrationStartDate } = this.parent;

        if (calibrationCheckbox && calibrationStartDate?.length)
          return dayjs(value).isBefore(calibrationStartDate, "day");

        return true;
      }
    )
    .test(
      "isBeforeFeedbacks",
      "A data inicial não pode ser posterior a feedbacks",
      function (value) {
        const { feedbackCheckbox, feedbackStartDate } = this.parent;

        if (feedbackCheckbox && feedbackStartDate?.length)
          return dayjs(value).isBefore(feedbackStartDate, "day");

        return true;
      }
    )
    .test(
      "isBeforeIDPs",
      "A data inicial não pode ser posterior a PDI's",
      function (value) {
        const { idpCheckbox, idpStartDate } = this.parent;

        if (idpCheckbox && idpStartDate?.length)
          return dayjs(value).isBefore(idpStartDate, "day");

        return true;
      }
    )
    .test(
      "isSameOfEnd",
      "A data inicial não pode ser igual à data final.",
      function (value) {
        const { evaluationEndDate } = this.parent;

        if (evaluationEndDate) return !dayjs(value).isSame(evaluationEndDate);

        return true;
      }
    ),
  evaluationEndDate: yup
    .date()
    .test("hasContent", "Este campo deve ser preenchido", function (value) {
      const { evaluationCheckbox: checkbox } = this.parent;
      if (checkbox) return Boolean(value);
      return true;
    })
    .test(
      "isBeforeCalibration",
      "A data inicial não pode ser posterior a calibração",
      function (value) {
        const { calibrationCheckbox, calibrationStartDate } = this.parent;

        if (calibrationCheckbox && calibrationStartDate?.length)
          return dayjs(value).isBefore(calibrationStartDate, "day");

        return true;
      }
    )
    .test(
      "isBeforeFeedbacks",
      "A data inicial não pode ser posterior a feedbacks",
      function (value) {
        const { feedbackCheckbox, feedbackStartDate } = this.parent;

        if (feedbackCheckbox && feedbackStartDate?.length)
          return dayjs(value).isBefore(feedbackStartDate, "day");

        return true;
      }
    )
    .test(
      "isBeforeIDPs",
      "A data inicial não pode ser posterior a PDI's",
      function (value) {
        const { idpCheckbox, idpStartDate } = this.parent;

        if (idpCheckbox && idpStartDate?.length)
          return dayjs(value).isBefore(idpStartDate, "day");

        return true;
      }
    ),
  calibrationCheckbox: yup.boolean(),
  calibrationStartDate: yup
    .date()
    .test("hasContent", "Este campo deve ser preenchido", function (value) {
      const { calibrationCheckbox: checkbox } = this.parent;
      if (checkbox) return Boolean(value);
      return true;
    })
    .test(
      "isAfterEvaluation",
      "A data não pode anteceder a data final das avaliações",
      function (value) {
        const { evaluationEndDate } = this.parent;
        if (value) return dayjs(value).isAfter(evaluationEndDate, "day");
        return true;
      }
    )
    .test(
      "isBeforeFeedbacks",
      "A data não pode ser posterior ao início dos Feedbacks",
      function (value) {
        const { feedbackCheckbox: checkbox, feedbackStartDate } = this.parent;
        if (value && checkbox)
          return dayjs(value).isBefore(feedbackStartDate, "day");
        return true;
      }
    )
    .test(
      "isBeforeIdps",
      "A data não pode ser posterior ao início dos PDI's.",
      function (value) {
        const { idpCheckbox: checkbox, idpStartDate } = this.parent;
        if (value && checkbox)
          return dayjs(value).isBefore(idpStartDate, "day");
        return true;
      }
    )
    .test(
      "isSameOfEnd",
      "A data inicial não pode ser igual à data final.",
      function (value) {
        const { calibrationEndDate } = this.parent;

        if (calibrationEndDate) return !dayjs(value).isSame(calibrationEndDate);

        return true;
      }
    ),
  calibrationEndDate: yup
    .date()
    .test("hasContent", "Este campo deve ser preenchido", function (value) {
      const { calibrationCheckbox: checkbox } = this.parent;
      if (checkbox) return Boolean(value);
      return true;
    })
    .test(
      "isAfterEvaluation",
      "A data não pode anteceder a data final das avaliações",
      function (value) {
        const { evaluationEndDate } = this.parent;
        if (value) return dayjs(value).isAfter(evaluationEndDate, "day");
        return true;
      }
    )
    .test(
      "isBeforeFeedbacks",
      "A data não pode ser posterior ao início dos Feedbacks",
      function (value) {
        const { feedbackCheckbox: checkbox, feedbackStartDate } = this.parent;
        if (value && checkbox)
          return dayjs(value).isBefore(feedbackStartDate, "day");
        return true;
      }
    )
    .test(
      "isBeforeIdps",
      "A data não pode ser posterior ao início dos PDI's.",
      function (value) {
        const { idpCheckbox: checkbox, idpStartDate } = this.parent;
        if (value && checkbox)
          return dayjs(value).isBefore(idpStartDate, "day");
        return true;
      }
    ),
  feedbackCheckbox: yup.boolean(),
  feedbackStartDate: yup
    .date()
    .test("hasContent", "Este campo deve ser preenchido", function (value) {
      const { feedbackCheckbox: checkbox } = this.parent;
      if (checkbox) return Boolean(value);
      return true;
    })
    .test(
      "isAfterCalibration",
      "A data não pode anteceder a data final de calibração",
      function (value) {
        const { calibrationCheckbox: checkbox, calibrationEndDate } =
          this.parent;
        if (checkbox && value)
          return dayjs(value).isAfter(calibrationEndDate, "day");
        return true;
      }
    )
    .test(
      "isAfterEvaluation",
      "A data não pode anteceder a data final das Avaliações.",
      function (value) {
        const { evaluationEndDate } = this.parent;
        if (value) return dayjs(value).isAfter(evaluationEndDate, "day");
        return true;
      }
    )
    .test(
      "isSameOfEnd",
      "A data inicial não pode ser igual à data final.",
      function (value) {
        const { feedbackEndDate } = this.parent;

        if (feedbackEndDate) return !dayjs(value).isSame(feedbackEndDate);

        return true;
      }
    ),
  feedbackEndDate: yup
    .date()
    .test("hasContent", "Este campo deve ser preenchido", function (value) {
      const { feedbackCheckbox: checkbox } = this.parent;
      if (checkbox) return Boolean(value);
      return true;
    })
    .test(
      "isAfterCalibration",
      "A data não pode anteceder a data final de calibração",
      function (value) {
        const { calibrationCheckbox: checkbox, calibrationEndDate } =
          this.parent;
        if (checkbox && value)
          return dayjs(value).isAfter(calibrationEndDate, "day");
        return true;
      }
    )
    .test(
      "isAfterEvaluation",
      "A data não pode anteceder a data final das Avaliações.",
      function (value) {
        const { evaluationEndDate } = this.parent;
        if (value) return dayjs(value).isAfter(evaluationEndDate, "day");
        return true;
      }
    ),
  idpCheckbox: yup.boolean(),
  idpStartDate: yup
    .date()
    .test("hasContent", "Este campo deve ser preenchido", function (value) {
      const { idpCheckbox: checkbox } = this.parent;
      if (checkbox) return Boolean(value);
      return true;
    })
    .test(
      "isAfterCalibration",
      "A data não pode anteceder a data final de calibração",
      function (value) {
        const { calibrationCheckbox: checkbox, calibrationEndDate } =
          this.parent;
        if (checkbox && value)
          return dayjs(value).isAfter(calibrationEndDate, "day");
        return true;
      }
    )
    .test(
      "isAfterEvaluation",
      "A data não pode anteceder a data final das Avaliações.",
      function (value) {
        const { evaluationEndDate } = this.parent;
        if (value) return dayjs(value).isAfter(evaluationEndDate, "day");
        return true;
      }
    )
    .test(
      "isSameOfEnd",
      "A data inicial não pode ser igual à data final.",
      function (value) {
        const { idpEndDate } = this.parent;

        if (idpEndDate) return !dayjs(value).isSame(idpEndDate);

        return true;
      }
    ),
  idpEndDate: yup
    .date()
    .test("hasContent", "Este campo deve ser preenchido", function (value) {
      const { idpCheckbox: checkbox } = this.parent;
      if (checkbox) return Boolean(value);
      return true;
    })
    .test(
      "isAfterCalibration",
      "A data não pode anteceder a data final de calibração",
      function (value) {
        const { calibrationCheckbox: checkbox, calibrationEndDate } =
          this.parent;
        if (checkbox && value)
          return dayjs(value).isAfter(calibrationEndDate, "day");
        return true;
      }
    )
    .test(
      "isAfterEvaluation",
      "A data não pode anteceder a data final das Avaliações.",
      function (value) {
        const { evaluationEndDate } = this.parent;
        if (value) return dayjs(value).isAfter(evaluationEndDate, "day");
        return true;
      }
    ),
});
