import { Grid } from "@mui/material";

import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { MainContainer, LabelColumn } from "./styled";

export const LoadingSkeleton = () => {
  const matrix = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <MainContainer>
      <LabelColumn>
        <Skeleton variant="rounded" width="100%" height={"100%"} />
      </LabelColumn>

      <Grid container spacing={2}>
        {matrix.map((item) => (
          <Grid item width={"100%"} xs={4} key={item}>
            <Skeleton variant="rounded" width={"100%"} height={"200px"} />
          </Grid>
        ))}
      </Grid>
    </MainContainer>
  );
};
