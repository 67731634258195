import { FormikProps } from "formik";

import {
  DatePicker,
  dayjs,
  Icons,
  Skeleton,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";

import { RequiredAsterisk, StyledText, track } from "@utils";

import { ContentArea, DatePickerArea, StyledCheckBox, Title } from "./styled";

import { CreateEditCycleFormProps } from "../../types";
import { ReactNode } from "react";

type IDatePeriodBox = {
  title: string;
  description: string;
  disabledAddStep?: boolean;
  disabledStartDateChanges?: boolean;
  disabledEndDateChanges?: boolean;
  tooltipInfo?: string | ReactNode;
  startLabel: string;
  endLabel: string;
  startProp: string;
  endProp: string;
  checkboxProp: string;
  prevDateProp?: string;
  tracker?: string | undefined;
  formik: FormikProps<CreateEditCycleFormProps>;
  isLoading?: boolean;
};

export const DatePeriodBox = ({
  title,
  disabledAddStep,
  disabledStartDateChanges,
  disabledEndDateChanges,
  description,
  tooltipInfo,
  startLabel,
  endLabel,
  startProp,
  endProp,
  checkboxProp,
  prevDateProp,
  tracker,
  formik,
  isLoading = false,
}: IDatePeriodBox) => (
  <ContentArea>
    <StyledCheckBox
      disabled={disabledAddStep || checkboxProp === "evaluationCheckbox"}
      checked={formik.values?.[checkboxProp]}
      onChange={(_, value) => {
        //O trecho de código abaixo é o mesmo que utilizamos em LMS mas sem o ts-ignore,
        //como nativamente o campo de data não esta limpando corretamente, segui no mesmo padrão de LMS
        if (checkboxProp === "evaluationCheckbox") return;
        if (!value) {
          const inputElementStart = document.getElementById(
            `input_reset_${startProp}`
          ) as HTMLInputElement;
          const inputElementEnd = document.getElementById(
            `input_reset_${endProp}`
          ) as HTMLInputElement;
          if (inputElementStart && inputElementEnd) {
            inputElementStart.value = "";
            inputElementEnd.value = "";
          }
          formik.handleChange({
            target: {
              id: startProp,
              value: "",
            },
          });
          formik.handleChange({
            target: {
              id: endProp,
              value: "",
            },
          });
        }

        formik.handleChange({
          target: {
            id: checkboxProp,
            value,
          },
        });

        if (tracker && value) track({ name: tracker });
      }}
    />
    <div style={{ width: "100%" }}>
      <div>
        <Title variant="body3" weight={600} setColor="neutral30">
          {title}
          {tooltipInfo && (
            <Tooltip
              title={tooltipInfo}
              style={{ marginLeft: "4px", cursor: "pointer" }}
            >
              <div>
                <Icons name="IconInfoCircle" fill="transparent" size={16} />
              </div>
            </Tooltip>
          )}
        </Title>
        <StyledText variant="body4" weight={400} setColor="neutral50">
          {description}
        </StyledText>
      </div>
      <DatePickerArea>
        {isLoading ? (
          <Skeleton width={"100%"} height={"58px"} />
        ) : (
          <>
            <DatePicker
              value={formik.values?.[startProp]}
              fromDate={
                prevDateProp && formik.values?.[prevDateProp]
                  ? (dayjs(formik.values?.[prevDateProp]).add(1, "day") as any)
                  : new Date()
              }
              disabled={
                disabledStartDateChanges || !formik.values?.[checkboxProp]
              }
              name={startProp}
              label={
                <>
                  {startLabel}
                  <RequiredAsterisk />
                </>
              }
              toDate={formik.values?.[endProp] as any}
              id={`input_reset_${startProp}`}
              onDateChange={(value) => {
                const date =
                  value && dayjs(value).isValid() ? dayjs(value) : null;

                if (!date) return;

                const parsedValue = dayjs(value).isValid()
                  ? date.toISOString()
                  : null;

                formik.handleChange({
                  target: {
                    id: startProp,
                    value: parsedValue,
                  },
                });
              }}
              error={
                formik?.touched?.[startProp] &&
                Boolean(formik?.errors?.[startProp])
              }
              helperText={
                formik?.touched?.[startProp] &&
                formik?.errors?.[startProp] &&
                formik?.errors?.[startProp]?.toString()
              }
              fullWidth
            />
            <DatePicker
              value={formik.values?.[endProp]}
              fromDate={dayjs(formik.values?.[startProp]).add(1, "day") as any}
              disabled={
                disabledEndDateChanges || !formik.values?.[checkboxProp]
              }
              name={endProp}
              label={
                <>
                  {endLabel}
                  <RequiredAsterisk />
                </>
              }
              id={`input_reset_${endProp}`}
              onDateChange={(value) => {
                const date =
                  value && dayjs(value).isValid() ? dayjs(value) : null;

                if (!date) return;

                const parsedValue = dayjs(value).isValid()
                  ? date.toISOString()
                  : null;

                formik.handleChange({
                  target: {
                    id: endProp,
                    value: parsedValue,
                  },
                });
              }}
              error={
                formik?.touched?.[endProp] && Boolean(formik?.errors?.[endProp])
              }
              helperText={
                formik?.touched?.[endProp] &&
                formik?.errors?.[endProp] &&
                formik?.errors?.[endProp]?.toString()
              }
              fullWidth
            />
          </>
        )}
      </DatePickerArea>
    </div>
  </ContentArea>
);
