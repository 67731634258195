import { FormikProps } from "formik";

import {
  Accordion,
  EmptyState,
  Skeleton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { TextEditor } from "@components/TextEditor";

import { CalibrationSections } from "./CalibrationSections";

import { EmptyStateContainer } from "./styled";

import type { CalibrationFormType } from "../../../types";

interface ICalibrationProps {
  formik: FormikProps<CalibrationFormType>;
  loading: boolean;
}

export const Calibration = ({ formik, loading }: ICalibrationProps) => {
  const sections = formik.values?.sections || [];

  if (loading) {
    return <Skeleton variant="rounded" width={"100%"} height={300} />;
  }

  if (!sections.length) {
    return (
      <EmptyStateContainer>
        <EmptyState
          buttonProps={{ size: "small", hidden: true }}
          buttonText=""
          description="Não foi possível encontrar as notas do colaborador"
        ></EmptyState>
      </EmptyStateContainer>
    );
  }

  return (
    <>
      <Accordion
        style={{ marginTop: "0px", marginBottom: "32px" }}
        variant="default"
        title={"Critérios avaliados"}
        defaultExpanded={true}
        description={"Calibre os critérios abaixo e gere uma nova nota final."}
        children={<CalibrationSections formik={formik} />}
      />

      <TextEditor
        styles={{
          container: { width: "100%" },
          editor: { height: "140px" },
        }}
        id="calibrationJustification"
        placeholder={`Justificativa (Opcional)`}
        value={formik?.values?.calibrationJustification}
        onChange={(value) => {
          const handleValue = value !== "<p><br></p>" ? value : "";

          formik?.setFieldValue("calibrationJustification", handleValue);
        }}
        maxLength={1000}
      />
    </>
  );
};
