import { useEffect, useMemo, useState } from "react";

import {
  EmptyState,
  SelectField,
  Skeleton,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2";

import { dispatchToast } from "@utils";

import { trpc } from "@api/client";

import { Answers } from "./Answers";

import { EmptyStateContainer, Header, MainContainer } from "./styled";

type SelectedEvaluator = {
  type: "self" | "byLeader" | "byLed" | "";
  _id: string;
  name: string;
};

interface IEvaluationProps {
  employeeId: string;
  evaluationId: string;
}

export const Evaluation = ({
  employeeId = "",
  evaluationId = "",
}: IEvaluationProps) => {
  const [selectedEvaluator, setSelectedEvaluator] = useState<SelectedEvaluator>(
    {
      _id: "",
      type: "",
      name: "",
    }
  );

  useEffect(() => {
    setSelectedEvaluator({ _id: "", type: "", name: "" });
  }, [evaluationId]);

  const { data: evaluators = [], isFetching: loadingEvaluators } =
    trpc.performance.evaluation.getEvaluatorsNamesByEvaluated.useQuery(
      {
        evaluatedId: employeeId,
        evaluationId: evaluationId,
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: !!evaluationId && !!employeeId,
        onError: () => {
          dispatchToast({
            content: "Erro ao buscar avaliadores. Tente novamente em breve.",
            type: "error",
          });
        },
      }
    );

  const { data: employeeResults, isFetching: loadingResults } =
    trpc.performance.evaluation.getEmployeeResultsByEvaluator.useQuery(
      {
        evaluatedId: employeeId,
        evaluationId: evaluationId,
        evaluatorId: selectedEvaluator?._id,
        type: selectedEvaluator?.type || "self",
      },
      {
        onError: () => {
          dispatchToast({
            content:
              "Erro ao buscar as respostas do avaliador. Tente novamente em breve.",
            type: "error",
          });
        },
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled:
          !!selectedEvaluator?._id &&
          !!selectedEvaluator?.type &&
          !!evaluationId,
      }
    );

  const evaluatorsOptions = useMemo(() => {
    if (!evaluators?.length) return [];

    const parsedEvaluatorsList = evaluators?.map((evaluator) => {
      return {
        label: evaluator.label,
        value: evaluator._id,
      };
    });

    return parsedEvaluatorsList;
  }, [evaluators]);

  useEffect(() => {
    if (!evaluators?.length) return;

    if (!!selectedEvaluator._id) return;

    const byLeader = evaluators.find((e) => e.type === "byLeader");

    if (byLeader) {
      setSelectedEvaluator({
        _id: byLeader._id,
        type: byLeader.type,
        name: byLeader.name,
      });
    }
  }, [evaluators]);

  if (loadingEvaluators)
    return <Skeleton width={"100%"} height={60} variant={"rounded"} />;

  if (!evaluatorsOptions?.length)
    return (
      <EmptyStateContainer>
        <EmptyState
          buttonProps={{ size: "small", hidden: true }}
          buttonText=""
          description="Nenhum avaliador encontrado"
        ></EmptyState>
      </EmptyStateContainer>
    );

  return (
    <MainContainer>
      <Header>
        <SelectField
          label="Selecionar avaliador"
          value={selectedEvaluator._id}
          options={evaluatorsOptions}
          disabled={!evaluatorsOptions.length}
          noOptionsText="Nenhum avaliador encontrado"
          onSelectChange={(_, selected) => {
            const evaluator = (evaluators || []).find(
              (evaluator) => evaluator._id === selected?.value
            );

            if (!evaluator) return;

            setSelectedEvaluator({
              _id: evaluator._id,
              type: evaluator.type,
              name: evaluator.name,
            });
          }}
          fullWidth
        />

        <TextField
          label="Nota do avaliador"
          value={employeeResults?.insights.average || ""}
          disabled
        />
      </Header>

      <Answers
        isLoading={loadingResults}
        sections={employeeResults?.sections || []}
        scales={employeeResults?.scale}
      />
    </MainContainer>
  );
};
