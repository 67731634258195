import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  PillButton,
  Icons,
  Menu,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  WordsConfirmationModal,
  CloseEvaluationModal,
  ExportEvaluationModal,
} from "@components/Modals";

import { trpc } from "@api/client";
import { routes } from "@routes";

import { StyledIcon, StyledText, dispatchToast } from "@utils";

import { Option } from "./styled";

export const EvaluationOptions = ({ status, evaluationId, daysToExpire }) => {
  const navigate = useNavigate();
  const utils = trpc.useContext();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [closeEvaluationModal, setCloseEvaluationModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);

  const refetch = () =>
    utils.performance.evaluation.getAllEvaluationsPaginated.invalidate();

  const { mutate: deleteEvaluation, isLoading: isDeleting } =
    trpc.performance.evaluation.deleteEvaluation.useMutation({
      onSuccess: () => {
        refetch();
        setOpenDeleteModal(false);
        dispatchToast({
          type: "success",
          content: "Avaliação excluida com sucesso.",
        });
      },

      onError: () => {
        dispatchToast({
          type: "error",
          content:
            "Não foi possível excluir a avaliação. Tente novamente em breve.",
        });
      },
    });

  const { mutate: duplicateEvaluation } =
    trpc.performance.evaluation.duplicateEvaluation.useMutation({
      onSuccess: () => {
        refetch();
        dispatchToast({
          type: "success",
          content: "Avaliação duplicada com sucesso.",
        });
      },

      onError: () => {
        dispatchToast({
          type: "error",
          content:
            "Não foi possível duplicar a avaliação. Tente novamente em breve.",
        });
      },
    });

  const { mutate: closeMutate, isLoading: isClosing } =
    trpc.performance.evaluation.closeEvaluation.useMutation({
      onSuccess: () => {
        refetch();
        setCloseEvaluationModal(false);
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao encerrar a avaliação, tente novamente mais tarde!",
        });
      },
    });

  const options = useMemo(() => {
    let items = [
      {
        key: "details",
        onClick: () =>
          navigate(routes.PageManagerEvaluationDetails(evaluationId)),
        children: (
          <Option>
            <Icons name="IconDeviceAnalytics" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              Ver detalhes da avaliação
            </Typography>
          </Option>
        ),
      },
      {
        key: "preview",
        onClick: () => {
          navigate(routes.PagePreviewEvaluation(evaluationId, "1"));
        },
        children: (
          <Option>
            <Icons name="IconEye" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              Pré-visualizar avaliação
            </Typography>
          </Option>
        ),
      },
      {
        key: "edit",
        onClick: () => {
          navigate(routes.PageEditEvaluation("basic-info", evaluationId));
        },
        children: (
          <Option>
            <Icons name="IconPencil" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              Editar avaliação
            </Typography>
          </Option>
        ),
      },
      {
        key: "export",
        onClick: () => setExportModal(true),
        children: (
          <Option>
            <Icons name="IconDownload" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              Exportar relatório
            </Typography>
          </Option>
        ),
      },
      {
        key: "close",
        onClick: () => setCloseEvaluationModal(true),
        children: (
          <Option>
            <Icons name="IconX" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              Encerrar avaliação
            </Typography>
          </Option>
        ),
      },

      {
        key: "duplicate",
        onClick: () => duplicateEvaluation({ evaluationId }),
        children: (
          <Option>
            <Icons name="IconCopy" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              Duplicar Avaliação
            </Typography>
          </Option>
        ),
      },
      {
        key: "delete",
        onClick: () => setOpenDeleteModal(true),
        children: (
          <Option>
            <StyledIcon
              name="IconTrash"
              fill="transparent"
              setColor="error50"
            />
            <StyledText
              variant="body3"
              style={{ fontWeight: 600 }}
              setColor="error50"
            >
              Excluir avaliação
            </StyledText>
          </Option>
        ),
      },
    ];

    if (status !== "active")
      items = items.filter((i) => i.key !== "preview" && i.key !== "close");
    if (!evaluationId || status === "draft")
      items = items.filter((i) => i.key !== "details" && i.key !== "export");
    if (
      status === "finished" ||
      status === "in_calibration" ||
      status === "calibration_finished"
    )
      items = items.filter((i) => i.key !== "edit");

    return items;
  }, [status, evaluationId]);

  return (
    <>
      <Menu
        type={"select"}
        options={options}
        disableAutoFocusItem={true}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <PillButton
          size="small"
          variant="default"
          type="secondary"
          icon="IconDotsVertical"
        />
      </Menu>

      <WordsConfirmationModal
        wordToConfirm={"EXCLUIR"}
        showWarning
        subTitle="Todos os dados e resultados relacionados serão perdidos e essa ação não poderá ser desfeita."
        title={"Tem certeza que deseja excluir esta avaliação?"}
        icon={"IconAlertCircle"}
        confirm={{
          title: "Confirmar exclusão",
          icon: "IconTrash",
        }}
        isLoading={isDeleting}
        open={openDeleteModal}
        onConfirm={() => deleteEvaluation({ evaluationId })}
        onClose={() => setOpenDeleteModal(false)}
      />

      <CloseEvaluationModal
        open={closeEvaluationModal}
        onClose={() => setCloseEvaluationModal(false)}
        isLoading={isClosing}
        daysToExpire={daysToExpire}
        onConfirm={() => {
          closeMutate({ evaluationId });
        }}
      />

      <ExportEvaluationModal
        open={exportModal}
        onClose={() => setExportModal(false)}
        evaluationId={evaluationId}
      />
    </>
  );
};
