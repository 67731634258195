import { useMemo } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

import {
  EmptyState,
  Icons,
  dayjs,
  Typography,
  Tag,
  Button,
  Dot,
} from "@flash-tecnologia/hros-web-ui-v2";

import { routes } from "@routes";

import { renderHtmlText } from "@utils";

import { OverflowCheck } from "@components/OverflowCheck";

import { EvaluationOptions } from "./EvaluationOptions";

import { CellArea, CycleCellArea, StyledText } from "./styled";

import { DataGrid } from "@components/DataGrid";

import type { TableGridCommonProps } from "@components/PaginationTable/types";

import type { TColumnProps } from "@components/DataGrid/types";

interface ITableGridProps extends TableGridCommonProps {
  onCreateClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const TableGrid = ({
  data,
  pagination,
  loading,
  isFiltered,
  onPaginationChanged,
  onCreateClick,
}: ITableGridProps) => {
  const navigate = useNavigate();

  const columns = useMemo<TColumnProps<any>>(
    () => [
      {
        header: "Informações privadas",
        disableSortBy: true,
        accessorKey: "privateName",
        cell: ({ row: { original } }) => {
          const { privateName, privateDescription = "" } = original;

          return (
            <CellArea>
              <StyledText variant="body3">
                {OverflowCheck({ text: privateName, width: "210px" })}
              </StyledText>
              <Typography
                variant="body4"
                children={renderHtmlText(privateDescription)}
              />
            </CellArea>
          );
        },
      },
      {
        header: "Informações públicas",
        disableSortBy: true,
        accessorKey: "name",
        cell: ({ row: { original } }) => {
          const { name, description } = original;
          return (
            <CellArea>
              <StyledText variant="body3">
                {OverflowCheck({ text: name, width: "210px" })}
              </StyledText>
              <Typography
                variant="body4"
                children={renderHtmlText(description)}
              />
            </CellArea>
          );
        },
      },
      {
        header: "Ciclo",
        disableSortBy: true,
        accessorKey: "cycleName",
        cell: (props) => {
          const value = props.getValue();

          return (
            <CycleCellArea>
              <StyledText variant="body3">
                {OverflowCheck({
                  text: renderHtmlText(value),
                  width: "210px",
                })}
              </StyledText>
            </CycleCellArea>
          );
        },
      },
      {
        header: "Status",
        accessorKey: "evaluationStatus",
        disableSortBy: true,
        cell: (props) => {
          const value = props.getValue();

          const info = {
            active: { label: "Avaliação ativa", variant: "success" },
            scheduled: { label: "Avaliação agendada", variant: "info" },
            draft: { label: "Avaliação em rascunho", variant: "error" },
            finished: {
              label: "Avaliação encerrada",
              variant: "gray",
            },
            in_calibration: {
              label: "Em calibração",
              variant: "gray",
            },
            calibration_finished: {
              label: "Calibração encerrada",
              variant: "gray",
            },
          };

          return (
            <div style={{ display: "flex", gap: "8px" }}>
              <Tag variant={info?.[value]?.variant || "gray"}>
                <Dot variant={info?.[value]?.variant} />
                <Typography
                  variant="caption"
                  style={{
                    fontWeight: 600,
                    width: "max-content",
                  }}
                >
                  {info?.[value]?.label || " - "}
                </Typography>
              </Tag>
            </div>
          );
        },
      },
      // {
      //   Header: "Andamento das respostas",
      //   accessor: "progress",
      //   Cell: ({ row }) => (
      //     <div style={{ display: "flex", gap: "8px", width: "max-content" }}>
      //       <ProgressArea>
      //         <ProgressWrapper>
      //           <ProgressBar totalDone={row?.original?.progress}>
      //             <span />
      //           </ProgressBar>
      //         </ProgressWrapper>
      //         <div>{row?.original?.progress}%</div>
      //       </ProgressArea>
      //     </div>
      //   ),
      // },
      {
        header: "Tipo de avaliação",
        accessorKey: "types",
        disableSortBy: true,
        cell: (props) => {
          const value = props.getValue();

          return (
            <div style={{ display: "flex", gap: "8px" }}>
              {(value || []).map((v, index) => {
                const labels = {
                  byLeader: "Líder",
                  byLed: "Liderado",
                  self: "Autoavaliação",
                };

                return (
                  <Tag key={index} variant="gray" style={{ flexShrink: 0 }}>
                    <Typography variant="caption" style={{ fontWeight: 600 }}>
                      {labels[v?.type]}
                    </Typography>
                  </Tag>
                );
              })}
            </div>
          );
        },
      },
      {
        header: "Início",
        disableSortBy: true,
        accessorKey: "initialDate",
        cell: ({ row: { original } }) => {
          const { startDate } = original;
          return (
            <StyledText variant="body3">
              {startDate && dayjs(startDate).isValid()
                ? dayjs(startDate).format("DD/MM/YYYY")
                : " - "}
            </StyledText>
          );
        },
      },
      {
        header: "Encerramento",
        disableSortBy: true,
        accessorKey: "finalDate",
        cell: ({ row: { original } }) => {
          const { endDate } = original;
          return (
            <StyledText variant="body3">
              {endDate && dayjs(endDate).isValid()
                ? dayjs(endDate).format("DD/MM/YYYY")
                : " - "}
            </StyledText>
          );
        },
      },
      {
        header: "Criado em",
        disableSortBy: true,
        accessorKey: "createdAt",
        cell: ({ row: { original } }) => {
          const { createdAt } = original;
          return (
            <StyledText variant="body3">
              {createdAt && dayjs(createdAt).isValid()
                ? dayjs(createdAt).format("DD/MM/YYYY")
                : " - "}
            </StyledText>
          );
        },
      },
      {
        header: "Ações",
        accessorKey: "action",
        sticky: "right",
        cell: ({ row }: any) => {
          const status = row.original.evaluationStatus;
          const types = (row.original.types || []).map((t) => t.type);

          const hasLeadType = types.includes("byLeader");

          const inCalibration = status === "in_calibration" && hasLeadType;

          const daysToExpire = dayjs(row?.original?.endDate).diff(
            dayjs(),
            "day"
          );

          return (
            <div
              style={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {inCalibration && (
                <Button
                  size="small"
                  variant="primary"
                  children="Calibrar"
                  style={{ marginTop: "2.5px" }}
                  onClick={() => {
                    const evaluationId = row?.original?._id;

                    navigate({
                      pathname:
                        routes.PageManagerEvaluationDetails(evaluationId),
                      search: createSearchParams({
                        tab: "results",
                      }).toString(),
                    });
                  }}
                />
              )}

              <EvaluationOptions
                status={status}
                evaluationId={row?.original?._id || ""}
                daysToExpire={daysToExpire}
              />
            </div>
          );
        },
      },
    ],
    [data]
  );

  const { totalCount } = pagination;

  if (!loading && !isFiltered && totalCount === 0) {
    return (
      <div style={{ marginBottom: "40px" }}>
        <EmptyState
          buttonText={
            <>
              Criar nova avaliação <Icons name="IconPlus" />
            </>
          }
          description={"Você ainda não criou avaliações"}
          buttonProps={{
            size: "medium",
            onClick: onCreateClick,
          }}
        />
      </div>
    );
  }

  return (
    <DataGrid
      data={data}
      columns={columns}
      loading={loading}
      pagination={pagination}
      empty={{
        isFiltered: true,
        message: loading ? "" : "Nenhuma avaliação foi encontrada!",
      }}
      onPaginationChanged={onPaginationChanged}
    />
  );
};
