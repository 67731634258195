import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Modal } from "@components/Modal";

import styled, { css } from "styled-components";

const StyledModal = styled(Modal)`
  && {
    .modal-container {
      max-width: 480px !important;
      max-height: 95vh;
      border-radius: 16px;
    }

    .modal-content-area {
      padding: 24px 40px;
    }
  }
`;

const StyledIconTitle = styled(Typography)<{
  color?: string;
}>`
  ${({ color, theme }) =>
    color &&
    css`
      color: ${() => {
        switch (color) {
          case "info":
          case "success":
          case "error":
          case "progress":
          case "negative":
            return theme.colors.feedback[color][40];
          case "neutral":
            return theme.colors.neutral[40];
          case "disabled":
            return theme.colors.neutral[70];
          case "transparent":
            return "transparent";
          default:
            return theme.colors.secondary[50];
        }
      }};
    `};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: center;
  margin-bottom: 12px;
  gap: 8px;
`;

const StyledHeader = styled.div`
  align-self: flex-end;
  margin-right: 12px;
  margin-top: 12px;
  margin-bottom: -32px;
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.feedback.error[40]} !important;
  background: ${({ theme }) => theme.colors.feedback.error[90]} !important;
`;

const Survey = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export {
  StyledModal,
  ContentContainer,
  IconContainer,
  StyledIconTitle,
  StyledButton,
  StyledHeader,
  Survey,
};
