import styled from "styled-components";

export const Box = styled.div`
  font-family: "Cabinet Grotesk";
  width: 72px;
  height: 72px;
  border: 2px solid ${({ theme }) => theme.colors.neutral[80]};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const Line = styled.div`
  display: flex;
  gap: 7px;
  margin-bottom: 7px;
`;

export const MatrixArea = styled.div`
  margin-left: 15px;
  margin-top: -21px;
`;
