export const avatarMaker = (name: string) => {
  const matches = name.split(" ");

  if (matches.length > 1) {
    const firstName = matches.shift();

    const firstLetter = firstName?.slice(0, 1);
    const secondLetter = matches?.pop()?.slice(0, 1);

    return `${firstLetter}${secondLetter}`.toLocaleUpperCase();
  }

  return matches?.pop()?.slice(0, 2).toLocaleUpperCase();
};
