export const selectAllTracks = {
  Cargo:
    "people_strategic_hr_performance_company_evaluations_evaluationdetails_results_graphics_filters_position_selectall_selected",
  Departamento:
    "people_strategic_hr_performance_company_evaluations_evaluationdetails_results_graphics_filters_department_selectall_selected",
  Líder:
    "people_strategic_hr_performance_company_evaluations_evaluationdetails_results_graphics_filters_leader_selectall_selected",
};

export const selectTracks = {
  Líder:
    "people_strategic_hr_performance_company_evaluations_evaluationdetails_results_graphics_filters_leader_selected",
  Departamento:
    "people_strategic_hr_performance_company_evaluations_evaluationdetails_results_graphics_filters_department_selected",
  Cargo:
    "people_strategic_hr_performance_company_evaluations_evaluationdetails_results_graphics_filters_position_selected",
};
