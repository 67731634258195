import { ReactElement } from "react";

import { Button } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledIcon, StyledText, StyledTitle } from "@utils";

import { EmptyStateImg, MainContainer, Span } from "./styled";

interface ConditionalChildren {
  condition: boolean;
  element: string | ReactElement;
}

interface IEmptyStateProps {
  hasCalibration: boolean;
  hasByLeader: boolean;
  isSectionsAvailable: boolean;
  isCreatedBefore: boolean;
  calibrationHasStarted: boolean;
  showEditButton: boolean;
}

const renderConditionalChildren = (elements: ConditionalChildren[]) => {
  const foundChildren = elements.find(({ condition }) => condition);

  return foundChildren ? foundChildren.element : "";
};

export const EmptyState = ({
  hasCalibration,
  hasByLeader,
  isSectionsAvailable,
  showEditButton,
  isCreatedBefore,
}: IEmptyStateProps) => {
  const conditionalTitles = [
    {
      condition: !hasCalibration,
      element: "Matriz indisponível para essa avaliação",
    },
    {
      condition: !hasByLeader || !isSectionsAvailable || isCreatedBefore,
      element: "Matriz indisponível para essa avaliação",
    },
  ];

  const conditionalTexts = [
    {
      condition: !hasCalibration,
      element: (
        <>
          Somente avaliações com <Span>etapa de calibração no ciclo</Span> têm
          acesso à matriz.
        </>
      ),
    },
    {
      condition: !hasByLeader,
      element: (
        <>
          Somente avaliações com a modalidade{" "}
          <Span>modalidade “Líder avalia liderado”</Span> têm acesso à matriz,
          uma vez que apenas a nota do líder permite gerar matriz de calibração.
        </>
      ),
    },
    {
      condition: !isSectionsAvailable,
      element: (
        <>
          Somente avaliações com <Span>duas seções</Span> têm acesso à matriz,
          uma vez que ela necessita de <Span>dois eixos</Span>,{" "}
          <Span>formados pelas seções da avaliação</Span>.
        </>
      ),
    },
    {
      condition: isCreatedBefore,
      element: (
        <>
          A matriz de calibração <Span>não pôde ser gerada</Span> porque a
          avaliação foi <Span>configurada antes</Span> da disponibilização da
          ferramenta de <Span>matriz de calibração.</Span>
        </>
      ),
    },
  ];

  return (
    <MainContainer>
      <EmptyStateImg />

      <StyledTitle
        variant="headline6"
        children={renderConditionalChildren(conditionalTitles)}
      />
      <>
        <StyledText
          variant="body3"
          style={{ textAlign: "center", maxWidth: 600 }}
          children={renderConditionalChildren(conditionalTexts)}
        />

        {showEditButton && (
          <Button
            variant="secondary"
            style={{ alignSelf: "center", marginTop: "16px" }}
            children={
              <>
                Editar Avaliação
                <StyledIcon name="IconPencil" setColor="secondary50" />
              </>
            }
          />
        )}
      </>
    </MainContainer>
  );
};
