import styled from "styled-components";

const Container = styled.tr`
  display: table-caption;
  background-color: ${({ theme }) => theme.colors.neutral[95]};
`;

const ItemContainer = styled.th`
  padding: 24px;
`;

export { Container, ItemContainer };
