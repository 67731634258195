import styled from "styled-components";

import { Drawer } from "@mui/material";

export const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 100%;
    max-width: 690px;
  }
`;

export const Body = styled.div`
  padding: 39px 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
