import { useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTheme } from "styled-components";

import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { PageTemplate } from "@components/PageTemplate";

import {
  EvaluationWithSaveModal,
  FinishEvaluationModal,
  UnfinishEvaluationModal,
} from "@components/Modals";
import { track } from "@utils";

import { EvaluationDetails } from "./components/EvaluationDetails";
import { StepRenderer } from "./components/StepRenderer";

import { routes } from "@routes";

import { useEvaluation } from "./hooks";

import { StyledContainer } from "./styled";

export const AnswerEvaluation = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { participantId = "", type = "", cycleId = "" } = useParams();

  const [searchParams] = useSearchParams();

  const theme = useTheme();
  const navigate = useNavigate();

  const step = useMemo(
    () => searchParams.get("step") || undefined,
    [searchParams]
  );

  const [finishEvaluationModal, setFinishEvaluationModal] = useState(false);
  const [unfinishEvaluationModal, setUnfinishEvaluationModal] = useState(false);
  const [exitAndSaveModal, setExitAndSaveModal] = useState(false);

  const routesBread = [
    {
      label: "Ciclo",
      route: routes.PageMyCycles(cycleId),
    },
    {
      label: "Responder avaliação",
    },
  ];

  const {
    isFinishing,
    isFetching,
    errorFetching,
    errorCriterial,
    progress,
    answerLoading,
    employeeEvaluation,
    reviewData,
    activeStep,
    totalSteps,
    currentStep,
    answerAverage,
    answeredTotal,
    answeredQuestions,
    isFilled,
    answerEvaluation,
    setAnswerMultipleMutate,
    finishEmployeeEvaluationMutate,
    checkForCriterialError,
  } = useEvaluation({
    participantId,
    step,
    type: type as any,
  });

  return (
    <PageTemplate
      containerStyle={{
        backgroundColor: theme.colors.neutral95,
      }}
      contentStyle={{
        maxWidth: "870px",
        gap: theme.spacings.m,
        padding: `${theme.spacings.m} ${theme.spacings.xs}`,
      }}
      routes={routesBread}
      progressPercentage={progress}
      followUpProgress={{
        questionsLabel: "Critérios respondidos",
        evaluationType: employeeEvaluation?.type,
        loading: isFetching,
        progressButton: {
          disabled: false,
          loading: answerLoading,
          hasChanges: !!answeredQuestions.length,
          onClick: () => {
            if (!employeeEvaluation) return;

            setAnswerMultipleMutate({
              evaluationId: employeeEvaluation?._id,
              params: {
                type: employeeEvaluation.type,
                evaluatedId: employeeEvaluation.evaluated._id,
                answers: answeredQuestions || [],
              },
            });
          },
        },
        steps: {
          total: totalSteps || 0,
          actualStep: type === "review" ? totalSteps : activeStep || 0,
        },
        questions: {
          answered: answeredTotal || 0,
          total: employeeEvaluation?.criteriaTotal || 0,
          currentGrade: answerAverage,
        },
      }}
      footer={{
        draftProps: {
          title: "Sair e salvar rascunho",
          callback: () => {
            setExitAndSaveModal(true);
          },
        },
        goBackProps: {
          disabled: isFetching || errorFetching,
          title: (
            <>
              <Icons name="IconArrowLeft" fill="transparent" />
              Voltar
            </>
          ),
          hasToShow: type === "review" || Boolean(activeStep && activeStep > 1),
          callback: () => {
            containerRef?.current?.scrollIntoView();

            const previousStep =
              type === "review" ? totalSteps : (activeStep || 2) - 1;

            navigate(
              routes.PageViewEvaluation(
                cycleId,
                participantId,
                "evaluation",
                previousStep
              )
            );
          },
        },
        confirmProps: {
          loading: isFinishing,
          disabled: isFetching || errorFetching,
          title: (
            <>
              {type === "review" ? "Enviar avaliação" : "Continuar"}
              <Icons name="IconArrowRight" fill="transparent" />
            </>
          ),
          callback: () => {
            if (type === "review") {
              if (!isFilled) {
                setUnfinishEvaluationModal(true);

                return;
              }

              setFinishEvaluationModal(true);

              return;
            }

            if (!activeStep) return;

            containerRef?.current?.scrollIntoView();

            if (activeStep === totalSteps) {
              navigate(
                routes.PageViewEvaluation(cycleId, participantId, "review")
              );

              return;
            }

            const nextStep = activeStep + 1;

            navigate(
              routes.PageViewEvaluation(
                cycleId,
                participantId,
                "evaluation",
                nextStep
              )
            );
          },
        },
      }}
    >
      <StyledContainer ref={containerRef}>
        <EvaluationDetails
          isLoading={isFetching}
          evaluation={employeeEvaluation}
        />

        <StepRenderer
          type={type as any}
          scale={employeeEvaluation?.scale || []}
          currentStep={currentStep}
          reviewData={reviewData}
          answerEvaluation={answerEvaluation}
          errorCriterial={errorCriterial}
          isLoading={isFetching}
        />
      </StyledContainer>

      <FinishEvaluationModal
        open={finishEvaluationModal}
        onClose={() => setFinishEvaluationModal(false)}
        onConfirm={async () => {
          if (!employeeEvaluation) return;
          track({
            name: "people_strategic_hr_performance_employee_cycles_evaluations_answerevaluation_sendevaluation_clicked",
          });

          if (answeredQuestions.length) {
            setAnswerMultipleMutate({
              evaluationId: employeeEvaluation?._id,
              finishEvaluation: true,
              params: {
                type: employeeEvaluation.type,
                evaluatedId: employeeEvaluation.evaluated._id,
                answers: answeredQuestions || [],
              },
            });

            return;
          }

          finishEmployeeEvaluationMutate({ participantId });
          return;
        }}
        isLoading={isFinishing}
        disabled={answerLoading}
      />

      <UnfinishEvaluationModal
        open={unfinishEvaluationModal}
        onClose={() => setUnfinishEvaluationModal(false)}
        onConfirm={() => {
          checkForCriterialError();
          setUnfinishEvaluationModal(false);
        }}
      />

      <EvaluationWithSaveModal
        open={exitAndSaveModal}
        isLoading={answerLoading}
        onConfirm={() => {
          if (!employeeEvaluation) return;

          if (answeredQuestions?.length) {
            setAnswerMultipleMutate(
              {
                evaluationId: employeeEvaluation?._id,
                params: {
                  type: employeeEvaluation.type,
                  evaluatedId: employeeEvaluation.evaluated._id,
                  answers: answeredQuestions || [],
                },
              },
              {
                onSuccess: () => {
                  navigate(routes.PageMyCycles(cycleId));
                  setExitAndSaveModal(false);
                },
              }
            );
            return;
          }

          navigate(routes.PageMyCycles(cycleId));
          setExitAndSaveModal(false);
        }}
        onClose={() => setExitAndSaveModal(false)}
      />
    </PageTemplate>
  );
};
