import { Button, PillButton } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledIcon, StyledText, StyledTitle } from "@utils";

import {
  Body,
  Header,
  MainContainer,
  StyledDrawer,
  FlexColumn,
  Card,
  RightColumn,
  KnowMoreImg,
  LeftColumn,
  ContentContainer,
} from "./styled";

interface IAboutCalibrationMatrixDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AboutCalibrationMatrixDrawer = ({
  isOpen,
  onClose,
}: IAboutCalibrationMatrixDrawerProps) => {
  return (
    <StyledDrawer anchor="right" open={isOpen} onClose={onClose}>
      <MainContainer>
        <Header>
          <FlexColumn>
            <StyledTitle
              variant="headline9"
              children="Sobre"
              setColor="neutral30"
            />

            <StyledTitle
              variant="headline7"
              children="Matriz de calibração"
              setColor="neutral10"
            />
          </FlexColumn>

          <div style={{ position: "absolute", top: "25px", right: "40px" }}>
            <PillButton
              variant="default"
              size="small"
              icon="IconX"
              type="secondary"
              onClick={() => onClose()}
            />
          </div>
        </Header>

        <Body>
          <ContentContainer>
            <StyledTitle
              variant="headline9"
              setColor="neutral30"
              weight={700}
              children="O que é a matriz de calibração?"
            />
            <StyledText
              variant="body4"
              setColor="neutral30"
              weight={400}
              children="A matriz de calibração, conhecida no mercado como matriz nine box, é um recurso complementar à avaliação de desempenho que permite que os RHs e gestores visualizem de forma simples e objetiva o nível de desempenho e comportamento dos colaboradores, de acordo com as expectativas da empresa."
            />
          </ContentContainer>
          <ContentContainer>
            <StyledTitle
              variant="headline9"
              setColor="neutral30"
              weight={700}
              children="O RH e gestores usam a matriz nine box para:"
            />
            <ul style={{ paddingLeft: "8px" }}>
              <li>
                <StyledText
                  tag={"span"}
                  variant="body4"
                  setColor="neutral30"
                  weight={400}
                  children="Tomar decisões de reconhecimento, promoções e movimentações internas com base em dados"
                />
              </li>
              <li>
                <StyledText
                  tag={"span"}
                  variant="body4"
                  setColor="neutral30"
                  weight={400}
                  children="Identificar talentos e mapear as lacunas de competências dentro das equipes"
                />
              </li>
              <li>
                <StyledText
                  tag={"span"}
                  variant="body4"
                  setColor="neutral30"
                  weight={400}
                  children="Criar planos de desenvolvimento individualizados e planos de carreira"
                />
              </li>
              <li>
                <StyledText
                  tag={"span"}
                  variant="body4"
                  setColor="neutral30"
                  weight={400}
                  children="Alocar recursos de treinamento e desenvolvimento de forma eficiente"
                />
              </li>
              <li>
                <StyledText
                  tag={"span"}
                  variant="body4"
                  setColor="neutral30"
                  weight={400}
                  children="Promover uma cultura de feedback e reconhecimento aumentando a retenção de talentos"
                />
              </li>
            </ul>
          </ContentContainer>
          <ContentContainer>
            <StyledTitle
              variant="headline9"
              setColor="neutral30"
              children="Como funciona?"
            />

            <StyledText
              variant="body4"
              setColor="neutral30"
              children="A matriz de calibração normalmente é composta por nove quadrantes, podendo variar conforme a necessidade da empresa. Esses quadrantes são formados pela interseção de duas variáveis: o eixo horizontal (X), que representa o desempenho técnico do colaborador, e o eixo vertical (Y), que reflete seu desenvolvimento em aspectos culturais e comportamentais."
            />

            <StyledText
              variant="body4"
              setColor="neutral30"
              children="O desempenho, representado no eixo X, avalia critérios objetivos, como entregas mensuráveis e resultados alcançados, medindo a capacidade do colaborador de cumprir suas responsabilidades."
            />
            <StyledText
              variant="body4"
              setColor="neutral30"
              children="O comportamento, posicionado no eixo Y, analisa a adaptação do colaborador à cultura organizacional, além de seu comportamento e soft skills, que são essenciais para o desenvolvimento."
            />
            <StyledText
              variant="body4"
              setColor="neutral30"
              children="A matriz oferece uma representação visual, permitindo que os RHs e gestores identifiquem o posicionamento de cada colaborador dentro dela e, assim, possam realizar as melhores estratégias para desenvolver e/ou reter talentos."
            />
          </ContentContainer>
          <Card>
            <LeftColumn>
              <KnowMoreImg />
            </LeftColumn>

            <RightColumn>
              <StyledTitle
                variant="headline7"
                setColor="neutral20"
                children="Saiba mais sobre o poder da matriz"
              />

              <StyledText
                variant="body4"
                setColor="neutral50"
                children="Preparamos uma matéria especial para que você entenda a fundo como a matriz de calibração (nine box) pode ajudar sua empresa a tomar decisões mais estratégicas em desenvolvimento, promoções e movimentações internas."
              />

              <Button
                variant="secondary"
                style={{ width: "100%" }}
                onClick={() =>
                  window.open(
                    "https://flashapp.com.br/blog/treinamento-e-desenvolvimento/nine-box"
                  )
                }
              >
                Saiba mais
                <StyledIcon name="IconArrowRight" />
              </Button>
            </RightColumn>
          </Card>
        </Body>
      </MainContainer>
    </StyledDrawer>
  );
};
