import { BigNumbers } from "./BigNumbers";
import { TableGrid } from "./TableGrid";

import { trpc } from "@api/client";

import { Table } from "@components/Table";

import { dispatchToast } from "@utils";

interface IAnalysisTabProps {
  evaluatedId: string;
  evaluationId: string;
  evaluationNotExists?: boolean;
}

export const AnalysisTab = ({
  evaluatedId,
  evaluationId,
  evaluationNotExists,
}: IAnalysisTabProps) => {
  const { data: gradeAnalysis, isFetching } =
    trpc.performance.evaluation.getEvaluationGradesAnalysis.useQuery(
      {
        evaluationId: evaluationId || "",
        employeeId: evaluatedId,
      },
      {
        onError: () =>
          dispatchToast({
            type: "error",
            content:
              "Erro ao carregar a lista de avaliações. Tente novamente em breve.",
          }),
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: !!evaluatedId && !!evaluationId,
      }
    );

  return (
    <>
      {gradeAnalysis?.insights && (
        <BigNumbers
          data={gradeAnalysis?.insights}
          types={gradeAnalysis?.evaluationTypes}
        />
      )}

      <Table
        loading={isFetching}
        data={gradeAnalysis?.sectionsGrades || []}
        tableGridRender={(props) => (
          <TableGrid
            {...props}
            types={gradeAnalysis?.evaluationTypes}
            evaluationNotExists={evaluationNotExists}
          />
        )}
      />
    </>
  );
};
