import styled from "styled-components";

import { Avatar } from "@mui/material";

export const CardContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral[95]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.neutral[90]};
  }
`;

export const StyledAvatar = styled(Avatar)`
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.primary};
  width: 40px;
  height: 40px;
`;

export const LeftColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
`;

export const QuadrantContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const IconContainer = styled.div``;
