import { useCallback, useMemo } from "react";
import {
  useParams,
  useLocation,
  Navigate,
  useNavigate,
  createSearchParams,
} from "react-router-dom";

import { dispatchToast } from "@utils";
import { trpc } from "@api/client";
import { routes } from "@routes";

import {
  BasicInfoStep,
  ModelStep,
  EvaluatedsStep,
  EvaluationFormStep,
  ReviewStep,
  CalibrationMatrixStep,
  ConfigurationStep,
} from "./Steps";

type StepType =
  | typeof BasicInfoStep
  | typeof ModelStep
  | typeof EvaluatedsStep
  | typeof EvaluationFormStep
  | typeof ReviewStep
  | typeof CalibrationMatrixStep
  | typeof ConfigurationStep
  | undefined;

export const PageCreateEvaluation = () => {
  const { pathname } = useLocation();
  const { step = "", _id = "" } = useParams();

  const utils = trpc.useContext();

  const navigate = useNavigate();

  const isEdit = useMemo(() => !!pathname.match("/edit"), [pathname]);

  const navigateByType = useCallback(
    (step: string, evaluationId?: string) => {
      if (step === "manageEvaluations") {
        navigate(routes.PageManageEvaluations);
        return;
      }

      if (step === "calibrationMatrix") {
        navigate({
          pathname: routes.PageManagerEvaluationDetails(evaluationId || ""),
          search: createSearchParams({
            tab: "results",
            resultsTab: "matrix",
          }).toString(),
        });

        return;
      }

      if (isEdit) {
        navigate(routes.PageEditEvaluation(step, evaluationId || ""));
        return;
      }

      navigate(routes.PageCreateEvaluation(step, evaluationId));
    },
    [isEdit]
  );

  const steps = useMemo(
    () => [
      "Modelo",
      "Informações básicas",
      "Configurações",
      "Avaliados",
      "Formulário da avaliação",
      "Matriz de calibração",
      "Revisão",
    ],
    []
  );

  const breadcrumbs = useMemo(
    () => [
      {
        label: "Avaliações",
        route: routes.PageManageEvaluations,
      },
      {
        label: `${isEdit ? "Editar" : "Criar"} avaliação`,
        route: "",
      },
    ],
    [isEdit]
  );

  const Step: StepType = useMemo(() => {
    const steps = {
      ["models"]: ModelStep,
      ["basic-info"]: BasicInfoStep,
      ["configurations"]: ConfigurationStep,
      ["evaluateds"]: EvaluatedsStep,
      ["form"]: EvaluationFormStep,
      ["matrix"]: CalibrationMatrixStep,
      ["review"]: ReviewStep,
    };
    return steps[step] || undefined;
  }, [step]);

  const {
    data: evaluation,
    refetch,
    isFetching,
    error: getError,
  } = trpc.performance.evaluation.getEvaluationById.useQuery(
    { _id: _id },
    {
      onError: (error: any) =>
        dispatchToast({
          type: "error",
          content:
            error?.data?.error === "EVALUATION_NOT_EXISTS_ERROR"
              ? "Erro, avaliação selecionada não foi encontrada."
              : "Não foi possível buscar as informações da sua avaliação. Tente novamente em breve.",
        }),
      enabled: !!_id,
    }
  );

  const { mutate: updateEvaluation, isLoading: isUpdating } =
    trpc.performance.evaluation.updateEvaluation.useMutation({
      onSuccess: (_, variables) => {
        utils.performance.evaluation.getEvaluationDetailsMatrix.invalidate();
        utils.performance.evaluation.getAllEvaluationsPaginated.invalidate();
        utils.performance.evaluation.getEvaluationsByEvaluationCycle.invalidate();
        refetch();

        if (variables.nextStep) return navigateByType(variables.nextStep, _id);

        navigateByType("manageEvaluations");
      },
      onError: (e: any) => {
        const messageSelect = {
          EVALUATION_EXISTS_ERROR:
            "Nome público da avaliação já foi utilizado em outra avaliação. Por favor, tente outro.",
          EVALUATION_NOT_EXISTS_ERROR:
            "Erro ao atualizar a avaliação. avaliação não encontrada!.",
          CYCLE_STEP_NOT_AVAILABLE_ERROR:
            "O ciclo desta avaliação está com o prazo expirado. Para continuar, é necessário atualizar a data de encerramento das etapas do ciclo ou selecionar outro ciclo.",
          CYCLE_NOT_EXISTS_ERROR:
            "O ciclo selecionado não pode ser encontrado. Por favor, selecione outro ciclo.",
          Default: "Erro ao atualizar a avaliação. Tenve novamente em breve",
        };

        dispatchToast({
          type: "error",
          content: messageSelect[e?.data?.error] || messageSelect["Default"],
        });
      },
    });

  const disabledEdit = useMemo(() => {
    if (!evaluation?.evaluationStatus)
      return { partial: !!_id, all: !!_id, matrix: !!_id };

    const partial = evaluation?.evaluationStatus === "active";

    const all = ["finished", "in_calibration", "calibration_finished"].includes(
      evaluation?.evaluationStatus
    );

    const matrix = ["calibration_finished"].includes(
      evaluation?.evaluationStatus
    );

    return { partial, all, matrix };
  }, [evaluation]);

  const error = !["models", "basic-info"].includes(step) && !_id;

  if (!Step || error) return <Navigate to={routes.PageManageEvaluations} />;

  return (
    <Step
      steps={steps}
      breadcrumbs={breadcrumbs}
      evaluationId={_id}
      isEdit={isEdit}
      isLoading={isFetching}
      isUpdating={isUpdating}
      error={!!getError}
      evaluation={evaluation}
      disabledEdit={disabledEdit}
      onSubmit={(updateData, nextStep) => {
        if (disabledEdit?.all) return navigateByType(nextStep, _id);

        updateEvaluation({
          nextStep,
          evaluationId: _id,
          params: updateData,
        });
      }}
      onSubmitCustom={(updateData, nextStep, type) => {
        if (type !== "matrix") return navigateByType(nextStep, _id);

        if (disabledEdit.matrix) return navigateByType(nextStep, _id);

        updateEvaluation({
          nextStep,
          evaluationId: _id,
          params: updateData,
        });
      }}
      onNavigateByStep={(step, evaluationId) =>
        navigateByType(step, evaluationId || _id)
      }
      refetch={refetch}
    />
  );
};
