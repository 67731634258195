import { trpc } from "@api/client";

import { dispatchToast } from "@utils";

import { OnSubmitCalibrationType } from "./types";

interface IUseCalibrationProps {
  isOpen: boolean;
  employeeId?: string;
  evaluationId?: string;
  onClose: () => void;
  onSubmit?: OnSubmitCalibrationType;
}

const useCalibration = ({
  isOpen,
  employeeId = "",
  evaluationId = "",
  onClose,
  onSubmit,
}: IUseCalibrationProps) => {
  const utils = trpc.useContext();

  const {
    data: calibrationSections,
    isFetching,
    refetch,
  } = trpc.performance.evaluation.getCalibrationSections.useQuery(
    { evaluatedId: employeeId, evaluationId: evaluationId },
    {
      onError: () => {
        dispatchToast({
          content: "Erro ao buscar as notas. Tente novamente em breve.",
          type: "error",
        });
      },
      enabled: !!isOpen && !!evaluationId && !!employeeId,
    }
  );

  const { data: employeeData, isFetching: loadingEmployeeData } =
    trpc.employee.getEmployeeDataByEvaluation.useQuery(
      {
        evaluationId: evaluationId,
        employeeId: employeeId,
      },
      {
        onError: () =>
          dispatchToast({
            content:
              "Erro ao buscar dados do avaliado. Tente novamente em breve.",
            type: "error",
          }),
        enabled: !!isOpen && !!evaluationId && !!employeeId,
      }
    );

  const { mutate: calibrateEvaluation, isLoading: isCalibrating } =
    trpc.performance.evaluation.calibrateEvaluationV2.useMutation({
      onSuccess: (result) => {
        dispatchToast({
          content: "Notas calibradas com sucesso.",
          type: "success",
        });

        utils.performance.evaluation.getCalibrationHistory.invalidate();
        utils.performance.evaluation.getEvaluationDetailsMatrix.refetch();
        utils.performance.evaluation.getMatrixQuadrantById.invalidate();

        if (typeof onSubmit === "undefined") {
          utils.performance.evaluation.getEvaluationDetailsResults.refetch();
        }

        onSubmit?.(result);

        setTimeout(() => {
          onClose();
          refetch();
        }, 500);
      },
      onError: (e: any) => {
        const evaluationNotExists =
          e?.data?.error === "EVALUATION_NOT_EXISTS_ERROR";

        const notInCalibrationStatus =
          e?.data?.error === "EVALUATION_NOT_IN_CALIBRATION_ERROR";

        let message = "Erro ao salvar calibração, tente novamente em breve.";

        if (evaluationNotExists)
          message = "Erro ao salvar calibração, avaliação não encontrada!";

        if (notInCalibrationStatus)
          message =
            "Erro ao salvar calibração, avaliação não está em período de calibração!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  return {
    calibrationSections,
    employeeData,
    loadingSections: isFetching,
    loadingEmployeeData: loadingEmployeeData,
    isCalibrating,
    calibrateEvaluation,
  };
};

export { useCalibration };
