import { useState } from "react";

import { Skeleton, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";

import { trpc } from "@api/client";

import { GraphBar } from "@components/Graph";
import { ExportChartInfoModal } from "@components/Modals";
import { EvaluationGraphFiltersDrawer } from "@components/Drawers";

import { dispatchToast, StyledText, StyledTitle, track } from "@utils";

import {
  GraphArea,
  GraphContainer,
  GraphTitle,
  IconArea,
  InfoStar,
  StyledHeader,
  StyledPillButton,
} from "./styled";

import type { Evaluation, FilterParams } from "../../types";

import type { Filter } from "@components/TableFilters/types";

interface IGradeChartProps {
  evaluation: Evaluation;
  filters?: Filter[];
}

export const GradeChart = ({ evaluation, filters = [] }: IGradeChartProps) => {
  const [exportModal, setExportModal] = useState(false);
  const [filtersDrawer, setFiltersDrawer] = useState(false);
  const [filtersParams, setFiltersParams] = useState<FilterParams>();

  const { data, isLoading } =
    trpc.performance.evaluation.getEvaluationGradesChart.useQuery(
      {
        evaluationId: evaluation._id,
        params: filtersParams,
      },
      {
        enabled: !!evaluation?._id,
        onError: () =>
          dispatchToast({
            type: "error",
            content:
              "Erro ao carregar as informações do gráfico de notas. Tente novamente em breve.",
          }),
      }
    );

  const totalEmployees =
    data?.totalEmployees !== undefined ? data.totalEmployees : "-";

  const totalEvaluateds = evaluation?.evaluateds?.length || 0;

  return (
    <GraphContainer>
      <StyledHeader>
        <div>
          <StyledTitle variant="headline8" setColor="neutral30">
            Gráfico de Notas
          </StyledTitle>
          <StyledText variant="body4" setColor="neutral50">
            <InfoStar>*</InfoStar> Apenas a <b>nota do líder</b> (líder avalia
            liderado) é considerada no cálculo da <b>nota final</b>, sendo
            também a <b>única nota contemplada nos gráficos</b> abaixo.
          </StyledText>
        </div>
        <IconArea>
          <Tooltip title={"Filtrar"}>
            <div>
              <StyledPillButton
                size="medium"
                variant="default"
                type="secondary"
                icon="IconFilter"
                onClick={() => {
                  track({
                    name: "people_strategic_hr_performance_company_evaluations_evaluationdetails_results_graphics_gradegraphic_filter_clicked",
                  });
                  setFiltersDrawer(true);
                }}
              />
            </div>
          </Tooltip>
          <Tooltip title={"Exportar gráfico"}>
            <div>
              <StyledPillButton
                size="medium"
                variant="default"
                type="secondary"
                icon="IconDownload"
                onClick={() => {
                  track({
                    name: "people_strategic_hr_performance_company_evaluations_evaluationdetails_results_graphics_gradegraphic_export_clicked",
                  });
                  setExportModal(true);
                }}
              />
            </div>
          </Tooltip>
        </IconArea>
      </StyledHeader>

      {isLoading ? (
        <Skeleton height={400} variant="rounded" />
      ) : (
        <GraphArea>
          <GraphTitle variant="body4" fontWeight={700} setColor="neutral40">
            {totalEmployees}&nbsp;
            <StyledText variant="caption" fontWeight={700} setColor="neutral50">
              /{totalEvaluateds} participante{totalEvaluateds > 1 ? "s" : ""}
            </StyledText>
          </GraphTitle>
          <GraphBar data={data?.chartData || []} />
        </GraphArea>
      )}

      <ExportChartInfoModal
        open={exportModal}
        reportType="grades"
        evaluationId={evaluation._id}
        filters={filtersParams}
        onClose={() => setExportModal(false)}
        onConfirm={() => setExportModal(false)}
      />

      <EvaluationGraphFiltersDrawer
        isOpen={filtersDrawer}
        filters={filters}
        onClose={() => setFiltersDrawer(false)}
        onClear={() => {
          setFiltersDrawer(false);

          setFiltersParams(undefined);
        }}
        onFiltered={(filteredItems) => {
          setFiltersDrawer(false);
          setFiltersParams(filteredItems);
        }}
      />
    </GraphContainer>
  );
};
