import styled from "styled-components";

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledText = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const CellArea = styled.div`
  width: 280px;
  max-width: 280px;
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 270px;
  }
`;

export const CycleCellArea = styled.div`
  width: 200px;
  max-width: 200px;
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 24ch;
  }
`;
