import styled, { css } from "styled-components";
import {
  BoxAdornment,
  Icons,
  Typography,
  Checkbox,
} from "@flash-tecnologia/hros-web-ui-v2";

const TypesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ScalesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CommentariesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
`;

const StyledBoxAdornment = styled(BoxAdornment)<{
  disabled: boolean;
  size?: number;
}>`
  && {
    text-align: left;
    width: 100%;
    ${({ disabled, theme }) =>
      disabled
        ? css`
            border: 1px solid ${theme.colors.neutral[80]};
            color: ${theme.colors.neutral[70]};
            pointer-events: none;
          `
        : ``}

    ${({ size }) =>
      size
        ? css`
            .box-adornment-flex {
              height: ${size}px;
            }
          `
        : ``}
  }
`;

const ScaleTrashIcon = styled(Icons)<{ disabled: boolean }>`
  cursor: pointer;
  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
          cursor: none;
          color: #d1c7ce;
        `
      : ""}
`;

const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  font-weigth: 600;
`;

const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

const StyledCheckBox = styled(Checkbox)<{ error?: boolean }>`
  svg path {
    fill: ${({ theme, disabled, checked }) =>
      disabled && checked
        ? theme.colors.neutral[90]
        : theme.colors.neutral[99]};
    stroke: ${({ theme, disabled, checked }) =>
      disabled && !checked ? theme.colors.neutral[90] : ""};
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  padding: 24px;
  gap: 16px;
`;
const BoldText = styled.b<{ disabled?: boolean }>`
  font-weight: ${({ disabled }) => (disabled ? "400" : "bold")};
`;
export {
  FlexColumn,
  FlexRow,
  TypesContainer,
  ScalesContainer,
  CommentariesContainer,
  MainContainer,
  StyledBoxAdornment,
  ScaleTrashIcon,
  StyledSubtitleContainer,
  StyledDescription,
  StyledCheckBox,
  PreviewContainer,
  BoldText,
};
