import styled from "styled-components";

import { Dot, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 258px;
  border: 1px solid black;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 4px;
`;

const StyledTag = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 4px 12px;
gap: 8px;
background: ${({ theme }) => theme.colors.neutral[90]}};
border-radius: 24px;
user-select: none;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
`;

const EmployeesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  padding: 13px 0px;
  height: 100%;
  gap: 5px;
  overflow: scroll;
  scrollbar-width: none;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-shrink: 0;
  user-select: none;
`;

const BigNumbers = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const FlexContainer = styled.div<{ align: string }>`
  display: flex;
  align-items: ${({ align }) => align};
  gap: 4px;
`;

const StyledAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 39px;
  border-radius: 20px;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  position: relative;
  z-index: 1;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.secondary[90]};
    box-shadow: 1px 1px 2px ${({ theme }) => theme.colors.secondary[95]};
  }
`;

const StatusDot = styled.div`
  display: flex;
  width: 11px;
  height: 11px;
  border-radius: 8px;
  position: absolute;
  right: 3px;
  bottom: 2px;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.secondary[50]};
`;

const StyledDot = styled(Dot)`
  background-color: ${({ theme }) => theme.colors.secondary[50]};
  width: 11px;
  height: 11px;
  border-radius: 6px;
  margin-bottom: 2px;
`;

const PlusAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 39px;

  border-radius: 20px;

  background-color: ${({ theme }) => theme.colors.neutral[95]};
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.neutral[90]};
    box-shadow: 1px 1px 2px ${({ theme }) => theme.colors.neutral[95]};
  }
`;

const StyledSkeleton = styled(Skeleton).attrs(() => ({
  variant: "rounded",
  width: "100%",
}))`
  border-radius: 4px;
`;

export {
  ItemContainer,
  StyledTag,
  Header,
  EmployeesContainer,
  Footer,
  BigNumbers,
  FlexContainer,
  StyledAvatar,
  StatusDot,
  StyledDot,
  PlusAvatar,
  StyledSkeleton,
};
