import { TipCard } from "@components/Cards";

import { TitleRow } from "./styled";

import { StyledIcon, StyledTitle } from "@utils";

interface FlashTipProps {
  onButtonClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const FlashTip = ({ onButtonClick }: FlashTipProps) => {
  return (
    <TipCard
      icon="IconSparkles"
      title={"Dica da Flash!"}
      subTitle={
        <>
          <b>As duas seções</b> do formulário devem ser <b>distribuídas</b>{" "}
          entre os <b>eixos</b> da matriz.
          <p>
            Recomendamos a organização das seções <b>objetivas</b> em um eixo e
            seções <b>subjetivas</b> no outro eixo. Desta forma, um deles poderá
            abordar <b>desempenho e metas</b>, enquanto o outro pode contemplar
            o <b>comportamento</b> do avaliado, incluindo <b>valores</b> e{" "}
            <b>cultura</b>.
          </p>
        </>
      }
      button={{
        title: (
          <TitleRow>
            <StyledIcon
              name="IconInfoCircle"
              setColor="secondary50"
              size={14}
            />
            <StyledTitle
              variant="body4"
              setColor="secondary50"
              children={"Saiba mais sobre matriz de calibração"}
              style={{ fontWeight: 700 }}
            />
          </TitleRow>
        ),
        onClick: onButtonClick,
      }}
    />
  );
};
