import { useEffect, useState } from "react";

import {
  CalibrationDrawerV2,
  QuadrantDrawer,
  CalibrationDetailsDrawer,
} from "../../Drawers";

import type {
  EvaluationGrades,
  EvaluationStatus,
} from "server/src/services/evaluation/types";

interface ICalibrationDrawersFluxProps {
  isOpen: boolean;
  evaluationStatus?: EvaluationStatus;
  selectedDrawer?: "quadrants" | "calibration";
  isCalibrationFinished?: boolean;
  id?: string;
  evaluationId?: string;
  onClose: () => void;
  onSubmitCalibration?: (updatedGrade: EvaluationGrades) => void;
}

export const CalibrationDrawersFlux = ({
  isOpen,
  evaluationStatus,
  isCalibrationFinished,
  selectedDrawer,
  id,
  evaluationId,
  onClose,
  onSubmitCalibration,
}: ICalibrationDrawersFluxProps) => {
  const [calibrationDrawerOpen, setCalibrationDrawerOpen] = useState(false);
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
  const [quadrantDrawerOpen, setQuadrantDrawerOpen] = useState(false);

  const [filteredParticipant, setFilteredParticipant] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (!isOpen) {
      calibrationDrawerOpen && setCalibrationDrawerOpen(false);
      detailsDrawerOpen && setDetailsDrawerOpen(false);
      quadrantDrawerOpen && setQuadrantDrawerOpen(false);

      return;
    }

    if (!selectedDrawer) return;

    if (selectedDrawer === "quadrants") return setQuadrantDrawerOpen(true);

    if (isCalibrationFinished) return setDetailsDrawerOpen(true);

    return setCalibrationDrawerOpen(true);
  }, [isOpen]);

  const onCloseHandler = (type: "calibration" | "quadrant") => {
    if (type === "quadrant") return onClose();

    if (type === "calibration" && !quadrantDrawerOpen) return onClose();

    if (calibrationDrawerOpen) return setCalibrationDrawerOpen(false);

    return setDetailsDrawerOpen(false);
  };

  return (
    <>
      <CalibrationDrawerV2
        evaluationStatus={evaluationStatus}
        isOpen={calibrationDrawerOpen}
        employeeId={filteredParticipant || id}
        evaluationId={evaluationId}
        onClose={() => onCloseHandler("calibration")}
        onSubmit={onSubmitCalibration}
      />

      <QuadrantDrawer
        isOpen={quadrantDrawerOpen}
        quadrantId={id}
        evaluationId={evaluationId}
        onParticipantSelected={(evaluatedId) => {
          setFilteredParticipant(evaluatedId);

          if (!isCalibrationFinished) return setCalibrationDrawerOpen(true);

          setDetailsDrawerOpen(true);
        }}
        onClose={() => {
          onCloseHandler("quadrant");
          filteredParticipant && setFilteredParticipant(undefined);
        }}
      />

      <CalibrationDetailsDrawer
        isOpen={detailsDrawerOpen}
        evaluatedId={filteredParticipant || id}
        evaluationId={evaluationId}
        onClose={() => onCloseHandler("calibration")}
      />
    </>
  );
};
