import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Select } from "@mui/material";
import { EmptyState } from "@assets/index";

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const PaginationArea = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    flex-direction: column;
  }
  margin: 32px 0;
`;

export const ItensPerPageArea = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    justify-content: center;
  }
`;

export const StyledSelectField = styled(Select)`
  margin-right: 16px;

  border-radius: 200px !important;
  padding-right: 0px !important;

  .MuiFilledInput-root {
    padding: 9px 39px 9px 8px;
  }

  &:before,
  &:after {
    content: none !important;
  }

  &:focus {
    background-color: transparent !important;
  }

  .MuiSelect-select {
    padding: 9px 30px 9px 16px !important;
    border-radius: 200px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral[40]};
    &:focus {
      background-color: transparent !important;
    }
  }
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const PaginationContainer = styled.div`
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
`;

export const CellArea = styled.div`
  width: 560px;
  max-width: 560px;

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 550px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  width: 58px;
  height: 48px;

  background-color: #ffff;
  border-radius: 200px;
  gap: 8px;
`;

export const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Option = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  gap: 16px;
  padding: 80px 0px;
`;

export const EmptyStateImg = styled.div`
  width: 160px;
  height: 156px;
  background-image: url(${EmptyState});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
`;
