import { segmentEventTracking } from "@flash-tecnologia/hros-web-utility";

type ObjectLiteral = {
  [key: string]: string;
};

export const track = ({
  name,
  params,
}: {
  name: string;
  params?: ObjectLiteral;
}) => {
  segmentEventTracking({
    name,
    module: "Performance",
    businessUnit: "people",
    params,
  });
};
