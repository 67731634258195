import styled from "styled-components";

import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Timeline from "@mui/lab/Timeline";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const LeftContent = styled(TimelineOppositeContent)`
  padding: 2px 18px 2px 0px;
`;

export const Separator = styled(TimelineSeparator)``;

export const RightContent = styled(TimelineContent)`
  padding: 4px 18px;
`;

export const Item = styled(Timeline)`
  padding: 0px;
`;

export const Connector = styled(TimelineConnector)<{ firstItem?: boolean }>`
  background-color: ${({ theme }) => theme.colors.neutral[90]};
  height: ${({ firstItem }) => firstItem && "8px"};
  flex-grow: ${({ firstItem }) => (firstItem ? "0" : "1")};

  box-shadow: none;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Bullet = styled(TimelineDot)<{
  firstItem: boolean;
}>`
  background: ${({ theme, firstItem }) =>
    firstItem ? "transparent" : theme.colors.neutral[90]};
  border-color: ${({ theme, firstItem }) =>
    !firstItem ? "transparent" : theme.colors.primary};
  box-shadow: none;
  margin: 0px;
  margin-top: ${({ firstItem }) => (firstItem ? "6px" : "0px")};
  padding: 6px;
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const EmptyStateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
