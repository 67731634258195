import { cloneDeep, meanBy } from "lodash-es";

import { CalibrationFormType } from "../../../types";

interface ICalculateAverageProps {
  sections: CalibrationFormType["sections"];
  calibratedSection: {
    _id: string;
    criterialId: string;
    calibrationValue: number | undefined;
  };
}

export const calibrateSections = ({
  sections,
  calibratedSection,
}: ICalculateAverageProps) => {
  const clonedSections = cloneDeep(sections || []);

  const sectionsMapped = clonedSections.map((section) => {
    const isSectionCalibrated = section._id === calibratedSection._id;

    const criterialsMapped = (section.criterials || []).map((criterial) => {
      const calibratedCriterial =
        isSectionCalibrated && calibratedSection.criterialId === criterial._id;

      return {
        ...criterial,
        calibrationValue: calibratedCriterial
          ? calibratedSection.calibrationValue
          : criterial.calibrationValue,
      };
    });

    const hasCriterialCalibration = criterialsMapped.some(
      (c) => c.calibrationValue
    );

    return (section = {
      ...section,
      criterials: criterialsMapped,
      calibrationAverage: hasCriterialCalibration
        ? Number(
            meanBy(
              criterialsMapped,
              (item) => item.calibrationValue || item.value || 0
            ).toFixed(1)
          )
        : undefined,
    });
  });

  const hasCalibrationSection = sectionsMapped.some(
    (s) => s.calibrationAverage
  );

  const calibrationAverage = hasCalibrationSection
    ? meanBy(
        sectionsMapped,
        (item) => item.calibrationAverage || item.average
      ) || 0
    : undefined;

  return {
    calibrationAverage:
      calibrationAverage !== undefined
        ? Number(calibrationAverage.toFixed(1))
        : calibrationAverage,
    sections: sectionsMapped,
  };
};
