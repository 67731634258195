import { useMemo, useState } from "react";
import { FormikProps } from "formik";
import { Grid } from "@mui/material";

import { SelectField } from "@flash-tecnologia/hros-web-ui-v2";

import { AboutCalibrationMatrixDrawer } from "@components/Drawers";

import { Matrix } from "../Matrix";
import { FlashTip } from "./FlashTip";

import { InputArea, StyledTextField } from "./styled";

import { StyledText, track } from "@utils";

import type { CalibrationMatrixFormProps } from "../../../../../types";

import type { EvaluationSection } from "server/src/services/evaluation/types";

interface IMatrixSection {
  formik: FormikProps<CalibrationMatrixFormProps>;
  sections: EvaluationSection[];
  disabledEdit?: boolean;
  disabledPartialEdit?: boolean;
}

export const MatrixSection = ({
  formik,
  sections,
  disabledEdit,
  disabledPartialEdit,
}: IMatrixSection) => {
  const [aboutCalibrationDrawer, setAboutCalibrationDrawer] = useState(false);

  const sectionsMapped = useMemo(
    () => (sections || []).map((s) => ({ label: s.name, value: s._id })),
    [sections]
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} lg={8}>
          <InputArea>
            <div style={{ width: "100%" }}>
              <StyledTextField
                label="Nome do eixo horizontal"
                value={formik.values.horizontalAxisName || ""}
                disabled={disabledPartialEdit}
                inputProps={{ maxLength: 30 }}
                onChange={(e) => {
                  formik.handleChange({
                    target: {
                      id: "horizontalAxisName",
                      value: e.target.value,
                    },
                  });
                }}
                error={
                  formik.touched.horizontalAxisName &&
                  Boolean(formik.errors.horizontalAxisName)
                }
                helperText={
                  formik.touched.horizontalAxisName &&
                  formik.errors.horizontalAxisName
                }
                fullWidth
              />
              <StyledText
                variant="caption"
                children="Máximo 30 caracteres"
                style={{ marginLeft: "18px", fontWeight: 600 }}
                setColor="neutral50"
              />
            </div>
            <div style={{ width: "100%" }}>
              <SelectField
                label="Seção do formulário"
                options={sectionsMapped.filter(
                  (s) => s.value !== formik.values.verticalSectionId
                )}
                value={formik.values.horizontalSectionId}
                disabled={disabledEdit}
                searchable={true}
                onSelectChange={(_, option) => {
                  formik.handleChange({
                    target: {
                      id: "horizontalSectionId",
                      value: option?.value || "",
                    },
                  });

                  if (!option?.value) return;

                  track({
                    name: "people_strategic_hr_performance_company_evaluations_createevaluation_calibrationmatrix_xaxis_section_selected",
                  });
                }}
                error={
                  formik.touched.horizontalSectionId &&
                  Boolean(formik.errors.horizontalSectionId)
                }
                helperText={
                  formik.touched.horizontalSectionId
                    ? formik.errors.horizontalSectionId
                    : "O cálculo da nota final é baseado na média das seções."
                }
                fullWidth
              />
            </div>
          </InputArea>
          <InputArea>
            <div style={{ width: "100%" }}>
              <StyledTextField
                label="Nome do eixo vertical"
                value={formik.values.verticalAxisName}
                disabled={disabledPartialEdit}
                inputProps={{ maxLength: 30 }}
                onChange={(e) => {
                  formik.handleChange({
                    target: {
                      id: "verticalAxisName",
                      value: e.target.value,
                    },
                  });
                }}
                error={
                  formik.touched.verticalAxisName &&
                  Boolean(formik.errors.verticalAxisName)
                }
                helperText={
                  formik.touched.verticalAxisName &&
                  formik.errors.verticalAxisName
                }
                fullWidth
              />

              <StyledText
                variant="caption"
                children="Máximo 30 caracteres"
                style={{ marginLeft: "18px", fontWeight: 600 }}
                setColor="neutral50"
              />
            </div>
            <div style={{ width: "100%" }}>
              <SelectField
                label="Seção do formulário"
                options={sectionsMapped.filter(
                  (s) => s.value !== formik.values.horizontalSectionId
                )}
                value={formik.values.verticalSectionId}
                disabled={disabledEdit}
                searchable={true}
                onSelectChange={(_, option) => {
                  formik.handleChange({
                    target: {
                      id: "verticalSectionId",
                      value: option?.value || "",
                    },
                  });

                  if (!option?.value) return;

                  track({
                    name: "people_strategic_hr_performance_company_evaluations_createevaluation_calibrationmatrix_yaxis_section_selected",
                  });
                }}
                error={
                  formik.touched.verticalSectionId &&
                  Boolean(formik.errors.verticalSectionId)
                }
                helperText={
                  formik.touched.verticalSectionId
                    ? formik.errors.verticalSectionId
                    : "O cálculo da nota final é baseado na média das seções."
                }
                fullWidth
              />
            </div>
          </InputArea>

          <FlashTip onButtonClick={() => setAboutCalibrationDrawer(true)} />
        </Grid>
        <Grid item md={12} lg={4} width={"100%"}>
          <Matrix />
        </Grid>
      </Grid>

      <AboutCalibrationMatrixDrawer
        isOpen={aboutCalibrationDrawer}
        onClose={() => setAboutCalibrationDrawer(false)}
      />
    </>
  );
};
