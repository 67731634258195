import { PillButton, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledIcon, StyledTitle, StyledText } from "@utils";

import {
  AxisContainer,
  AxisContentContainer,
  DescriptionContainer,
  IconContainer,
  TitleContainer,
  TitleActionsContainer,
  MainContainer,
  PillButtonHidden,
  PillButtonContainer,
} from "./styled";

import type { QuadrantType } from "../../types";

interface IHeaderProps {
  quadrant?: QuadrantType;
  loading?: boolean;
  onClose: () => void;
}

export const Header = ({ quadrant, loading, onClose }: IHeaderProps) => {
  return (
    <MainContainer>
      <TitleContainer>
        <TitleActionsContainer>
          <IconContainer onClick={onClose}>
            <StyledIcon name="IconArrowLeft" setColor="neutral40" />
          </IconContainer>

          {loading && <Skeleton variant="text" height="29px" width="100%" />}

          {!loading && (
            <StyledTitle
              variant="headline7"
              setColor="neutral10"
              children={
                <>
                  {quadrant?.name || ""}{" "}
                  <StyledTitle
                    variant="headline9"
                    style={{ marginTop: "4px" }}
                    tag={"span"}
                    children={`(${quadrant?.total || 0} participant${
                      quadrant?.total === 1 ? "e" : "es"
                    })`}
                    setColor="neutral40"
                  />
                </>
              }
            />
          )}
        </TitleActionsContainer>

        <PillButtonContainer>
          <PillButton
            variant="default"
            size="small"
            icon="IconX"
            type="secondary"
            onClick={() => onClose()}
          />
        </PillButtonContainer>

        <PillButtonHidden />
      </TitleContainer>

      <AxisContainer>
        <AxisContentContainer>
          <StyledText
            variant="body4"
            children="Eixo vertical"
            setColor="neutral30"
            weight={600}
          />

          {loading && <Skeleton variant="text" height="22px" width="100%" />}

          {!loading && (
            <StyledText
              variant="body3"
              children={
                !!quadrant?.vertical
                  ? `${quadrant?.vertical?.label} (${quadrant?.vertical?.init} - ${quadrant?.vertical?.end})`
                  : " - "
              }
              setColor="neutral30"
              weight={600}
            />
          )}
        </AxisContentContainer>

        <AxisContentContainer>
          <StyledText
            variant="body4"
            children="Eixo horizontal"
            setColor="neutral30"
            weight={600}
          />

          {loading && <Skeleton variant="text" height="22px" width="100%" />}

          {!loading && (
            <StyledText
              variant="body3"
              children={
                !!quadrant?.horizontal
                  ? `${quadrant?.horizontal.label} (${quadrant?.horizontal?.init} - ${quadrant?.horizontal?.end})`
                  : " - "
              }
              setColor="neutral30"
              weight={600}
            />
          )}
        </AxisContentContainer>
      </AxisContainer>

      <DescriptionContainer>
        <StyledText
          variant="body4"
          children="Contexto/Recomendação"
          setColor="neutral30"
          weight={600}
        />

        {loading && <Skeleton variant="text" height="44px" width="100%" />}

        {!loading && (
          <StyledText
            variant="body3"
            children={quadrant?.description || " - "}
            setColor="neutral30"
            weight={600}
          />
        )}
      </DescriptionContainer>
    </MainContainer>
  );
};
