import { StyledText, avatarMaker, cpfMask } from "@utils";

import { AvatarContainer, NameContainer } from "./styled";

export const RenderNameField = (name: string, documentNumber = "") => (
  <NameContainer>
    <AvatarContainer>{avatarMaker(name)}</AvatarContainer>
    <div>
      <StyledText variant="body3" children={name} setColor="neutral40" />
      {!!documentNumber && (
        <StyledText
          variant="body3"
          children={`CPF: ${cpfMask(documentNumber)}`}
          setColor="neutral50"
        />
      )}
    </div>
  </NameContainer>
);
