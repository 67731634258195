import {
  ShapeIconOptions,
  LinkButtonProps,
  ButtonProps,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { ModalProps as ModalPropsMui } from "@mui/material";

import {
  StyledModal,
  Container,
  IconContainer,
  ContentArea,
  Footer as StyledFooter,
  FooterV2 as StyledFooterV2,
  StyledButton,
} from "./styled";

export type ModalProps = ModalPropsMui & {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  icon?: React.ReactNode;
};

const Modal = ({
  open,
  onClose,
  className,
  children,
  header,
  footer,
  icon,
  style,
  ...rest
}: ModalProps) => {
  return (
    <StyledModal
      className={className}
      open={open}
      onClose={onClose}
      style={style}
      {...rest}
    >
      <Container className="modal-container">
        {icon && (
          <IconContainer className="modal-icon-container">{icon}</IconContainer>
        )}
        {header && <>{header}</>}
        <ContentArea className="modal-content-area">{children}</ContentArea>
        {footer && <>{footer}</>}
      </Container>
    </StyledModal>
  );
};

const Footer = ({ children }: { children: React.ReactNode }) => {
  return <StyledFooter>{children}</StyledFooter>;
};

const FooterV2 = ({ children }: { children: React.ReactNode }) => {
  return <StyledFooterV2>{children}</StyledFooterV2>;
};

const DefaultFooter = ({
  variantType,
  isLoading,
  cancelButton,
  confirmButton,
  secondaryVariant,
}: {
  variantType: ShapeIconOptions;
  isLoading?: boolean;
  secondaryVariant?: ShapeIconOptions;
  cancelButton: {
    title: string;
    variant?: LinkButtonProps["variant"];
    onClick: React.EventHandler<any> | undefined;
    hide?: boolean;
  };
  confirmButton: {
    variant?: ButtonProps["variant"];
    content: string | React.ReactNode;
    onClick: React.EventHandler<any> | undefined;
    disabled?: boolean;
  };
}) => {
  return (
    <StyledFooter disableCancel={!cancelButton?.hide}>
      {!cancelButton?.hide && (
        <LinkButton
          variant={cancelButton?.variant || "neutral"}
          style={{ alignSelf: "center" }}
          children={cancelButton?.title}
          onClick={cancelButton?.onClick}
          disabled={isLoading}
        />
      )}
      <StyledButton
        size="large"
        variant={confirmButton?.variant || "primary"}
        variantType={secondaryVariant || (variantType as any)}
        onClick={confirmButton.onClick}
        loading={isLoading}
        disabled={confirmButton.disabled}
      >
        {confirmButton.content}
      </StyledButton>
    </StyledFooter>
  );
};

const DefaultFooterV2 = ({
  variantType,
  isLoading,
  cancelButton,
  confirmButton,
  secondaryVariant,
  onClose,
  onConfirm,
}: {
  variantType: ShapeIconOptions;
  isLoading?: boolean;
  secondaryVariant?: ShapeIconOptions;
  cancelButton?: {
    title: string;
    variant?: LinkButtonProps["variant"];
  };
  confirmButton?: {
    variant?: ButtonProps["variant"];
    content: string | React.ReactNode;
    disabled?: boolean;
  };
  onClose?: React.EventHandler<any> | undefined;
  onConfirm?: React.EventHandler<any> | undefined;
}) => {
  const centered = !(cancelButton && confirmButton);

  return (
    <StyledFooterV2 centered={centered}>
      {cancelButton && (
        <LinkButton
          variant={cancelButton?.variant || "neutral"}
          style={{ alignSelf: "center" }}
          children={cancelButton?.title}
          onClick={onClose}
          disabled={isLoading}
        />
      )}

      {confirmButton && (
        <StyledButton
          variant={confirmButton?.variant || "primary"}
          variantType={secondaryVariant || (variantType as any)}
          onClick={onConfirm}
          loading={isLoading}
          disabled={confirmButton.disabled}
          children={confirmButton.content}
        />
      )}
    </StyledFooterV2>
  );
};

export { Modal, Footer, FooterV2, DefaultFooter, DefaultFooterV2 };
