import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { flatMap, mapValues, groupBy } from "lodash-es";

import {
  Icons,
  LinkButton,
  PillButton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { EmptyState } from "@assets/index";

import { StyledText, StyledTitle } from "@utils";

import { FilterItem, BodySkeleton } from "./components";

import {
  Body,
  FlexRow,
  Header,
  HeaderTitleContainer,
  MainContainer,
  StyledDrawer,
  HeaderButtons,
  ButtonContainer,
  ContentContainer,
  FilterButton,
  EmptyStateArea,
  EmptyStateDescription,
  EmptyStateImg,
} from "./styled";

import type { FilterType } from "./types";

interface IEvaluationGraphFiltersDrawerProps {
  isOpen: boolean;
  filters?: FilterType[];
  initialValues?: { [key: string]: string[] };
  isLoading?: boolean;
  onClose: () => void;
  onClear: () => void;
  onFiltered: (filters: { [key: string]: string[] }) => void;
}

export const EvaluationGraphFiltersDrawer = ({
  isOpen,
  filters = [],
  initialValues,
  isLoading,
  onClose,
  onClear,
  onFiltered,
}: IEvaluationGraphFiltersDrawerProps) => {
  const navigate = useNavigate();

  const [filterList, setFilterList] = useState<FilterType[]>([]);

  useEffect(() => {
    const mappedFilters = filters.map((filter) => {
      return {
        ...filter,
        options: filter.options.map((option) => {
          const value = initialValues?.[filter.accessor]?.includes(
            option.value
          );

          return {
            ...option,
            checked: option?.checked || !!value,
          };
        }),
      };
    });

    setFilterList(mappedFilters);
  }, [JSON.stringify(filters)]);

  const renderEmptyState = useCallback(
    (filterList: FilterType[]) => {
      const found = filterList?.find((item) => item?.options.length === 0);

      if (found?.label === "Departamento" || found?.label === "Cargo") {
        return (
          <EmptyStateArea>
            <EmptyStateImg src={EmptyState} width={128} height={116} />
            <StyledText variant="headline6" fontWeight={700}>
              Filtros não encontrados.
            </StyledText>
            <EmptyStateDescription variant="body3">
              A disponibilidade dos filtros depende de como a configuração de
              cargos e departamentos estava no momento da criação da avaliação.
            </EmptyStateDescription>
            <LinkButton
              variant="neutral"
              size="large"
              onClick={() => navigate("/settings", { replace: true })}
            >
              Configurações <Icons name="IconExternalLink" size={16} />
            </LinkButton>
          </EmptyStateArea>
        );
      }
    },
    [JSON.stringify(filterList)]
  );

  const onClearHandler = () => {
    setFilterList((old) => {
      return old?.map((item) => ({
        ...item,
        id: item.accessor,
        options: item?.options?.map((option) => ({
          ...option,
          checked: false,
        })),
      }));
    });

    onClear();
  };

  const onFilterHandler = () => {
    const optionsMapped = flatMap(filterList || [], (item) =>
      (item.options || []).map((option) => ({ id: item.accessor, ...option }))
    );

    const checkedOptions = optionsMapped.filter((option) => option.checked);

    const filteredValues = mapValues(groupBy(checkedOptions, "id"), (items) =>
      items.map((item) => item.value)
    );

    onFiltered(filteredValues);
  };

  return (
    <StyledDrawer anchor="right" open={isOpen} onClose={onClose}>
      <MainContainer>
        <Header>
          <FlexRow>
            <HeaderTitleContainer>
              <StyledTitle
                variant="headline6"
                children={"Filtros"}
                setColor="neutral10"
              />
            </HeaderTitleContainer>
            <HeaderButtons>
              <div style={{ marginTop: "-16px" }}>
                <PillButton
                  variant="default"
                  size="small"
                  type="secondary"
                  icon="IconX"
                  onClick={onClose}
                />
              </div>
            </HeaderButtons>
          </FlexRow>
        </Header>

        <Body>
          {isLoading ? (
            <BodySkeleton />
          ) : (
            <ContentContainer>
              {filterList.map((item) => (
                <FilterItem
                  key={item.accessor}
                  setFilterList={setFilterList}
                  {...item}
                />
              ))}
              {renderEmptyState(filterList)}
            </ContentContainer>
          )}
        </Body>

        <ButtonContainer>
          <LinkButton variant="default" onClick={onClearHandler}>
            Redefinir
          </LinkButton>
          <FilterButton
            variant="primary"
            size="large"
            onClick={onFilterHandler}
          >
            Filtrar
          </FilterButton>
        </ButtonContainer>
      </MainContainer>
    </StyledDrawer>
  );
};
