import { Arrow, ArrowArea, ArrowLine, Line, Text } from "./styled";

interface IArrowString {
  label: string;
  position?: "horizontal" | "vertical";
  variant?: "primary" | "secondary";
}

export const ArrowString = ({
  label,
  position = "horizontal",
  variant = "primary",
}: IArrowString) => {
  return (
    <ArrowArea position={position}>
      <Line variant={variant}></Line>
      <Text variant={variant}>{label}</Text>
      <ArrowLine variant={variant}></ArrowLine>
      <Arrow variant={variant}>►</Arrow>
    </ArrowArea>
  );
};
