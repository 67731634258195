import styled from "styled-components";
import { StyledTitle } from "@utils";

export const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled(StyledTitle)`
  margin: 32px 0;
`;

export const CharCountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 16px 0px 16px;
`;
