import styled from "styled-components";

export const ActionsContainer = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
`;
