import { useState, useMemo } from "react";
import { FormikErrors, FormikTouched } from "formik";
import { cloneDeep } from "lodash-es";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";

import { Icons, LinkButton, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { SectionsOptions } from "../SectionsOptions";

import { RenameSectionModal } from "@components/Modals";

import { StyledText, StyledPLine } from "@utils";

import {
  SectionsContainer,
  ItemContainer,
  ItemCounter,
  ItemSection,
} from "./styled";

import type { SectionType } from "../../../types";
import type { onOptionsClickedType } from "../../types";

interface IDraggableSectionsProps {
  sections: SectionType[];
  matrixSectionIds: string[];
  selectedIndex: number;
  errors?: FormikErrors<SectionType>[];
  touched?: FormikTouched<SectionType>[];
  disabledEdit?: boolean;
  hasByLeader: boolean;
  hasCalibration: boolean;
  isLoading?: boolean;
  onSelectItem?: (section: SectionType, index: number) => void;
  onReordered: (criterials: SectionType[], selectedIndex: number) => void;
  onOptionsClicked: onOptionsClickedType;
}

type OnReorderItems = (
  list: SectionType[],
  startIndex: number,
  endIndex: number
) => SectionType[];

const FormSkeleton = () => {
  return (
    <SectionsContainer>
      <Skeleton width="100%" height="42px" variant="rounded" />
      <Skeleton width="100%" height="42px" variant="rounded" />
    </SectionsContainer>
  );
};

export const DraggableSections = ({
  sections = [],
  selectedIndex,
  errors,
  touched,
  disabledEdit,
  isLoading,
  hasByLeader,
  hasCalibration,
  matrixSectionIds,
  onSelectItem,
  onReordered,
  onOptionsClicked,
}: IDraggableSectionsProps) => {
  const [renameModalOpen, setRenameModalOpen] = useState(false);

  const sectionTitles = useMemo(
    () => (sections || []).map((section) => section.name),
    [sections]
  );

  const reorderItems: OnReorderItems = (list, startIndex, endIndex) => {
    const clonedSections = cloneDeep(list);

    const [removed] = clonedSections.splice(startIndex, 1);

    clonedSections.splice(endIndex, 0, removed);

    return clonedSections.map((section, index) => ({
      ...section,
      order: index + 1,
    }));
  };

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const items = reorderItems(sections, sourceIndex, destinationIndex);

    onReordered(items, destinationIndex);
  };

  if (isLoading) return <FormSkeleton />;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="sections">
        {(provided) => (
          <SectionsContainer
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {sections.map((section, index) => {
              const sectionError = !!Object.keys(errors?.[index] || {}).length;
              const sectionTouched = !!Object.keys(touched?.[index] || {})
                .length;

              const hasError = sectionTouched && sectionError;
              const isSelected = index === selectedIndex;

              return (
                <Draggable
                  key={section._id || index}
                  draggableId={section._id || index.toString()}
                  index={index}
                  isDragDisabled={disabledEdit}
                >
                  {(provided, snapshot) => (
                    <ItemContainer
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      isSelected={isSelected}
                      isDragging={snapshot.isDragging}
                      error={hasError}
                      onClick={() => onSelectItem?.(section, index)}
                    >
                      <ItemSection>
                        <Icons
                          name="IconGripVertical"
                          style={{ flexShrink: 0 }}
                        />
                        <StyledPLine numberOfLines={1}>
                          <StyledText
                            variant="body4"
                            fontWeight={600}
                            children={section.name || "Seção sem título"}
                          />
                        </StyledPLine>
                      </ItemSection>
                      <ItemSection>
                        <ItemCounter
                          isSelected={isSelected}
                          error={hasError}
                          isDragging={snapshot.isDragging}
                        >
                          <StyledText
                            variant="caption"
                            fontWeight={600}
                            children={section.criterials.length || 0}
                            style={{ fontSize: "10px", lineHeight: "12px" }}
                          />
                        </ItemCounter>
                        <SectionsOptions
                          type="menu"
                          section={section}
                          sections={sections}
                          selectedSectionIndex={index}
                          sectionsLimit={(sections || []).length <= 1}
                          disabledEdit={disabledEdit}
                          hasByLeader={hasByLeader}
                          hasCalibration={hasCalibration}
                          matrixSectionIds={matrixSectionIds || []}
                          onOptionsClicked={onOptionsClicked}
                        />
                      </ItemSection>
                    </ItemContainer>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </SectionsContainer>
        )}
      </Droppable>

      <LinkButton
        variant="neutral"
        onClick={() => setRenameModalOpen(true)}
        style={{ alignSelf: "center" }}
        disabled={disabledEdit}
        children={
          <>
            <Icons name="IconPlus" size={16} /> Adicionar nova seção
          </>
        }
      />

      <RenameSectionModal
        open={renameModalOpen}
        variant="create"
        sectionTitles={sectionTitles}
        hasWarning={hasByLeader && hasCalibration && sections?.length >= 2}
        onClose={() => setRenameModalOpen(false)}
        onCompleted={(updatedSection) =>
          onOptionsClicked("add", selectedIndex, updatedSection)
        }
      />
    </DragDropContext>
  );
};
