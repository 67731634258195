import { useMemo, useCallback } from "react";

import { useSearchParams } from "react-router-dom";

import { cloneDeep } from "lodash-es";

import { parse } from "qs";

import { trpc } from "@api/client";

import { dispatchToast } from "@utils";
interface IUseQuadrantProps {
  quadrantId: string;
  evaluationId: string;
  isOpen: boolean;
}

const useQuadrant = ({
  quadrantId,
  evaluationId,
  isOpen,
}: IUseQuadrantProps) => {
  const [searchParams] = useSearchParams();

  const {
    data: quadrant,
    isLoading,
    isFetching,
  } = trpc.performance.evaluation.getMatrixQuadrantById.useQuery(
    {
      evaluationId,
      quadrantId,
    },
    {
      enabled: !!isOpen && !!evaluationId && !!quadrantId,
      onError: (e: any) => {
        const evaluationNotExists =
          e?.data?.error === "EVALUATION_NOT_EXISTS_ERROR";

        const quadrantNotExists =
          e?.data?.error === "QUADRANT_NOT_EXISTS_ERROR";

        let message = "Erro ao buscar quadrante, tente novamente em breve!";

        if (evaluationNotExists)
          message = "Erro ao buscar quadrante, avaliação não encontrada!";

        if (quadrantNotExists)
          message = "Erro ao buscar quadrante, quadrante não encontrado!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    }
  );

  const {
    data: matrixFilters = [],
    isLoading: isLoadingFilters,
    isFetching: isFetchingFilters,
  } = trpc.performance.evaluation.getEvaluationDetailsMatrixFilters.useQuery(
    {
      evaluationId,
    },
    { refetchOnMount: false, enabled: !!isOpen && !!evaluationId }
  );

  const filteredValues = useMemo(() => {
    const filtersMatrix = searchParams.get("filtersMatrix") || {};

    const parsedFilters = parse(filtersMatrix) || {};

    const departmentIds: string[] = Array.isArray(parsedFilters?.departmentIds)
      ? parsedFilters.departmentIds || []
      : [];

    const evaluateds: string[] = Array.isArray(parsedFilters?.evaluateds)
      ? parsedFilters.evaluateds || []
      : [];

    const reportsTo: string[] = Array.isArray(parsedFilters?.reportsTo)
      ? parsedFilters.reportsTo || []
      : [];

    const roleId: string[] = Array.isArray(parsedFilters?.roleId)
      ? parsedFilters.roleId || []
      : [];

    return {
      departmentIds,
      evaluateds,
      reportsTo,
      roleId,
    };
  }, [searchParams]);

  const hasAnyFilteredValue = useCallback(() => {
    return Object.keys(filteredValues).some(
      (key) => !!filteredValues[key]?.length
    );
  }, [filteredValues]);

  const filtersWithValues = useMemo(() => {
    const hasFiltered = hasAnyFilteredValue();

    if (!hasFiltered) return matrixFilters || [];

    if (!matrixFilters?.length) return matrixFilters || [];

    const clonedFilters = cloneDeep(matrixFilters);

    const filtersWithValues = clonedFilters.map((f) => {
      const hasValue = !!filteredValues[f.accessor]?.length;

      return {
        ...f,
        initialValues: hasValue ? filteredValues[f.accessor] : undefined,
      };
    });

    return filtersWithValues;
  }, [matrixFilters, filteredValues]);

  return {
    quadrant,
    filters: filtersWithValues,
    isLoading: isLoading || isFetching || isLoadingFilters || isFetchingFilters,
    filteredValues,
  };
};

export { useQuadrant };
