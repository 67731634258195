import { StyledTitle, dispatchToast } from "@utils";

import { trpc } from "@api/client";

import { Header } from "./components/Header";
import { HistoryTimeline } from "./components/HistoryTimeline";

import { Body, MainContainer, StyledDrawer } from "./styled";

interface ICalibrationDetailsDrawerProps {
  isOpen: boolean;
  evaluatedId?: string;
  evaluationId?: string;
  onClose: () => void;
}

export const CalibrationDetailsDrawer = ({
  isOpen,
  evaluatedId = "",
  evaluationId = "",
  onClose,
}: ICalibrationDetailsDrawerProps) => {
  const { data: calibrationHistory = [], isFetching } =
    trpc.performance.evaluation.getCalibrationHistory.useQuery(
      {
        evaluationId: evaluationId,
        evaluatedId: evaluatedId,
      },
      {
        onError: () =>
          dispatchToast({
            content: "Erro ao buscar histórico. Tente novamente em breve.",
            type: "error",
          }),
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        enabled: !!isOpen && !!evaluationId && !!evaluatedId,
      }
    );

  const { data: employeeData, isFetching: loadingEmployeeData } =
    trpc.employee.getEmployeeDataByEvaluation.useQuery(
      {
        evaluationId: evaluationId,
        employeeId: evaluatedId,
      },
      {
        onError: () =>
          dispatchToast({
            content:
              "Erro ao buscar dados do avaliado. Tente novamente em breve.",
            type: "error",
          }),
        enabled: !!isOpen && !!evaluationId && !!evaluatedId,
      }
    );

  return (
    <StyledDrawer anchor="right" open={isOpen} onClose={onClose}>
      <MainContainer>
        <Header
          employeeData={employeeData}
          loading={loadingEmployeeData}
          onClose={onClose}
        />

        <Body>
          <StyledTitle
            variant="headline8"
            setColor="neutral30"
            children="Histórico de calibração"
          />

          <HistoryTimeline
            calibrationHistory={calibrationHistory || []}
            isLoading={isFetching}
          />
        </Body>
      </MainContainer>
    </StyledDrawer>
  );
};
