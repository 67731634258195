import { ButtonBaseProps } from "@mui/material";
import { ReactNode, useRef, useState } from "react";
import { useTheme } from "styled-components";

import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import * as SC from "./styled";
import { TagCheckboxFilterPopover } from "./TagCheckboxFilterPopover";

export type TagCheckboxFilterProps = {
  variant: "primary" | "secondary" | "tertiary" | "error" | "disabled";
  loading?: boolean;
  hasLeftIcon?: boolean;
  hasRightIcon?: boolean;
  options?: { label: string; value: string }[];
  badgeNumber?: ReactNode;
  filterLabel: ReactNode;
  children?: ReactNode;
  onClick(value: Array<string>): void;
  showClearButton?: boolean;
  showSelectAllButton?: boolean;
  initialValues?: string[];
} & ButtonBaseProps;

export const TagCheckboxFilter = ({
  variant,
  options,
  style,
  onClick,
  onFocus,
  onSubmit,
  onError,
  type,
  classes,
  id,
  hasLeftIcon = true,
  hasRightIcon = true,
  filterLabel,
  className,
  badgeNumber,
  showClearButton,
  showSelectAllButton,
  initialValues = [],
  children,
}: TagCheckboxFilterProps) => {
  const disabled = variant === "disabled";
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { spacings } = useTheme();

  return (
    <>
      <SC.Button
        active={Boolean(badgeNumber)}
        id={id}
        variant={variant}
        className={`loading ${className} `}
        style={style}
        classes={classes}
        ref={buttonRef}
        type={type}
        onClick={(e) => {
          buttonRef?.current?.focus();
          setAnchorEl?.(e.currentTarget);
        }}
        onFocus={onFocus}
        onSubmit={onSubmit}
        onError={onError}
        disabled={disabled}
      >
        {hasLeftIcon ? (
          <Icons
            name="IconSquareDot"
            fill="transparent"
            style={{ marginRight: spacings.xs4, background: "transparent" }}
          />
        ) : (
          <></>
        )}
        <Typography variant="body3">{filterLabel}</Typography>
        {hasRightIcon ? (
          <Icons
            name="IconChevronDown"
            fill="transparent"
            style={{ marginLeft: "8px", background: "transparent" }}
          />
        ) : (
          <></>
        )}
        {Boolean(badgeNumber) && (
          <SC.BadgeNumber variant={variant} active={Boolean(badgeNumber)}>
            <Typography variant="caption">{badgeNumber}</Typography>
          </SC.BadgeNumber>
        )}
      </SC.Button>
      <TagCheckboxFilterPopover
        anchorEl={anchorEl}
        onClick={onClick}
        options={options}
        setAnchorEl={setAnchorEl}
        showClearButton={showClearButton}
        showSelectAllButton={showSelectAllButton}
        initialValues={initialValues}
      >
        {children}
      </TagCheckboxFilterPopover>
    </>
  );
};
