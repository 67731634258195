import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
`;

const LabelColumn = styled.div`
  display: flex;
  width: 98px;
`;

export { MainContainer, LabelColumn };
