import styled from "styled-components";

import { TextField } from "@flash-tecnologia/hros-web-ui-v2";

export const InputArea = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 16px;
`;

export const StyledTextField = styled(TextField)``;
