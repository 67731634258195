import { useState } from "react";
import { FormikProps } from "formik";

import { HistoryTimeline } from "./HistoryTimeline";
import { Calibration } from "./Calibration";
import { Evaluation } from "./Evaluation";

import type { CalibrationFormType } from "../../types";

import { StyledTab } from "./styled";

interface TabsProps {
  formik: FormikProps<CalibrationFormType>;
  loading: boolean;
  employeeId: string;
  evaluationId: string;
}

export const Tabs = ({
  formik,
  loading,
  employeeId,
  evaluationId,
}: TabsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const options = [
    {
      label: "Calibração",
      component: <Calibration formik={formik} loading={loading} />,
    },
    {
      label: "Avaliação",
      component: (
        <Evaluation employeeId={employeeId} evaluationId={evaluationId} />
      ),
    },
    {
      label: "Histórico de calibração",
      component: (
        <HistoryTimeline employeeId={employeeId} evaluationId={evaluationId} />
      ),
    },
  ];

  return (
    <StyledTab
      onTabChanged={setActiveTab}
      selected={activeTab}
      tabItens={options}
    />
  );
};
