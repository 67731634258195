import { useSearchParams } from "react-router-dom";

import { Skeleton, EmptyState } from "@flash-tecnologia/hros-web-ui-v2";

import { Overview } from "./components/Overview";
import { Details } from "./components/Details";
import { Results } from "./components/Results";

import { StyledTab } from "./styled";

import type { Evaluation } from "server/src/services/evaluation/types";

interface ITabsProps {
  selectedEvaluation?: Evaluation;
  isLoading?: boolean;
}

const TabsSkeleton = () => {
  return (
    <>
      <Skeleton width={300} height={50} style={{ marginBottom: "18px" }} />
      <Skeleton width={"100%"} height={450} />
    </>
  );
};

export const Tabs = ({ selectedEvaluation, isLoading }: ITabsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const tabsNames = {
    ["overview"]: 0,
    ["results"]: 1,
    ["details"]: 2,
  };

  const tabsIndexes = {
    0: "overview",
    1: "results",
    2: "details",
  };

  const onTabChanged = (tab: number) => {
    searchParams.set("tab", tabsIndexes[tab]);

    setSearchParams(searchParams);
  };

  if (isLoading) return <TabsSkeleton />;

  if (!selectedEvaluation)
    return (
      <EmptyState
        buttonProps={{ size: "small", hidden: true }}
        buttonText=""
        description="Nenhuma avaliação encontrada."
      />
    );

  return (
    <StyledTab
      selected={tabsNames[searchParams.get("tab") || "overview"]}
      onTabChanged={onTabChanged}
      tabItens={[
        {
          label: "Visão Geral",
          component: <Overview evaluationId={selectedEvaluation._id} />,
        },
        {
          label: "Resultados",
          component: <Results evaluation={selectedEvaluation} />,
        },
        {
          label: "Detalhes",
          component: <Details selectedEvaluation={selectedEvaluation} />,
        },
      ]}
    />
  );
};
