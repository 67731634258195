import styled from "styled-components";
import { EmptyState } from "@assets/index";

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 42px 0px;
  gap: 32px;
`;

export const EmptyStateImg = styled.div`
  width: 127px;
  height: 116px;
  background-image: url(${EmptyState});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
`;

export const EmptyStateTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
