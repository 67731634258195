import { ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText, StyledTitle } from "@utils";

import { ContentContainer, IconContainer } from "../styled";

export const WarningStep = () => {
  return (
    <ContentContainer>
      <IconContainer>
        <ShapeIcon
          variant={"error"}
          name={"IconAlertCircle"}
          size={64}
          stroke={"error"}
        />
      </IconContainer>

      <StyledTitle
        variant="body3"
        children="Atenção!"
        setColor={"error40"}
        style={{ textAlign: "center" }}
      />

      <StyledTitle
        variant="headline6"
        children={"Tem certeza que deseja adicionar uma nova seção?"}
        setColor="neutral20"
        style={{
          textAlign: "center",
          marginBottom: "16px",
        }}
      />

      <StyledText
        variant="body3"
        children={
          <>
            Ao adicionar <b>mais de duas seções</b> na avaliação,{" "}
            <b>não será possível</b> utilizar a ferramenta de{" "}
            <b>matriz de calibração</b> para ver os resultados dos
            participantes.
          </>
        }
        setColor="neutral50"
        style={{
          textAlign: "center",
          marginBottom: "14px",
        }}
      />
    </ContentContainer>
  );
};
