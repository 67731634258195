import { StyledIcon, StyledText, avatarMaker } from "@utils";

import { AvatarContainer, ProfileArea, RoleContainer } from "./styled";

interface ProfileTableProps {
  name: string;
  email?: string | React.ReactNode;
  role?: string;
}

export const ProfileTable = ({ name, email, role }: ProfileTableProps) => {
  const renderDescription = (description: string | React.ReactNode) => {
    const isString = typeof description === "string";

    if (isString)
      return (
        <StyledText
          variant="body4"
          setColor="neutral50"
          weight={400}
          children={email}
        />
      );
    return description;
  };

  return (
    <ProfileArea>
      <AvatarContainer children={avatarMaker(name) || "-"} />
      <div>
        <StyledText
          variant="body3"
          setColor="neutral40"
          weight={600}
          children={name || "-"}
        />
        {role && (
          <RoleContainer>
            <StyledIcon name="IconBriefcase" size={14} setColor="neutral60" />
            <StyledText
              setColor="neutral50"
              variant={"body4"}
              children={role}
            />
          </RoleContainer>
        )}
        {email && renderDescription(email)}
      </div>
    </ProfileArea>
  );
};
