import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import { track } from "@utils";

import { MainContainer } from "./styled";

interface FooterProps {
  isLoading: boolean;
  isMutating: boolean;
  hasChanges: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

export const Footer = ({
  isLoading,
  isMutating,
  hasChanges,
  onCancel,
  onSubmit,
}: FooterProps) => {
  return (
    <MainContainer>
      <LinkButton
        variant="default"
        children="Cancelar"
        style={{ alignSelf: "center" }}
        disabled={false}
        onClick={onCancel}
      />
      <Button
        variant="primary"
        size="large"
        children="Calibrar"
        onClick={() => {
          onSubmit();

          track({
            name: "people_strategic_hr_performance_company_evaluations_evaluationdetails_results_persondrawer_calibrate_clicked",
          });
        }}
        disabled={isLoading || !hasChanges}
        loading={isMutating}
      />
    </MainContainer>
  );
};
