import styled from "styled-components";

import { Modal } from "@components/Modal";
import { IconButton } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledText } from "@utils";

export const StyledModal = styled(Modal)`
  && {
    .modal-container {
      width: 100%;
      max-width: 480px !important;
      max-height: 95vh;
      border-top-right-radius: 26px;
      border-top-left-radius: 26px;
    }
    .modal-content-area {
      padding: 0;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FooterContainer = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  padding: 12px 40px;
`;

export const HeaderContainer = styled(Flex)``;

export const ImgInfo = styled.img`
  border-radius: 16px;
  outline: 2px solid white;
  outline-offset: -2px;
`;

export const VideoIframe = styled.iframe`
  border-radius: 16px;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: ${({ theme }) => theme.colors.neutral[100]} !important;
`;

export const TextArea = styled.div`
  padding: 32px 40px 24px 40px;
`;

export const StyledList = styled.ul`
  li {
    color: ${({ theme }) => theme.colors.neutral[40]};
    line-height: 20px;
  }
`;

export const TitleNews = styled(StyledText)`
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
  }
`;
