import { useState } from "react";

import { getFromLS } from "@flash-tecnologia/hros-web-utility";

import {
  ShapeIcon,
  Button,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { trpc } from "@api/client";

import { StyledText, StyledTitle, StyledIcon, dispatchToast } from "@utils";

import { StyledModal, ContentContainer, IconContainer, Footer } from "./styled";

import type { CommonModalProps } from "@components/Modals/types";

interface IModalProps extends CommonModalProps {
  reportType: "grades" | "quadrants";
  evaluationId?: string;
  filters?: {
    reportsTo?: string[];
    departments?: string[];
    roles?: string[];
  };
}

export const ExportChartInfoModal = ({
  open,
  reportType,
  evaluationId = "",
  onClose,
  filters,
}: IModalProps) => {
  const [success, setSuccess] = useState(false);

  const { attributes = null } = getFromLS("userAuth");

  const { mutate, isLoading } =
    trpc.performance.report.generateEvaluationChartReport.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Relatório enviado com sucesso.",
        });
        setSuccess(true);
      },

      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao buscar dados da matriz, tente novamente em breve!",
        });
      },
    });

  return (
    <StyledModal
      open={open}
      onClose={(e) => {
        onClose?.(e);
        setSuccess(false);
      }}
      footer={
        <Footer success={success}>
          {success ? (
            <Button
              size="large"
              variant="primary"
              style={{ alignSelf: "center" }}
              onClick={() => {
                onClose?.(new Event("click"));
                setSuccess(false);
              }}
            >
              Voltar para Resultados
            </Button>
          ) : (
            <>
              <LinkButton
                variant="neutral"
                children="Cancelar"
                style={{ alignSelf: "center" }}
                onClick={() => {
                  onClose?.(new Event("click"));
                  setSuccess(false);
                }}
              />
              <Button
                size="large"
                variant="primary"
                style={{ alignSelf: "center" }}
                loading={isLoading}
                onClick={() => mutate({ evaluationId, reportType, filters })}
              >
                Exportar relatório
                <StyledIcon
                  setColor="neutral100"
                  name="IconDownload"
                  fill="none"
                  size={40}
                />
              </Button>
            </>
          )}
        </Footer>
      }
    >
      <ContentContainer>
        <IconContainer>
          <ShapeIcon
            variant={"default"}
            name={success ? "IconCheck" : "IconFileSpreadsheet"}
            size={64}
            stroke={"default"}
          />
        </IconContainer>

        {success && (
          <StyledTitle
            variant="body3"
            children="Pronto!"
            setColor="secondary50"
            style={{ textAlign: "center" }}
          />
        )}

        <StyledTitle
          variant="headline6"
          children={
            success
              ? "Seu relatório está sendo exportado!"
              : "Deseja exportar o relatório?"
          }
          setColor="neutral20"
          style={{
            textAlign: "center",
            marginBottom: "16px",
          }}
        />

        <StyledText
          variant="body3"
          setColor="neutral50"
          style={{
            textAlign: "center",
            marginBottom: "14px",
          }}
        >
          {success ? (
            <>
              Para acessá-lo é só conferir o e-mail que será enviado em alguns
              minutos para o endereço: <b>{attributes?.email}</b>
              <br />
              <br />
              Para acessar os dados utilize os{" "}
              <b>4 primeiros dígitos do seu CPF</b> como senha.
            </>
          ) : (
            <>
              O relatório apresentará somente os <b>dados desse gráfiico</b>{" "}
              convertidos em um arquivo excel.
              <br /> O arquivo será enviado para o email:
              <br />
              <br />
              <b>{attributes?.email}</b>
            </>
          )}
        </StyledText>
      </ContentContainer>
    </StyledModal>
  );
};
