import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;

  background-color: ${({ theme }) => theme.colors.secondary[99]};
  border: 1px solid ${({ theme }) => theme.colors.secondary[70]};
  border-radius: 8px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ButtonLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
