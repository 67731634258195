import { useMemo } from "react";

import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import { GradeChart } from "./Charts/GradeChart";
import { QuadrantChart } from "./Charts/QuadrantChart";

import {
  EmptyStateContainer,
  EmptyStateImg,
  EmptyStateTextContainer,
} from "./styled";

import { trpc } from "@api/client";

import { StyledIcon, StyledText, StyledTitle, dispatchToast } from "@utils";

import type { Evaluation } from "./types";

interface IGraphProps {
  evaluation: Evaluation;
}

const EmptyState = ({
  status,
}: {
  status: "error" | "noEvaluation" | "noGrades";
}) => {
  switch (status) {
    case "error":
    case "noEvaluation":
      return (
        <EmptyStateContainer>
          <EmptyStateImg />

          <EmptyStateTextContainer>
            <StyledTitle
              variant="headline6"
              setColor="neutral20"
              style={{ textAlign: "center" }}
              children={
                status === "error"
                  ? "Ocorreu um erro"
                  : "Gráficos indisponíveis para essa avaliação"
              }
            />

            <StyledText
              variant="body3"
              setColor="neutral40"
              style={{ textAlign: "center" }}
              children={
                status === "error" ? (
                  "Não foi possível exibir as informações, por favor recarregue a página."
                ) : (
                  <>
                    Somente avaliações com a modalidade{" "}
                    <b>modalidade “Líder avalia liderado”</b> têm acesso aos
                    gráficos, uma vez que apenas a nota do líder permite gerar
                    uma nota final.
                  </>
                )
              }
            />
          </EmptyStateTextContainer>
          {status === "error" ? (
            <LinkButton
              variant="neutral"
              children={
                <>
                  Recarregar <StyledIcon name="IconRefresh" size={16} />
                </>
              }
            />
          ) : null}
        </EmptyStateContainer>
      );
    case "noGrades":
    default:
      return (
        <EmptyStateContainer>
          <EmptyStateImg />

          <StyledTitle
            variant="body1"
            style={{ textAlign: "center", maxWidth: 500 }}
            children={"Nenhum dado encontrado"}
            setColor="neutral20"
          />
          <StyledText
            variant="body3"
            style={{ textAlign: "center", maxWidth: 500 }}
            children={`Aguarde até que pelo menos um participante conclua a avaliação de modalidade "Líder avalia liderado" para que o gráfico seja exibido.`}
            setColor="neutral40"
          />
        </EmptyStateContainer>
      );
  }
};

export const Graph = ({ evaluation }: IGraphProps) => {
  const hasLeaderEvaluation = useMemo(
    () => !!evaluation?.types?.find(({ type }) => type === "byLeader"),
    [evaluation?.types?.map((t) => t.type)]
  );

  const {
    data: checkHasGrades,
    isError,
    isLoading,
  } = trpc.performance.evaluation.getCheckEvaluationHasGrades.useQuery(
    {
      evaluationId: evaluation._id,
    },
    {
      enabled: !!evaluation?._id && hasLeaderEvaluation,
    }
  );

  const { data: filters = [] } =
    trpc.performance.evaluation.getEvaluationChartsFilters.useQuery(
      {
        evaluationId: evaluation._id,
      },
      {
        enabled: !!evaluation?._id && hasLeaderEvaluation,
        onError: () =>
          dispatchToast({
            type: "error",
            content:
              "Erro ao carregar as informações do gráfico de notas. Tente novamente em breve.",
          }),
      }
    );

  const showNoGrades = (!isLoading && !checkHasGrades?.hasGrades) || isError;

  if (!hasLeaderEvaluation) return <EmptyState status={"noEvaluation"} />;

  if (showNoGrades)
    return <EmptyState status={isError ? "error" : "noGrades"} />;

  return (
    <>
      <GradeChart evaluation={evaluation} filters={filters} />
      {evaluation.matrix && (
        <QuadrantChart evaluation={evaluation} filters={filters} />
      )}
    </>
  );
};
