import { Tag, TagProps, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";

import { Container } from "./styled";

import { StyledPLine, StyledText } from "@utils";

type TagListProps = Array<
  {
    id: string | number;
    label: string;
    variant?: TagProps["variant"];
  } & Omit<TagProps, "variant">
>;

interface ITagsListProps {
  tags: TagListProps;
  limit?: number;
}

export const TagList = ({ tags, limit = 12 }: ITagsListProps) => {
  const visibleTags = tags?.slice(0, limit);
  const remainingTags = tags?.slice(limit);

  return (
    <Container>
      {visibleTags?.map((tag) => (
        <Tooltip key={tag?.id} title={tag?.label} placement="top-start">
          <Tag {...tag} variant={tag?.variant ? tag?.variant : "primary"}>
            <StyledPLine
              numberOfLines={1}
              children={
                <StyledText
                  variant="caption"
                  fontWeight={600}
                  children={tag?.label}
                />
              }
            />
          </Tag>
        </Tooltip>
      ))}
      {remainingTags?.length > 0 && (
        <Tooltip
          title={remainingTags?.map((tag) => tag?.label).join(", ")}
          placement="top-start"
        >
          <Tag key="remainingCountTag" variant="primary">
            {`+${remainingTags?.length}`}
          </Tag>
        </Tooltip>
      )}
    </Container>
  );
};
