import { Skeleton } from "@mui/material";

import { StyledIcon, StyledTitle, avatarMaker } from "@utils";

import {
  FlexRow,
  LeftColumn,
  MainContainer,
  RightColumn,
  StyledSelect,
  AvatarContainer,
} from "./styled";

interface IHeaderProps {
  evaluated?: { name: string; _id: string };
  evaluator?: { name: string; _id: string };
  evaluatorsOptions?: {
    label: string;
    value: string;
  }[];
  loading?: boolean;
  onSelectedEvaluator: (_id: string) => void;
}

export const Header = ({
  evaluated,
  evaluator,
  evaluatorsOptions,
  loading,
  onSelectedEvaluator,
}: IHeaderProps) => {
  const renderPerson = ({ type, name }) => {
    return (
      <FlexRow>
        <StyledTitle
          variant="body4"
          children={type === "evaluator" ? "Avaliador" : "Avaliado"}
          setColor="neutral50"
        />
        <AvatarContainer children={avatarMaker(name || "")} />
        <div>
          <StyledTitle variant="body3" children={name} setColor="neutral40" />
        </div>
      </FlexRow>
    );
  };

  if (loading)
    return (
      <MainContainer>
        <Skeleton width="100%" height={"60px"} variant="rounded" />
      </MainContainer>
    );

  return (
    <MainContainer>
      <LeftColumn>
        <StyledSelect
          label={"Selecionar avaliador"}
          noOptionsText={"Nenhum avaliador disponível"}
          value={evaluator?._id}
          onSelectChange={(_, option) => {
            onSelectedEvaluator(option?.value);
          }}
          searchable={true}
          options={evaluatorsOptions}
          fullWidth
        />
      </LeftColumn>

      <RightColumn>
        {evaluator?.name &&
          renderPerson({
            type: "evaluator",
            name: evaluator.name || "",
          })}

        {evaluator && <StyledIcon name="IconArrowRight" setColor="neutral60" />}

        {renderPerson({
          type: "evaluated",
          name: evaluated?.name || "",
        })}
      </RightColumn>
    </MainContainer>
  );
};
