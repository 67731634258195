import Banner from "@components/Banner";

import { StyledText } from "@utils";

interface IWarningBanner {
  hasByLeaderType?: boolean;
  hasCalibrationStep?: boolean;
}

export const WarningBanner = ({
  hasByLeaderType,
  hasCalibrationStep,
}: IWarningBanner) => {
  const renderChildren = () => {
    if (!hasCalibrationStep)
      return (
        <StyledText variant="body4">
          Você não adicionou a etapa de <b>calibração ao ciclo</b>, portanto, a
          ferramenta de matriz de calibração <b>não estará disponível</b>. Para
          ter acesso a visualização de matriz, certifique-se de{" "}
          <b>incluir a etapa de calibração no ciclo</b>.
        </StyledText>
      );

    if (!hasByLeaderType)
      return (
        <StyledText variant="body4">
          Apenas a nota do líder (modalidade <b>“Líder avalia liderado”</b>)
          permite <b>gerar matriz de calibração.</b>
          <p>
            Para ter acesso a visualização de matriz, inclua esse tipo de
            avaliação na etapa de <b>Configurações.</b>
          </p>
        </StyledText>
      );

    return (
      <StyledText variant="body4">
        Somente avaliações com <b>duas seções</b> terão acesso à matriz, uma vez
        que ela necessita de{" "}
        <b>dois eixos, formados pelas seções da avaliação.</b> Para ter acesso à
        matriz, reconfigure a avaliação na etapa de{" "}
        <b>Formulário de avaliação.</b>
      </StyledText>
    );
  };

  return (
    <Banner
      type="info"
      icon="IconInfoCircle"
      title="Não é possível configurar a matriz de calibração"
      hasHideBanner={true}
      children={renderChildren()}
      style={{ marginBottom: "24px" }}
    />
  );
};
