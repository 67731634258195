import styled from "styled-components";

export const Span = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

export const MatrixWarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
