import { Checkbox } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledTitle } from "@utils";
import styled from "styled-components";

export const ContentArea = styled.div`
  padding: 24px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  display: flex;
`;

export const DatePickerArea = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 16px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    flex-direction: column;
  }
`;

export const Title = styled(StyledTitle)`
  display: flex;
  align-items: center;
`;

export const StyledCheckBox = styled(Checkbox)`
  position: relative;
  display: inline-block;
  padding: 0;
  margin-right: 20px;
  height: fit-content;
  margin-top: 14px;

  svg path {
    fill: ${({ theme, disabled }) =>
      disabled ? theme.colors.neutral[90] : ""};
    stroke: ${({ theme, disabled, checked }) =>
      disabled && !checked ? theme.colors.neutral[90] : ""};
  }
`;
