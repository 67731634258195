import styled, { css } from "styled-components";

export const ArrowArea = styled.div<{ position?: "vertical" | "horizontal" }>`
  display: flex;
  align-items: center;
  max-width: 260px;
  ${({ position }) =>
    position === "vertical"
      ? css`
          transform: translateX(-50%) translateY(-50%) rotate(-90deg);
          position: relative;
          top: 140px;
          margin-top: -10px;
        `
      : null}
`;

export const Line = styled.span<{ variant?: "primary" | "secondary" }>`
  width: -webkit-fill-available;
  height: 2px;
  background-color: ${({ theme, variant }) =>
    variant === "secondary"
      ? theme.colors.neutral[90]
      : theme.colors.secondary[80]};
  margin-right: 10px;
`;

export const Text = styled.span<{ variant?: "primary" | "secondary" }>`
  color: ${({ theme, variant }) =>
    variant === "secondary"
      ? theme.colors.neutral[70]
      : theme.colors.secondary[50]};
  font-size: 13px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: 1.3px;
  width: fit-content;
  text-wrap: nowrap;
`;

export const ArrowLine = styled.span<{ variant?: "primary" | "secondary" }>`
  position: relative;
  width: -webkit-fill-available;
  height: 2px;
  background-color: ${({ theme, variant }) =>
    variant === "secondary"
      ? theme.colors.neutral[90]
      : theme.colors.secondary[80]};
`;

export const Arrow = styled.div<{ variant?: "primary" | "secondary" }>`
  font-size: 24px;
  font-family: "Cabinet Grotesk";
  font-weight: bold;
  color: ${({ theme, variant }) =>
    variant === "secondary"
      ? theme.colors.neutral[90]
      : theme.colors.secondary[80]};
  position: relative;
  left: -10px;
  top: 3px;
`;
