import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { cloneDeep, flatMap, isInteger, meanBy } from "lodash-es";

import { trpc } from "@api/client";

import { routes } from "@routes";

import { dispatchToast } from "@utils";

import type {
  AnswerEvaluationType,
  BatchAnswerItemType,
  EmployeeEvaluation,
  EmployeeEvaluationCriterial,
  EvaluationReviewData,
  UseEvaluationParams,
} from "./types";

const useEvaluation = ({ participantId, step, type }: UseEvaluationParams) => {
  const navigate = useNavigate();
  const utils = trpc.useContext();

  const { cycleId = "" } = useParams();

  const [employeeEvaluation, setEmployeeEvaluation] = useState<
    EmployeeEvaluation | undefined
  >();

  const [answeredQuestions, setAnsweredQuestions] = useState<
    BatchAnswerItemType[]
  >([]);

  const [errorCriterial, setErrorCriterial] = useState<{ _id: string }[]>([]);

  const answeredRef = useRef(answeredQuestions);

  answeredRef.current = answeredQuestions;

  const { isFetching, error: errorFetching } =
    trpc.performance.evaluation.getEmployeeEvaluation.useQuery(
      {
        participantId,
      },
      {
        onSuccess: (data) => setEmployeeEvaluation(data),
        retry: false,
        refetchOnWindowFocus: false,
        staleTime: 0,
        enabled: !!participantId,
        onError: () => {
          dispatchToast({
            content:
              "Erro ao carregar os dados da avaliação. Tente novamente em breve.",
            type: "error",
          });
        },
      }
    );

  const { mutate: finishEmployeeEvaluationMutate, isLoading: isFinishing } =
    trpc.performance.evaluation.finishEmployeeEvaluation.useMutation({
      onSuccess: () => {
        utils.performance.evaluation.getEmployeeEvaluationByCycleId.invalidate();
        utils.performance.evaluation.getEvaluationDetailsResultsByLeader.invalidate();

        navigate(routes.PageMyCycles(cycleId));
      },
      onError() {
        dispatchToast({
          type: "error",
          content: "Erro ao finalizar o progresso, tente novamente mais tarde!",
        });
      },
    });

  const { mutate: setAnswerMultipleMutate, isLoading: answerLoading } =
    trpc.performance.monitoring.setAnswerEvaluationMultiple.useMutation({
      onSuccess: (values, variables) => {
        utils.performance.evaluation.getEmployeeEvaluationByCycleId.invalidate();
        const questions = cloneDeep(answeredQuestions);

        const createdMonitorings = cloneDeep(values || []);

        const filterArgs = (question: BatchAnswerItemType) => {
          const { answered, commented } = question;

          if (answered) {
            const { sectionId, criterialId } = answered;

            return !createdMonitorings.some(
              (monitoring) =>
                monitoring?.answered?.sectionId === sectionId &&
                monitoring?.answered?.criterialId === criterialId
            );
          }

          if (commented?.type === "general") {
            const { type } = commented;

            return !createdMonitorings.some(
              (monitoring) => monitoring?.commented?.type === type
            );
          }

          if (commented?.type === "criterial") {
            const { type, sectionId, criterialId } = commented;

            return !createdMonitorings.some(
              (monitoring) =>
                monitoring?.commented?.type === type &&
                monitoring?.commented?.sectionId === sectionId &&
                monitoring?.commented?.criterialId === criterialId
            );
          }

          return true;
        };

        const filteredQuestions = questions.filter(filterArgs);

        setAnsweredQuestions(filteredQuestions);

        if (!variables.finishEvaluation) return;

        if (!filteredQuestions.length) {
          finishEmployeeEvaluationMutate({ participantId });
          return;
        }

        dispatchToast({
          type: "error",
          content:
            "Erro ao salvar progresso das questões, tente novamente mais tarde!",
        });
      },
      onError() {
        dispatchToast({
          type: "error",
          content: "Erro ao salvar o progresso, tente novamente mais tarde!",
        });
      },
    });

  useEffect(() => {
    if (employeeEvaluation) {
      const isNotAvailable = ["done", "expired", "scheduled"].includes(
        employeeEvaluation.status
      );

      const steps = employeeEvaluation?.steps || [];

      const activeStep = isInteger(Number(step)) ? Number(step) : 0;

      if (isNotAvailable) {
        navigate(routes.PageViewEvaluation(cycleId, participantId, "index"));

        return;
      }

      if (type === "review") return;

      if (activeStep <= 0) {
        navigate(
          routes.PageViewEvaluation(cycleId, participantId, "evaluation", 1)
        );

        return;
      }

      if (activeStep > steps.length) {
        const lastStep = steps.length;

        navigate(
          routes.PageViewEvaluation(
            cycleId,
            participantId,
            "evaluation",
            lastStep
          )
        );

        return;
      }
    }
  }, [employeeEvaluation, type, step]);

  useEffect(() => {
    const alreadyAnswered = answeredRef?.current || [];

    alreadyAnswered.length && setAnsweredQuestions([]);

    const interval = setInterval(() => {
      const questions = answeredRef?.current || [];

      if (!questions.length) return;
      if (!employeeEvaluation) return;

      setAnswerMultipleMutate({
        evaluationId: employeeEvaluation._id,
        params: {
          type: employeeEvaluation.type,
          evaluatedId: employeeEvaluation.evaluated._id,
          answers: questions,
        },
      });
    }, 60000);

    return () => interval && clearInterval(interval);
  }, [participantId, employeeEvaluation?._id]);

  const activeStep = useMemo(
    () => (isInteger(Number(step)) ? Number(step) : undefined),
    [step]
  );

  const currentStep = useMemo(() => {
    const steps = employeeEvaluation?.steps || [];

    if (!steps.length || !activeStep) return;

    return steps?.[activeStep - 1];
  }, [employeeEvaluation, activeStep]);

  const progress = useMemo(() => {
    if (type === "review") return 100;

    const steps = employeeEvaluation?.steps || [];
    if (!steps.length || !activeStep) return;

    const stepTotalQty = steps.length;

    const progressByStep = (activeStep / stepTotalQty) * 100;
    const progressInEvaluation = (1 / 2) * progressByStep;

    return progressInEvaluation;
  }, [employeeEvaluation, activeStep, type]);

  const answerDetails = useMemo(() => {
    const clonedEvaluation = cloneDeep(employeeEvaluation);

    const sectionSteps =
      clonedEvaluation?.steps?.filter((step) => step?.type === "section") || [];

    const sectionsAverage = sectionSteps.map((sectionStep) => {
      const section = sectionStep.section;

      return Number(
        meanBy(section?.criterials || [], (item) => item.value || 0).toFixed(1)
      );
    });

    const criterials: EmployeeEvaluationCriterial[] = flatMap(
      sectionSteps || [],
      "section.criterials"
    );

    const answeredTotal = criterials.reduce((acc, obj) => {
      const answered = obj.hasOwnProperty("value") ? 1 : 0;
      return acc + answered;
    }, 0);

    const isFilled = answeredTotal === clonedEvaluation?.criteriaTotal;

    const average = meanBy(sectionsAverage, (item) => item || 0).toFixed(1);

    return {
      answerAverage: parseFloat(average),
      answeredTotal,
      totalSteps: employeeEvaluation?.steps?.length || 0,
      isFilled: !!clonedEvaluation?.criteriaTotal && isFilled,
    };
  }, [employeeEvaluation]);

  const reviewData = useMemo<EvaluationReviewData[]>(() => {
    if (!employeeEvaluation || type !== "review") return [];

    const clonedEvaluation = cloneDeep(employeeEvaluation);

    const scales = employeeEvaluation?.scale || [];

    const sectionSteps =
      clonedEvaluation?.steps?.filter((step) => step?.type === "section") || [];

    const criterials: EmployeeEvaluationCriterial[] = flatMap(
      sectionSteps || [],
      "section.criterials"
    );

    const criterialsMapped = criterials.map((criterial) => {
      const formatAnswer = (value: number | undefined) => {
        const scale = scales.find((s) => s.value === value);

        if (!scale) return "Não respondido";

        return `${scale.value} - ${scale.title}`;
      };

      const sectionIndex = (clonedEvaluation.steps || []).findIndex(
        (step) =>
          step?.type === "section" &&
          (step?.section?.criterials || []).some((c) => c._id === criterial._id)
      );

      return {
        _id: criterial._id,
        name: criterial.name,
        description: criterial.description,
        answer: formatAnswer(criterial.value),
        sectionIndex: sectionIndex !== -1 ? sectionIndex + 1 : 1,
      };
    });

    return criterialsMapped;
  }, [employeeEvaluation, type]);

  const filterNotAnswered = (employeeEvaluation) => {
    const sectionSteps =
      employeeEvaluation?.steps?.filter((step) => step?.type === "section") ||
      [];

    const criterials: EmployeeEvaluationCriterial[] = flatMap(
      sectionSteps || [],
      "section.criterials"
    );

    const filterNotAnswered = criterials
      .filter((obj) => !!!obj.hasOwnProperty("value"))
      ?.map((obj) => ({ _id: obj?._id }));

    return filterNotAnswered;
  };

  const checkForCriterialError = () => {
    setErrorCriterial(filterNotAnswered(employeeEvaluation));
    return;
  };

  const updateAnsweredQuestions = (newAnswer: BatchAnswerItemType) => {
    if (!newAnswer) return;

    const clonedAnsweredQuestions = cloneDeep(answeredQuestions);

    const findArgs = (question: BatchAnswerItemType) => {
      if (newAnswer.answered) {
        const criterial =
          question.answered?.criterialId === newAnswer.answered?.criterialId;

        const section =
          question.answered?.sectionId === newAnswer.answered?.sectionId;

        return criterial && section;
      }

      if (newAnswer?.commented?.type === "criterial") {
        const byType = question?.commented?.type === newAnswer?.commented?.type;

        const criterial =
          question.commented?.criterialId === newAnswer.commented?.criterialId;

        const section =
          question.commented?.sectionId === newAnswer.commented?.sectionId;

        return byType && criterial && section;
      }

      if (newAnswer?.commented?.type === "general") {
        const byType = question?.commented?.type === newAnswer?.commented?.type;

        return byType;
      }

      return false;
    };

    const foundIndex = clonedAnsweredQuestions.findIndex(findArgs);

    if (foundIndex !== -1) clonedAnsweredQuestions[foundIndex] = newAnswer;
    else clonedAnsweredQuestions.push(newAnswer);

    setAnsweredQuestions(clonedAnsweredQuestions);
  };

  const answerEvaluation: AnswerEvaluationType = ({
    question,
    commentary,
    generalCommentary,
  }) => {
    if (!employeeEvaluation) return;

    const clonedEvaluation = cloneDeep(employeeEvaluation);

    const newAnsweredQuestion: BatchAnswerItemType[] = [];

    if (generalCommentary) {
      const stepIndex = (clonedEvaluation.steps || []).findIndex(
        (step) => step.type === "generalCommentary"
      );

      if (stepIndex === -1) return;

      const value = generalCommentary.value || "";

      clonedEvaluation.steps[stepIndex].generalCommentary = value;

      newAnsweredQuestion.push({ commented: { type: "general", value } });
    }

    if (question) {
      const { sectionId, criterialId, value } = question;

      const stepIndex = (clonedEvaluation.steps || []).findIndex(
        (step) => step.type === "section" && step.section?._id === sectionId
      );

      if (stepIndex === -1) return;

      const criterials =
        clonedEvaluation.steps[stepIndex].section?.criterials || [];

      const criterialIndex = criterials.findIndex(
        (criterial) => criterial._id === criterialId
      );

      if (criterialIndex === -1) return;

      const selectedCriterial = criterials[criterialIndex];

      (clonedEvaluation.steps[stepIndex].section || { criterials }).criterials[
        criterialIndex
      ] = {
        ...selectedCriterial,
        value,
      };

      newAnsweredQuestion.push({
        answered: { sectionId, criterialId, value },
      });
    }

    if (commentary) {
      const { sectionId, criterialId, value } = commentary;

      const stepIndex = (clonedEvaluation.steps || []).findIndex(
        (step) => step.type === "section" && step.section?._id === sectionId
      );

      if (stepIndex === -1) return;

      const criterials =
        clonedEvaluation.steps[stepIndex].section?.criterials || [];

      const criterialIndex = criterials.findIndex(
        (criterial) => criterial._id === criterialId
      );

      if (criterialIndex === -1) return;

      const selectedCriterial = criterials[criterialIndex];

      (clonedEvaluation.steps[stepIndex].section || { criterials }).criterials[
        criterialIndex
      ] = {
        ...selectedCriterial,
        commentary: value,
      };

      newAnsweredQuestion.push({
        commented: { type: "criterial", sectionId, criterialId, value },
      });
    }

    setEmployeeEvaluation(clonedEvaluation);

    if (errorCriterial?.length)
      setErrorCriterial(filterNotAnswered(clonedEvaluation));

    if (!newAnsweredQuestion.length) return;

    updateAnsweredQuestions(newAnsweredQuestion[0]);
  };

  return {
    employeeEvaluation,
    progress,
    isFetching,
    isFinishing,
    errorFetching: !!errorFetching,
    errorCriterial,
    answerLoading,
    activeStep,
    totalSteps: answerDetails.totalSteps || 0,
    answerAverage: answerDetails.answerAverage || 0,
    currentStep,
    answeredTotal: answerDetails.answeredTotal || 0,
    answeredQuestions,
    isFilled: answerDetails.isFilled,
    reviewData,
    answerEvaluation,
    setAnswerMultipleMutate,
    finishEmployeeEvaluationMutate,
    checkForCriterialError,
  };
};

export { useEvaluation };
