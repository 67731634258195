import {
  DataGrid,
  Skeleton,
  EmptyState,
} from "@flash-tecnologia/hros-web-ui-v2";

import { pageSizeOptions } from "@utils";

import { useEvaluationDetails } from "./hooks";

import { DataGridContainer } from "./styled";

import type { TableGridCommonProps } from "@components/Table/types";
import type { EvaluationGrades } from "server/src/services/evaluation/types";

interface ITableGridProps extends TableGridCommonProps {
  types: EvaluationGrades["type"][];
  departments?: { label: string; value: string }[];
  selectedEvaluationId?: string;
}

export const TableGrid = ({
  data,
  rawData,
  loading,
  types,
  selectedEvaluationId,
}: ITableGridProps) => {
  const { columns, tableData } = useEvaluationDetails({
    types,
    data,
    selectedEvaluationId,
  });

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={"Nenhum resultado foi encontrado!"}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <DataGridContainer>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={(tableData as any[]) || []}
        expandSubRows
        initialState={{ pageSize: Number(10) }}
        emptyState={{
          isFiltered: false,
          message: "Nenhuma resultado foi encontrado!",
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </DataGridContainer>
  );
};
