import styled from "styled-components";

import { Dot } from "@flash-tecnologia/hros-web-ui-v2";

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const DataGridContainer = styled.div`
  min-height: 400px;
  .data-grid-pagination-container {
    margin: 16px 0px 0px 0px !important;
  }

  .data-grid-custom-select div.MuiSelect-select {
    padding-right: 36px !important;
  }

  .select-icon-custom svg {
    top: calc(50% - 0.7em);
    right: 5px;
  }
`;

const StyledDot = styled(Dot).attrs(() => ({
  variant: "pink",
  style: { height: "12px", width: "12px", borderRadius: "50%" },
}))`
  background-color: ${({ theme }) => theme.colors.secondary[50]};
`;
export { FlexBetween, FlexColumn, DataGridContainer, StyledDot };
