import { ConfirmationModal } from "@components/Modals";

import { StyledText } from "@utils";

import { Span, MatrixWarningContainer } from "./styled";

import type { CommonModalProps } from "@components/Modals/types";

interface IDeleteSectionModal extends CommonModalProps {
  sectionsLengthWarning?: boolean;
  matrixSectionWarning?: boolean;
}

export const DeleteSectionModal = ({
  open,
  sectionsLengthWarning,
  matrixSectionWarning,
  isLoading,
  onClose,
  onConfirm,
}: IDeleteSectionModal) => {
  return (
    <ConfirmationModal
      open={open}
      showWarning
      icon="IconAlertCircle"
      title="Tem certeza que deseja excluir a seção?"
      subTitle={
        <MatrixWarningContainer>
          {matrixSectionWarning && (
            <StyledText
              variant="body3"
              setColor="neutral50"
              style={{ textAlign: "center" }}
            >
              Esta <b>seção está atrelada à matriz</b> de calibração. Ao
              prosseguir para excluir, as configurações dos <b>eixos</b> da
              matriz serão <b>perdidas</b> e será necessário reconfigurá-las.
            </StyledText>
          )}
          Todos os critérios serão removidos e essa ação não poderá ser
          desfeita.
        </MatrixWarningContainer>
      }
      confirm={{ title: "Confirmar exclusão", icon: "IconTrash" }}
      onClose={onClose}
      onConfirm={onConfirm}
      isLoading={isLoading}
      children={
        sectionsLengthWarning && (
          <StyledText
            variant="body3"
            setColor="neutral50"
            style={{ textAlign: "center" }}
            children={
              <>
                <Span>Importante:</Span> Somente avaliações com{" "}
                <b>duas seções</b> poderão acessar a <b>matriz de calibração</b>
                .
              </>
            }
          />
        )
      }
    />
  );
};
