import { Dot } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledIcon, StyledText, StyledTitle, avatarMaker } from "@utils";

import {
  CardContainer,
  ContentContainer,
  IconContainer,
  LeftColumn,
  QuadrantContainer,
  StyledAvatar,
} from "./styled";

import type { Evaluated } from "../../../types";

interface IParticipantsProps {
  participant: Evaluated;
  onClick: (evaluated: Evaluated) => void;
}

export const ParticipantCard = ({
  participant,
  onClick,
}: IParticipantsProps) => {
  return (
    <CardContainer onClick={() => onClick(participant)}>
      <LeftColumn>
        <StyledAvatar
          children={
            <StyledTitle
              variant="headline8"
              children={avatarMaker(participant?.name || "")}
            />
          }
        />

        <ContentContainer>
          <StyledText
            variant="body3"
            children={participant?.name || ""}
            setColor="neutral50"
            weight={600}
          />

          {!!participant?.originalQuadrantName && (
            <QuadrantContainer>
              <StyledText
                variant="body4"
                children={
                  <>
                    <b>Original: </b>
                    {participant?.originalQuadrantName}
                  </>
                }
                setColor="neutral50"
                weight={600}
              />

              {participant.calibrationAverage && (
                <>
                  <Dot
                    variant="gray"
                    style={{ alignSelf: "center", margin: "0px 12px" }}
                  />

                  <StyledText
                    variant="body4"
                    children={
                      <>
                        <b>Calibrado: </b>
                        {participant?.quadrantName || "-"}
                      </>
                    }
                    setColor="neutral50"
                    weight={600}
                  />
                </>
              )}
            </QuadrantContainer>
          )}
        </ContentContainer>
      </LeftColumn>

      <IconContainer>
        <StyledIcon name="IconChevronRight" setColor="neutral40" size={24} />
      </IconContainer>
    </CardContainer>
  );
};
