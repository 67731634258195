import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { MainContainer } from "../styled";

export const LoadingSkeleton = () => {
  const cards = [1, 2, 3];

  const cardsMapped = cards.map((c) => (
    <Skeleton key={c} width={"100%"} height="78px" variant="rounded" />
  ));

  return <MainContainer children={cardsMapped} />;
};
