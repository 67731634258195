import styled from "styled-components";
import { EmptyState } from "@assets/index";

const Row = styled.div`
  display: flex;
  min-width: 1190px;
  margin: 0 auto;
  width: 100%;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  margin-top: 24px;
  scrollbar-width: none;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 42px 0px;
  gap: 32px;
`;

const EmptyStateImg = styled.div`
  width: 127px;
  height: 116px;
  background-image: url(${EmptyState});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
`;

const EmptyStateTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export {
  Row,
  MainContainer,
  EmptyStateContainer,
  EmptyStateImg,
  EmptyStateTextContainer,
};
