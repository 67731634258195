import styled from "styled-components";
import { EmptyState } from "@assets/index";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  gap: 16px;
  padding: 80px 0px;
`;

export const EmptyStateImg = styled.div`
  width: 160px;
  height: 156px;
  background-image: url(${EmptyState});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
`;

export const Span = styled.span`
  font-weight: 700;
`;
