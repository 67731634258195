import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 24px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 40px;
`;

export const TitleActionsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 13px;
`;

export const AxisContainer = styled.div`
  display: flex;
  gap: 40px;
`;

export const AxisContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const IconContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const PillButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 12px;
`;

export const PillButtonHidden = styled.div`
  width: 40px;
  height: 40px;
  visibility: hidden;
`;
