import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

import { useFormik } from "formik";
import { compact } from "lodash-es";
import * as yup from "yup";

import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { PageTemplate } from "@components/PageTemplate";

import {
  EvaluationWithSaveModal,
  EvaluationWithoutSaveModal,
} from "@components/Modals";

import { AboutCalibrationMatrixDrawer } from "@components/Drawers";

import { DraggableSections } from "./components/DraggableSections";
import { SectionsForm } from "./components/SectionsForm";
import { FlashTip } from "./components/FlashTip";

import { trpc } from "@api/client";
import { routes } from "@routes";

import {
  StyledText,
  StyledTitle,
  dispatchToast,
  getIn,
  getObjDiffs,
  track,
} from "@utils";

import {
  addSection,
  duplicateSection,
  removeSection,
  renameSection,
} from "./utils";

import { Header, LeftContainer } from "../../Steps/styled";

import type { BasicStepProps, SectionsFormProps } from "../../Steps/types";

import type { onOptionsClickedType } from "./types";

const validationSchema = yup.object({
  sections: yup
    .array()
    .of(
      yup.object({
        _id: yup.string().notRequired(),
        name: yup.string().required("Este campo deve ser preenchido"),
        description: yup.string().notRequired(),
        criterials: yup
          .array()
          .of(
            yup.object({
              _id: yup.string().required("Este campo deve ser preenchido"),
              type: yup.string().oneOf(["custom", "default"]),
              name: yup.string().required("Este campo deve ser preenchido"),
              // description: yup
              //   .string()
              //   .test(
              //     "empty text editor",
              //     "Este campo deve ser preenchido",
              //     (val) => Boolean(removeHtmlTags(val).trim().length)
              //   )
              //   .required("Este campo deve ser preenchido"),
            })
          )
          .min(1),
      })
    )
    .min(1),
});

export const EvaluationFormStep = ({
  isEdit,
  isLoading,
  isUpdating,
  error,
  steps,
  breadcrumbs,
  evaluation,
  disabledEdit,
  onSubmit,
  onNavigateByStep,
}: BasicStepProps) => {
  const navigate = useNavigate();

  const utils = trpc.useContext();

  const [selectedSectionIndex, setSelectedSectionIndex] = useState<number>(0);

  const [exitAndSaveModal, setExitAndSaveModal] = useState(false);
  const [withoutSaveModal, setWithoutSaveModal] = useState(false);

  const [aboutCalibrationDrawer, setAboutCalibrationDrawer] = useState(false);

  const disabled = disabledEdit.partial || disabledEdit.all;

  const hasByLeader = !!evaluation?.types?.find(
    (type) => type.type === "byLeader"
  );

  const hasCalibration = !!evaluation?.cycle.steps.find(
    (step) => step.type === "calibration"
  );

  const matrixSectionIds = useMemo(() => {
    if (!evaluation || !evaluation.matrix) return [];

    return compact([
      evaluation?.matrix?.horizontalAxis?.sectionId,
      evaluation?.matrix?.verticalAxis?.sectionId,
    ]);
  }, [evaluation]);

  const formik = useFormik<SectionsFormProps>({
    initialValues: {
      sections: [
        {
          order: 1,
          name: "Seção 1",
          description: "",
          fromModel: false,
          criterials: [],
        },
        {
          order: 2,
          name: "Seção 2",
          description: "",
          fromModel: false,
          criterials: [],
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const hasChanges = getObjDiffs(values, {
        sections: evaluation?.sections || [],
      });

      if (hasChanges) return onSubmit(values, "matrix");

      onNavigateByStep("matrix");
    },
  });

  useEffect(() => {
    if (!evaluation) return;

    formik.setValues({
      sections: (evaluation?.sections || []).length
        ? evaluation?.sections || []
        : formik.values.sections,
    });
  }, [evaluation]);

  const handleOptionsClicked: onOptionsClickedType = (
    actionType,
    sectionIndex,
    updatedSection
  ) => {
    if (actionType === "delete") {
      const updatedSections = removeSection({
        sections: formik.values.sections,
        selectedSectionIndex: sectionIndex,
      });

      formik.handleChange({
        target: { id: "sections", value: updatedSections },
      });

      dispatchToast({
        type: "success",
        content: "Seção excluída com sucesso.",
      });

      if (sectionIndex <= selectedSectionIndex) setSelectedSectionIndex(0);

      return;
    }

    if (actionType === "rename" && updatedSection) {
      const updatedSections = renameSection({
        sections: formik.values.sections,
        selectedSectionIndex: sectionIndex,
        updatedSection,
      });

      formik.handleChange({
        target: { id: "sections", value: updatedSections },
      });

      return;
    }

    if (actionType === "add" && updatedSection) {
      const updatedSections = addSection({
        sections: formik.values.sections,
        newSection: updatedSection,
      });

      formik.handleChange({
        target: { id: "sections", value: updatedSections },
      });

      setSelectedSectionIndex(updatedSections.length - 1);

      return;
    }

    if (actionType === "duplicate") {
      const updatedSections = duplicateSection({
        sections: formik.values.sections,
        selectedSectionIndex: sectionIndex,
      });

      formik.handleChange({
        target: { id: "sections", value: updatedSections },
      });

      setSelectedSectionIndex(updatedSections.length - 1);

      dispatchToast({
        type: "success",
        content: "Seção duplicada com sucesso.",
      });

      return;
    }
  };

  return (
    <PageTemplate
      stepper={{
        steps: steps,
        activeStep: 4,
      }}
      routes={breadcrumbs || []}
      footer={{
        cancelProps: {
          title: "Sair sem salvar",
          callback: () => setWithoutSaveModal(true),
        },
        ...(evaluation?.evaluationStatus === "draft" && {
          draftProps: {
            disabled: isLoading || isUpdating || error,
            title: "Sair e salvar rascunho",
            callback: () => setExitAndSaveModal(true),
          },
        }),
        goBackProps: {
          title: (
            <>
              <Icons name="IconArrowLeft" fill="transparent" />
              Voltar
            </>
          ),
          callback: () => onNavigateByStep("evaluateds"),
        },
        confirmProps: {
          title: (
            <>
              Continuar
              <Icons name="IconArrowRight" fill="transparent" />
            </>
          ),
          disabled: error || isLoading,
          loading: isUpdating,
          callback: async () => {
            const errors = await formik.validateForm();

            if (errors.sections && typeof errors.sections === "string") {
              dispatchToast({
                type: "error",
                content: "A avaliação deve conter pelo menos uma seção.",
              });
            }

            if (errors.sections && Array.isArray(errors.sections)) {
              const titleError = errors.sections.some(
                (section) => section.name
              );

              const criterialError = errors.sections.some(
                (section) => section?.criterials
              );

              if (titleError) {
                dispatchToast({
                  type: "error",
                  content: "Todas as seções devem ter um título.",
                });
              }

              if (criterialError) {
                dispatchToast({
                  type: "error",
                  content:
                    "As seções devem conter pelo menos um critério cada.",
                });
              }
            }

            track({
              name: "people_strategic_hr_performance_company_evaluations_createevaluation_evaluationform_continue_clicked",
            });

            formik.handleSubmit();
          },
        },
      }}
    >
      <Header>
        <StyledTitle
          setColor="neutral20"
          variant="headline6"
          children={`${isEdit ? "Editar" : "Criar"} Avaliação`}
        />
      </Header>
      <Grid container paddingBottom={"40px"} spacing={3}>
        <Grid item sm={12} md={4} lg={3} style={{ width: "100%" }}>
          <LeftContainer>
            <StyledTitle
              setColor="secondary50"
              variant="headline7"
              children={"Formulário de avaliação"}
            />

            <StyledText
              setColor="neutral50"
              variant="body3"
              children={"Agrupe os critérios avaliativos em seções."}
            />

            <DraggableSections
              sections={formik.values.sections || []}
              touched={formik.touched.sections || []}
              errors={
                Array.isArray(getIn(formik.errors, "sections"))
                  ? (formik.errors.sections as any)
                  : undefined
              }
              selectedIndex={selectedSectionIndex}
              matrixSectionIds={matrixSectionIds}
              disabledEdit={disabled}
              isLoading={isLoading}
              hasByLeader={hasByLeader}
              hasCalibration={hasCalibration}
              onOptionsClicked={handleOptionsClicked}
              onSelectItem={(_, index) => setSelectedSectionIndex(index)}
              onReordered={(sections, newIndex) => {
                formik.handleChange({
                  target: { id: "sections", value: sections },
                });

                if (newIndex === selectedSectionIndex) return;

                setSelectedSectionIndex(newIndex);
              }}
            />
          </LeftContainer>
        </Grid>

        <Grid item sm={12} md={8} lg={9} style={{ width: "100%" }}>
          {hasByLeader && hasCalibration && (
            <FlashTip onButtonClick={() => setAboutCalibrationDrawer(true)} />
          )}

          <SectionsForm
            formik={formik}
            selectedSectionIndex={selectedSectionIndex}
            isLoading={isLoading}
            disabledEdit={disabled}
            hasByLeader={hasByLeader}
            hasCalibration={hasCalibration}
            matrixSectionIds={matrixSectionIds}
            onOptionsClicked={handleOptionsClicked}
          />
        </Grid>
      </Grid>

      <EvaluationWithoutSaveModal
        open={withoutSaveModal}
        onClose={() => setWithoutSaveModal(false)}
        onConfirm={() => {
          utils.performance.evaluation.getAllEvaluationsPaginated.invalidate();
          navigate(routes.PageManageEvaluations);
        }}
      />

      <EvaluationWithSaveModal
        open={exitAndSaveModal}
        isLoading={isUpdating}
        onConfirm={async () => {
          const errors = await formik.validateForm();

          if (Object.keys(errors).length) {
            onNavigateByStep("manageEvaluations");
            return;
          }

          const values = formik.values;

          const hasChanges = getObjDiffs(values, {
            sections: evaluation?.sections || [],
          });

          if (hasChanges) return onSubmit(values, "manageEvaluations");

          onNavigateByStep("manageEvaluations");
        }}
        onClose={() => setExitAndSaveModal(false)}
      />

      <AboutCalibrationMatrixDrawer
        isOpen={aboutCalibrationDrawer}
        onClose={() => setAboutCalibrationDrawer(false)}
      />
    </PageTemplate>
  );
};
