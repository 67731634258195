import { useMemo, useState } from "react";
import { cloneDeep } from "lodash";

import {
  DataGrid,
  Skeleton,
  PillButton,
  Button,
  Menu,
} from "@flash-tecnologia/hros-web-ui-v2";

import { OverflowCheck } from "@components/OverflowCheck";
import { FeedbackTag } from "@components/FeedbackTags";

import { AddEvaluationCriterialsModal } from "@components/Modals";

import { StyledIcon, StyledText, StyledTitle, removeHtmlTags } from "@utils";

interface TableGridProps {
  data: any[];
  pageSize: number;
  loading: boolean;
  evaluation: {
    _id: string;
    name: string;
  };
  onCriterialsChanged: (values: any[]) => void;
  refetch?: () => void;
}

export const TableGrid = ({
  data,
  loading,
  evaluation,
  onCriterialsChanged,
}: TableGridProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Critério",
        accessor: "name",
        Cell: (e) => {
          const description = removeHtmlTags(
            e?.row?.original?.description || ""
          );
          return (
            <>
              <StyledTitle variant="body3" children={e?.row?.original?.name} />
              <StyledText
                variant="body3"
                children={OverflowCheck({
                  text: description,
                  width: "400px",
                })}
              />
            </>
          );
        },
      },
      {
        Header: "Avaliar",
        accessor: "value",
        Cell: ({ row }) => {
          return (
            <Menu
              type={"select"}
              options={[
                {
                  children: <>Mandando bem</>,
                  onClick: () => {
                    const selectedValues = cloneDeep(data);
                    const selectedIndex = selectedValues.findIndex(
                      (selectedValue) => selectedValue._id === row.original._id
                    );

                    if (selectedIndex >= 0)
                      selectedValues[selectedIndex].value = "doing_well";

                    onCriterialsChanged(selectedValues);
                  },
                },
                {
                  children: <>Pode melhorar</>,
                  onClick: () => {
                    const selectedValues = cloneDeep(data);
                    const selectedIndex = selectedValues.findIndex(
                      (selectedValue) => selectedValue._id === row.original._id
                    );

                    if (selectedIndex >= 0)
                      selectedValues[selectedIndex].value = "could_improve";

                    onCriterialsChanged(selectedValues);
                  },
                },
              ]}
              children={FeedbackTag(row?.original?.value || "")}
            />
          );
        },
      },
      {
        Header: "Ação",
        accessor: "action",
        sticky: "right",
        Cell: (info) => {
          return (
            <PillButton
              icon="IconTrash"
              size="medium"
              variant="default"
              type="secondary"
              onClick={() => {
                const selectedValues = cloneDeep(data);
                const filteredValues = selectedValues.filter(
                  (value) => value._id !== info.row.original._id
                );

                onCriterialsChanged(filteredValues);
              }}
            />
          );
        },
      },
    ],
    [data]
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  return (
    <>
      {!!data.length && (
        <Button
          size={"medium"}
          variant="secondary"
          onClick={() => setIsOpen(true)}
          style={{ width: "100%", marginBottom: "24px" }}
        >
          Adicionar Critérios Avaliativos
          <StyledIcon name="IconPlus" fill="none" />
        </Button>
      )}
      <DataGrid
        columns={data.length ? columns : []}
        data={data || []}
        initialState={{ pageSize: 1000 }}
        emptyState={{
          isFiltered: true,
          message: "Nenhum critério encontrado",
          children: (
            <Button
              size={"large"}
              variant="primary"
              onClick={() => setIsOpen(true)}
            >
              Adicionar Critérios Avaliativos
              <StyledIcon name="IconPlus" fill="none" />
            </Button>
          ),
        }}
      />
      <AddEvaluationCriterialsModal
        open={isOpen}
        selectedValues={data}
        evaluation={evaluation}
        onCriterialsChanged={onCriterialsChanged}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};
