import { Tooltip } from "@flash-tecnologia/hros-web-ui-v2";
import { trpc } from "@api/client";

import * as yup from "yup";
import { useFormik } from "formik";

import { StyledIcon, StyledTitle, dispatchToast, removeHtmlTags } from "@utils";

import { TextEditor } from "@components/TextEditor";
import { RenderReaction } from "@components/RenderReaction";

import {
  AnswerArea,
  AnswerContainer,
  Line,
  Option,
  StyledMenu,
  MenuContainer,
} from "./styled";

const EDITOR_MAX_LENGTH = 3000;

export const AnswerField = ({ data, permission, onCloseDrawer, refetch }) => {
  const validationSchema = yup.object({
    reaction: yup.string().optional(),
    reactionMessage: yup
      .string()
      .optional()
      .test(
        "len",
        `O texto deve ter até ${EDITOR_MAX_LENGTH} caracteres`,
        (val) => removeHtmlTags(val).length <= EDITOR_MAX_LENGTH
      ),
  });

  const { mutate, isLoading } =
    trpc.performance.feedback.answerFeedback.useMutation({
      onSuccess: (_, variables) => {
        refetch();

        const hasAnswered = variables.params.reactionMessage;

        const message = !hasAnswered
          ? "Reação salva com sucesso"
          : "Resposta enviada com sucesso";

        dispatchToast({
          type: "success",
          content: message,
        });

        if (hasAnswered) onCloseDrawer();
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao salvar as alterações. Tente novamente em breve.",
        });
      },
    });

  const formik = useFormik<any>({
    initialValues: {
      reaction: data?.reaction,
      reactionMessage: data?.reactionMessage || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      mutate({
        feedbackId: data._id,
        params: {
          reactionMessage: values.reactionMessage,
        },
      });
    },
  });

  const MenuOptions = [
    {
      onClick: () => {
        formik.handleChange({
          target: { id: "reaction", value: "liked" },
        });

        mutate({
          feedbackId: data._id,
          params: {
            reaction: "liked",
          },
        });
      },
      children: (
        <Tooltip title={"Gostei"}>
          <Option setColor="info90">
            <StyledIcon
              name="IconThumbUp"
              fill="transparent"
              setColor="info40"
              size={25}
            />
          </Option>
        </Tooltip>
      ),
    },
    {
      onClick: () => {
        formik.handleChange({
          target: { id: "reaction", value: "loved" },
        });
        mutate({
          feedbackId: data._id,
          params: {
            reaction: "loved",
          },
        });
      },
      children: (
        <Tooltip title={"Amei"}>
          <Option setColor="tertiary90">
            <StyledIcon
              name="IconHeart"
              fill="transparent"
              setColor="tertiary40"
              size={25}
            />
          </Option>
        </Tooltip>
      ),
    },
    {
      onClick: () => {
        formik.handleChange({
          target: { id: "reaction", value: "support" },
        });

        mutate({
          feedbackId: data._id,
          params: {
            reaction: "support",
          },
        });
      },
      children: (
        <Tooltip title={"Apoio"}>
          <Option setColor="success90">
            <StyledIcon
              name="IconHeartHandshake"
              fill="transparent"
              setColor="success40"
              size={25}
            />
          </Option>
        </Tooltip>
      ),
    },
    {
      onClick: () => {
        formik.handleChange({
          target: { id: "reaction", value: "congratulate" },
        });

        mutate({
          feedbackId: data._id,
          params: {
            reaction: "congratulate",
          },
        });
      },
      children: (
        <Tooltip title={"Parabéns"}>
          <Option setColor="error90">
            <StyledIcon
              name="IconConfetti"
              fill="transparent"
              setColor="error40"
              size={25}
            />
          </Option>
        </Tooltip>
      ),
    },
  ];

  const onlyView = permission !== "answer" || data.status === "answered";
  const hideReaction = permission !== "answer" && !formik.values.reaction;
  const hideAnswer = permission !== "answer" && !formik.values.reactionMessage;
  const showSaveButton =
    permission === "answer" &&
    data.status !== "answered" &&
    !!formik.values.reactionMessage;

  return (
    <AnswerContainer>
      {!hideReaction && (
        <>
          <Line marginBottom="12px" marginTop="16px" />
          <MenuContainer>
            <StyledMenu
              anchorOrigin={{ horizontal: 0, vertical: -80 }}
              options={MenuOptions}
              disabled={permission !== "answer" || isLoading}
              type={"select"}
            >
              <RenderReaction reaction={formik.values.reaction} />
            </StyledMenu>
          </MenuContainer>
        </>
      )}

      {!hideAnswer && (
        <>
          <Line marginBottom="24px" marginTop="12px" />
          <StyledTitle
            variant="body2"
            setColor="neutral20"
            style={{ marginBottom: "24px" }}
          >
            Resposta
          </StyledTitle>
          <AnswerArea>
            <TextEditor
              styles={{
                container: {
                  width: "100%",
                },
                editor: { height: "100px" },
              }}
              value={formik.values.reactionMessage}
              placeholder="Resposta do destinatário*"
              readOnly={onlyView || isLoading}
              maxLength={EDITOR_MAX_LENGTH}
              onChange={(value) => {
                formik.handleChange({
                  target: { id: "reactionMessage", value },
                });
              }}
            />
            {showSaveButton && (
              <Option
                setColor="secondary95"
                style={{ alignSelf: "flex-end", marginLeft: "16px" }}
                onClick={() => formik.handleSubmit()}
              >
                <StyledIcon
                  setColor="secondary50"
                  name="IconMessage"
                  fill="transparent"
                  size={25}
                />
              </Option>
            )}
          </AnswerArea>
        </>
      )}
    </AnswerContainer>
  );
};
