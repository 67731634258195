import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";

import {
  Button,
  Icons,
  Menu,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { routes } from "@routes";

import { trpc } from "@api/client";

import { PaginationTable } from "@components/PaginationTable";

import { InfoBanner } from "../../InfoBanner";
import { TableGrid } from "./components/TableGrid";

import { Lantern } from "@assets/index";

import { dispatchToast, StyledText, track } from "@utils";

import { EmptyStateArea, MenuItem } from "./styled";

import type {
  Filter,
  PaginationProps,
} from "@components/PaginationTable/types";

export const EmptyState = () => {
  const navigate = useNavigate();

  const { id = "" } = useParams();

  const [hideBanner, setHideBanner] = useState<boolean>(false);

  return (
    <Grid
      container
      spacing={4}
      alignItems="stretch"
      style={{ marginBottom: "40px" }}
      sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
    >
      <Grid
        item
        xs={12}
        md={hideBanner ? 12 : 8}
        style={{ width: "100%" }}
        display={"flex"}
      >
        <EmptyStateArea>
          <Lantern />
          <StyledText variant="body3" weight={600} setColor="neutral30">
            Nenhuma avaliação criada para esse ciclo
          </StyledText>
          <Menu
            children={
              <Button
                size="large"
                variant="primary"
                onClick={() =>
                  track({
                    name: "people_strategic_hr_performance_company_evaluations_createevaluation_clicked",
                  })
                }
              >
                Crie a primeira avaliação
                <Icons name="IconPlus" />
              </Button>
            }
            type="default"
            options={[
              {
                children: (
                  <MenuItem>
                    <Icons name="IconLayoutGrid" />
                    <Typography variant="body3">
                      Com base em um modelo
                    </Typography>
                  </MenuItem>
                ),
                onClick: () => {
                  track({
                    name: "people_strategic_hr_performance_company_evaluations_createevaluation_model_clicked",
                  });
                  navigate(routes.PageCreateEvaluation("models"), {
                    state: id,
                  });
                },
              },
              {
                children: (
                  <MenuItem>
                    <Icons name="IconEdit" />
                    <Typography variant="body3">
                      Criar avaliação em branco
                    </Typography>
                  </MenuItem>
                ),
                onClick: () => {
                  track({
                    name: "people_strategic_hr_performance_company_evaluations_createevaluation_inblank_clicked",
                  });
                  navigate(routes.PageCreateEvaluation("basic-info"), {
                    state: { cycleId: id },
                  });
                },
              },
            ]}
          />
        </EmptyStateArea>
      </Grid>

      {!hideBanner && (
        <Grid item xs={12} md={4} style={{ width: "100%" }} display={"flex"}>
          <InfoBanner onClose={() => setHideBanner(true)} />
        </Grid>
      )}
    </Grid>
  );
};

export const Evaluations = () => {
  const { id = "" } = useParams();
  const [pagination, setPagination] = useState<PaginationProps>({
    page: 1,
    pageSize: 5,
    totalCount: 0,
  });

  const [search, setSearch] = useState("");

  const [filtersInfo, setFiltersInfo] = useState<{
    cycle?: string[];
    status?: (
      | "draft"
      | "scheduled"
      | "active"
      | "disabled"
      | "finished"
      | "in_calibration"
      | "calibration_finished"
    )[];
    types?: ("self" | "byLeader" | "byLed")[];
    startDate?: { startDate: string; endDate?: string };
    endDate?: { startDate: string; endDate?: string };
    createdAt?: { startDate: string; endDate?: string };
  }>();

  const filters = [
    {
      accessor: "status",
      label: "Status",
      options: [
        { value: "draft", label: "Rascunho" },
        { value: "scheduled", label: "Agendada" },
        { value: "active", label: "Ativa" },
        { value: "finished", label: "Encerrada" },
        { value: "in_calibration", label: "Em calibração" },
        { value: "calibration_finished", label: "Calibração encerrada" },
      ],
      type: "checkbox",
    },
    {
      accessor: "type",
      label: "Tipo de avaliação",
      options: [
        { value: "self", label: "Autoavaliação" },
        { value: "byLeader", label: "Líder avalia liderado" },
        { value: "byLed", label: "Liderado avalia líder" },
      ],
      type: "checkbox",
    },
    {
      accessor: "date",
      label: "Início",
      options: [],
      type: "date",
    },
    {
      accessor: "date",
      label: "Encerramento",
      options: [],
      type: "date",
    },
    {
      accessor: "date",
      label: "Criado em",
      options: [],
      type: "date",
    },
  ] as Filter[];

  const { data, isFetching: loading } =
    trpc.performance.evaluation.getAllEvaluationsPaginated.useQuery(
      {
        ...pagination,
        search,
        sortBy: "createdAt",
        filters: {
          cycle: [id],
          ...filtersInfo,
        },
      },
      {
        enabled: !!id,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
          dispatchToast({
            type: "error",
            content: "Erro ao buscar as avaliações, tente novamente em breve!",
          });
        },
        onSuccess: (result) => {
          setPagination((prev) => {
            return { ...prev, totalCount: result?.total || 0 };
          });
        },
      }
    );

  useEffect(() => {
    if (!data) return;

    if (data.total === pagination.totalCount) return;

    setPagination({
      page: data.page,
      pageSize: data.pageSize,
      totalCount: data.total,
    });
  }, [data?.total]);

  if (!data?.total && !loading) return <EmptyState />;

  return (
    <PaginationTable
      loading={loading}
      data={data?.items || []}
      pagination={pagination}
      filters={filters}
      isFiltered={!!search || !!filtersInfo}
      searchPlaceholder="Buscar por avaliação"
      tableGridRender={(props) => <TableGrid {...props} />}
      onSearch={setSearch}
      onPaginationChanged={(page, pageSize) => {
        setPagination((prev) => ({ ...prev, page, pageSize }));
      }}
      onFiltersChanged={() => {
        setFiltersInfo(undefined);
      }}
    />
  );
};
