import styled from "styled-components";
import { Avatar } from "@mui/material";

export const Card = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  padding: 24px;

  background: ${({ theme }) => theme.colors.neutral[100]};

  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;

  @media screen and (max-width: 990px) {
    max-width: 450px;
    padding: 14px 24px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;

  height: 100%;

  @media screen and (max-width: 990px) {
    flex-direction: row;
    align-items: center;

    width: 60%;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
  @media screen and (max-width: 990px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;

export const StyledAvatar = styled(Avatar)<{ setColor: string }>`
  text-transform: uppercase;
  background-color: ${({ theme, setColor }) => {
    return setColor === "success"
      ? theme.colors.feedback.success[90]
      : setColor === "error"
      ? theme.colors.feedback.error[90]
      : theme.colors.secondary[90];
  }};
  margin-right: 16px;
  width: 48px;
  height: 48px;
  font-size: 32px;
`;

export const TextRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  justify-content: space-between;
`;

export const LeftColumn = styled.div`
  display: flex;
  align-items: center;
`;

export const ToolTipContainer = styled.div`
  height: 100%;
  margin-left: 4px;
`;
